import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoLarge from '../../static/logoLarge.png'
import { FormattedMessage, injectIntl } from 'react-intl'
function termsPl(props) {
    window.scrollTo(0, 0);
    return (
        <Container style={{ paddingTop: '100px' }}>
            <Row className="">
                <Col xs={12} className=' '>
                    <h3 className="">REGULAMIN SERWISU CHILLISTREAM</h3>
                </Col>
                <Col xs={12} className=' '>
                    <h4 className="terms-title">USŁUGODAWCA I SERWIS</h4>
                    Podmiotem prowadzącym Serwis jest polska spółka z ograniczoną odpowiedzialnością
                    prowadzona pod firmą WM Media sp. z o.o., NIP: 5242952600, REGON: 52336518800000,
                    z siedzibą w Warszawie, ul. Józefa Mehoffera 70C/1, 03-131 Warszawa. W dalszej części
                    tekstu tego Regulaminu spółka ta nazywana będzie „Spółką” lub „Usługodawcą”, a także
                    będziemy używać określeń w pierwszej osobie liczby mnogiej, takich jak „my”, „nasz”,
                    „naszym” oraz podobnych, które będziemy odnosić do naszych zobowiązań w ramach tego
                    Regulaminu. Serwis jest serwisem internetowym umożliwiającym Użytkownikom prezentację
                    na żywo treści audio - wideo oraz zarabianie na tych treściach poprzez płatne transmisje na
                    rzecz innych Użytkowników.

                    <h4 className="terms-title">UŻYTKOWNICY I PROCES REJESTRACJI W SERWISIE</h4>
                    Użytkownikami mogą być wyłącznie osoby fizyczne, które posiadają, zgodnie z prawem
                    państwa, w którym żyją pełną zdolność do czynności prawnych, przy czym w każdym razie
                    muszą być to osoby, które ukończyły co najmniej 18 rok życia.
                    W sytuacjach, w których piszemy o Użytkownikach Serwisu w tym Regulaminie używać
                    będziemy także zwrotów w drugiej osobie liczby pojedynczej, takich jak „Ty”, „Twoim” itp.
                    Aby zostać naszym Użytkownikiem osoba fizyczna musi przejść proces rejestracji, podając
                    przy tym w formularzu rejestracyjnym następujące dane: adres poczty elektronicznej (e-mail)
                    – istniejący adres poczty elektronicznej (e-mail), na który zostanie przesłany link aktywacyjny
                    do aktywacji konta Użytkownika w Serwisie, hasło – ciąg co najmniej 7 (siedmiu) znaków
                    alfanumerycznych zawierający co najmniej jedną dużą literę oraz jedną cyfrę, który służył
                    będzie do uwierzytelniania logowań Użytkownika w Serwisie. Aby dokonać rejestracji musisz
                    wyrazić zgodę na treść niniejszego Regulaminu oraz akceptujesz ograniczenia dotyczącego
                    tego, komu zgodnie z niniejszym Regulaminem, przysługuje status Użytkownika.
                    Jako alternatywę możemy też udostępnić możliwość założenia konta przy wykorzystaniu
                    istniejącego już konta w innych serwisach społecznościowych.
                    Po przesłaniu nam wymaganych danych otrzymasz na podany adres poczty elektronicznej
                    (e-mail) link aktywacyjny dla swojego Konta. Celem ukończenia procedury rejestracyjnej
                    musisz dokonać aktywacji Konta poprzez kliknięcie na przesłany link lub poprzez
                    skopiowanie linku i otwarcie go w oknie przeglądarki. Po kliknięciu w link zobowiązujesz nam
                    podać swoje podstawowe dane osobowe: rok urodzenia oraz płeć. Dane te zbieramy celem
                    personalizacji Serwisu do Twoich potrzeb. Możesz jednak nie podawać nam na tym etapie
                    swoje płci, jeżeli z jakichkolwiek powodów czujesz się z tym niekomfortowo.
                    W wyniku rejestracji, o ile spełniasz wymagania niniejszego Regulaminu, stajesz się naszym
                    Użytkownikiem i możesz korzystać ze wszystkich funkcji Serwisu. Podczas użytkowania
                    naszego Serwisu masz możliwość zmiany podanego w trakcie rejestracji hasła. Z poziomu
                    zakładki „Ustawienia” możliwe jest także usunięcie konta w Serwisie. Wszelkie wyżej
                    wymienione zmiany, a także usunięcie Konta Użytkownika wymagają autoryzacji hasłem
                    Użytkownika. Jako Użytkownik Serwisu masz obowiązek zabezpieczenia swoich danych
                    autoryzacyjnych przed niepowołanym dostępem osób trzecich. Wybieraj hasła, które nie są
                    oczywiste i nie zapisuj ich w żadnym widocznym miejscu.

                    Celem identyfikacji w Serwisie przez innych Użytkowników dajemy im dostęp do odrębnej
                    podstrony, którą tworzysz, a która jest Twoim Profilem Użytkownika. Profil Użytkownika
                    domyślnie zawiera dane dotyczące Twojej osoby, które dobrowolnie udostępniasz w
                    Serwisie. Nie musisz podawać żadnych danych poza nazwą użytkownika. Podany przy
                    rejestracji adres e-mail jest niezmienny, jednak w ustawieniach możesz określić swój
                    pseudonim stanowiący publiczną nazwę konta.
                    W przypadku gdy jako Usługodawca mamy wątpliwości co do prawdziwości Twojego profilu
                    lub danych podanych przy rejestracji wyznaczymy Tobie jako Użytkownikowi bądź osobie
                    rejestrującej się w Serwisie odpowiedni termin na przedstawienie wizerunku lub dokumentów
                    identyfikacyjnych, z zastrzeżeniem, że w przypadku nieprzedstawienia stosownych
                    dokumentów w wyznaczonym terminie, Usługodawca usunie konto Użytkownika bądź
                    uniemożliwi rejestrację konta.

                    <h4 className="terms-title">JAKIE UŚŁUGI UDOSTĘPNIAMY UŻYTKOWNIKOM?</h4>
                   <p> W ramach Serwisu udostępniamy Użytkownikom następujące usługi (dalej zwane także
                    „Usługami”):</p>
                </Col>
                <Col xs={12} className='mt-2'>
                    <ul className="terms-ul">
                        <li>
                            nadawanie na żywo treści obejmujących obraz i dźwięk,
                        </li>
                        <li>
                            pobieranie wynagrodzenia w formie Tokenów przez Użytkowników nadających treści
                            audiowizualne,
                        </li>
                        <li>
                            wynagradzanie innych Użytkowników za nadawanie treści audiowizualnych w formie
                            Tokenów,
                        </li>
                        <li>
                            wymianę środków pieniężnych na Tokeny i zarobionych w Serwisie Tokenów na
                            środki pieniężne.
                        </li>
                    </ul>
                </Col>
                <Col xs={12}>
                    <h4 className="terms-title">CO ZROBIĆ ABY MÓC SKORZYSTAĆ Z SERWISU?</h4>
                    Aby korzystać z Usług Użytkownik powinien posiadać urządzenie umożliwiające dostęp do
                    sieci Internet oraz przeglądanie jego zasobów. Serwis jest kompatybilny z następującymi
                    przeglądarkami internetowymi w wersji desktopowej: Google Chrome, Safari, Microsoft Edge, Firefox, Opera .
                    W przypadku, gdy Użytkownik korzysta z Serwisu za pośrednictwem aplikacji na urządzenie
                    mobilne powinien posiadać telefon z systemem iOS lub Android oraz dostęp do Internetu
                    oraz następujące przeglądarki internetowe: Google Chrome, Safari
                    Brak dostępu do Internetu bądź długotrwałe przerwy w dostępie do Internetu mogą
                    spowodować nienależyte działanie Serwisu. Celem nadawania na żywo obrazu i dźwięku
                    musisz posiadać odpowiednie urządzenie, umożliwiające ich rejestrację i przesyłanie w
                    czasie rzeczywistym w sieci Internet.
                    <br></br><br></br>
                    Celem skorzystania z usługi pobierania wynagrodzenia w formie Tokenów, dokonania
                    wymiany zebranych w Serwisie Tokenów na środki pieniężne oraz nadawania treści na
                    żywo obejmujących obraz i dźwięk wymagamy od Ciebie:
                  
                </Col>
                <Col xs={12} className=' mt-2'>
                    <ul className="terms-ul">
                        <li>
                            podania imienia, nazwiska oraz adresu,
                        </li>
                        <li>
                            posiadania rachunku bankowego w banku znajdującym się na terenie Jednolitego
                            Obszaru Płatności w Euro, danych tego rachunku obejmujących numer IBAN,
                            BIC/SWIFT banku, dane instytucji bankowej obejmujące jej nazwę oraz adres,
                        </li>
                        <li>
                            weryfikacji obejmującej przedstawienia swojego wizerunku lub dokumentów
                            identyfikacyjnych bądź ich elektronicznie przetworzonych obrazów, w celu weryfikacji
                            spełniania przez Użytkownika warunków rejestracji w Serwisie oraz zapewnienia
                            prawidłowości rozliczeń, w tym przeciwdziałania nadużyciom i potencjalnej
                            działalności przestępczej z wykorzystaniem Serwisu..
                        </li>
                    </ul>
                </Col>
                <Col xs={12}>
                    <h4 className="terms-title">ZAKAZ DOSTARCZANIA TREŚCI O CHARAKTERZE BEZPRAWNYM I KONSEKWENCJE ZŁAMANIA ZAKAZU</h4>
                    Użytkownicy korzystający z Usług nie mogą wykorzystywać Serwisu do działań niezgodnych
                    z obowiązującym na terenie Rzeczypospolitej Polskiej lub na terenie państwa swojego
                    pobytu prawem lub zasadami współżycia społecznego, w szczególności powodujących lub
                    mogących powodować przeciążenie infrastruktury teleinformatycznej Usługodawcy. Możemy
                    usunąć lub zawiesić Twoje konto lub choćby tylko niektóre jego funkcjonalności, a tym
                    samym prawo do korzystania z usług Serwisu ze skutkiem natychmiastowym, w przypadku
                    naruszenia przez Ciebie postanowień tego Regulaminu lub przepisów prawa, powzięcia
                    wiadomości o działaniu przez Ciebie na szkodę innych osób, dostarczania poprzez
                    funkcjonalności Serwisu danych niezgodnych z prawdą, niedokładnych lub nieaktualnych,
                    wprowadzających w błąd lub naruszających prawa osób trzecich, w tym w szczególności
                    jeżeli podszywasz się pod inną osobę lub osobę fikcyjną a także w sytuacji gdy
                    wykorzystujesz Serwis w sposób niezgodny z zasadami współżycia społecznego. Możemy
                    zażądać od Użytkownika przedstawienia jego wizerunku, dokumentów bądź ich
                    elektronicznie przetworzonych obrazów, w celu weryfikacji prawdziwości konta Użytkownika.
                    W tym celu możemy wyznaczyć Użytkownikowi bądź osobie rejestrującej się w Serwisie
                    odpowiedni termin na przedstawienie wizerunku lub dokumentów, z zastrzeżeniem, że w
                    przypadku nieprzedstawienia swojego wizerunku lub dokumentów w wyznaczonym terminie,
                    usuniemy konto w Serwisie. Podczas korzystania z Serwisu oraz oferowanych przez niego
                    funkcjonalności zobowiązujesz się stosować się do wszelkich przepisów i regulacji
                    obowiązujących na terenie Rzeczypospolitej Polskiej oraz w państwie z którego korzystasz z
                    Serwisu, w tym w szczególności tych zakazujących pokazywania treści o charakterze
                    dyskryminacyjnym, mogących mieć negatywny wpływ na nieletnich bądź o charakterze
                    pornografii dziecięcej lub pornografii z udziałem zwierząt, a także zobowiązujesz się, że
                    podczas korzystania z Serwisu i oferowanych przez niego funkcjonalności nie umieścisz w
                    Serwisie jakichkolwiek dzieł, obrazów, zdjęć, filmów bądź innych przedstawień oraz treści na
                    dowolnych nośnikach, które naruszają prawa lub dobra osobiste osób trzecich bądź
                    pochwalają lub akceptują zachowania niezgodne z prawem, przemoc, terroryzm,
                    dyskryminację, obrazę uczuć religijnych, bądź pedofilię, lub mających charakter oferty usług
                    seksualnych, w tym usług seksualnych świadczonych za wynagrodzeniem. Masz obowiązek
                    zapewnić nas i doprowadzić do tego, że treść prezentowana w jakiejkolwiek formie w
                    Serwisie nie będzie stanowiła, odnosiła się, przedstawiała lub sugerowała: zachowań
                    pedofilnych, seksu ze zwierzętami, czynności fizjologicznych organizmu, części intymnych,
                    kazirodztwa, nekrofilii, ranienia fizycznego siebie lub innych osób, zgwałcenia, porad lub
                    usług o charakterze medycznym, psychologicznym, biznesowym czy prawnym, zachowań
                    potencjalnie groźnych dla życia lub zdrowia ludzkiego, reklam jakichkolwiek produktów albo
                    jakichkolwiek rzeczy, z wyjątkiem reklam produktów i usług pochodzących od Usługodawcy,
                    adresów stron internetowych, danych osobowych, takich jak nazwiska, imiona, numery
                    telefonu, adresy, adresy e-mail, Skype i jakichkolwiek innych informacji, które mogą być
                    użyte aby skontaktować się z jakąkolwiek inną osobą niż Użytkownik. Akceptując nasz
                    Regulamin zobowiązujesz się do nie angażowania się podczas jakiekolwiek formy
                    komunikacji z osobami trzecimi za pośrednictwem w Serwisu w działania, które można uznać
                    za groźby, mowę nienawiści, znieważanie lub zniesławianie albo naruszanie dóbr osobistych
                    osób trzecich, albo promowanie lub akceptowanie dyskryminacji opartej o rasę, płeć,
                    orientację seksualną, religię, pochodzenie etniczne, narodowość, niepełnosprawność, wiek
                    bądź zawierającej jakąkolwiek treść lub materiały naruszające prawa innych osób z uwagi na
                    wyżej wspomniane kryteria, a także promowanie komunizmu, faszyzmu, terroryzmu lub
                    organizacji terrorystycznych, Zobowiązujesz się też do tego, że korzystając z Serwisu i jego
                    funkcjonalności nie naruszysz praw osób trzecich, a w szczególności posiadasz prawa
                    autorskie do wszelkich prezentowanych w ramach Serwisu treści, w tym tekstów, obrazów, a
                    także prawo do swobodnego dysponowania swoim wizerunkiem, a także że zachowasz te
                    prawa tak długo, jak będziesz prezentować owe treści poprzez Serwis. Nie możesz
                    publikować w jakichkolwiek publicznie dostępnych częściach Serwisu (to jest takich, których
                    oglądanie nie wymaga zalogowania), oraz w zdjęciach profilowych materiałów, które
                    zawierają nieocenzurowaną nagość lub jakichkolwiek innych treści, które mogą, w
                    powszechnym odbiorze, uchodzić za wulgarne lub w inny sposób niezgodne z zasadami
                    współżycia społecznego. Zobowiązujesz się do nie przyjmowania jakichkolwiek wynagrodzeń
                    za swoją aktywność i treści w Serwisie w formie innej niż poprzez Serwis, w szczególności
                    poprzez inne systemy płatności, bądź bezpośrednio na swój rachunek bankowy.
                    Zobowiązujesz się nadto do tego, że nie będziesz namawiać innych użytkowników Serwisu
                    do dokonywania wpłat na Twoją rzecz z pominięciem Serwisu, ani nie będziesz płacić innym
                    Użytkownikom z pominięciem Serwisu lub namawiać ich do przyjmowania wpłat poza
                    Serwisem. Zobowiązujesz się do zgłaszania wszelkich zauważonych naruszeń wyłącznie za
                    pośrednictwem funkcji &#39;zgłoś profil&#39; lub za pośrednictwem poczty email, wysyłając wiadomość
                    na adres admin@chillistream.com. Wszelkie inne formy zgłaszania naruszeń Regulaminu, a
                    w szczególności w formie komentarzy pod postami użytkowników lub postów publikowanych
                    w Serwisie stanowią naruszenie niniejszego Regulaminu. Usunięcie konta z powodu
                    naruszenia niniejszego Regulaminu powoduje utratę wszystkich środków zgromadzonych na
                    koncie (kara umowna). Zastrzegamy sobie prawo dochodzenia od Użytkownika
                    odszkodowania uzupełniającego w sytuacji, gdy zastrzeżona kara umowna nie wystarcza na
                    pokrycie szkody wywołanej naruszeniem niniejszego Regulaminu.

                    <h4 className="terms-title">TOKENY</h4>
                    Tokeny są wirtualną jednostką rozrachunkową naszego Serwisu, służą one do
                    wynagradzania Użytkowników realizujących transmisje na żywo. Tokeny te reprezentują
                    wartość pieniężną wskazaną w naszym Serwisie na podstronie umożliwiającej wymianę
                    środków pieniężnych na Tokeny i na odwrót. Wymienione na rzecz Użytkownika Tokeny w
                    każdym momencie pozostaną własnością Usługodawcy. Ich własność nie jest przenoszona
                    na Użytkownika w momencie wymiany środków pieniężnych na Tokeny. Żadnych Tokenów
                    wymienionych lub zebranych w Serwisie nie można powielać, sprzedawać, handlować ani
                    przekazywać w jakikolwiek sposób, w tym między innymi wypłacać ich za gotówkę, w sposób
                    inny niż opisany w niniejszym Regulaminie. Jedynymi dopuszczalnymi formami
                    wykorzystania Tokenów przez Użytkownika są wynagradzanie innych Użytkowników lub
                    wymiana zarobionych w Serwisie Tokenów na środki pieniężne za pomocą dostępnych
                    funkcjonalności Serwisu. Tokeny mają postać wyłącznie wirtualną i stanowią jedynie
                    jednostkę rozrachunkową (w postaci zapisu informatycznego) stosowaną w Serwisie i

                    służącą do dokonywania rozliczeń z innymi Użytkownikami. Liczba posiadanych przez
                    Użytkownika Tokenów jest zapisywana na koncie w Serwisie. Wyłącznie Tokeny zarobione
                    w Serwisie podlegają odwrotnej wymianie (Token za środki pieniężne), na żądanie
                    Użytkownika według wartości podanych w Serwisie.
                    Korzystając z naszych funkcjonalności wyrażasz zgodę na to, że przechowywane na Twoim
                    koncie Tokeny są jedynie reprezentacją wartości pieniężnej, której będziemy używać do
                    rozliczeń Użytkowników. W związku z tym środki te nie są oprocentowane i nie są
                    ubezpieczone ani objęte żadnym systemem ochrony finansowej. Zdecydowanie zachęcamy
                    Twórców, aby nie trzymali uśpionych sald w Serwisie.
                    Usługodawca zarabia na wymianie wartości pieniężnych na Tokeny pobierając od tego
                    stosowną prowizję. Usługodawca nie refunduje kosztów zakupu Tokenów, ani nie udostępnia
                    możliwości wymiany zakupionych Tokenów na środki pieniężne za wyjątkiem realizacji przez
                    Ciebie prawa odstąpienia od umowy zgodnie z przepisami ustawy o prawach konsumenta.

                    <h4 className="terms-title">PŁATNOŚCI</h4>
                    Nie pobieramy żadnych opłat za założenie konta oraz nadawanie treści dostępnych dla
                    Użytkowników. W trakcie wymiany środków pieniężnych na Tokeny pobieramy jednak
                    prowizję, która może zmieniać się w czasie.
                    Przyjmując płatności środkami pieniężnymi celem dokonania wymiany środków pieniężnych
                    na Tokeny i je wypłacając po wymianie Tokenów na wartości pieniężne możemy korzystać z
                    usług podmiotów trzecich, takich jak operatorzy płatności, banki, instytucje pieniądza
                    elektronicznego. Podmioty te realizują swoje usługi odrębnie, niezależnie od Nas oraz
                    regulują je odrębnymi regulaminami. Warunkiem realizacji usług przez wyżej wskazane
                    podmioty trzecie może być akceptacja regulaminu dostarczanego przez taki podmiot.
                    Pamiętaj też, że podmioty te zobowiązane są do monitorowania ryzyk związanych z praniem
                    pieniędzy i finansowaniem terroryzmu, dlatego też mogą one żądać od Ciebie różnorodnych
                    oświadczeń, dokumentów, czy też danych.

                    <h4 className="terms-title">ODPOWIEDZIALNOŚĆ W STOSUNKU DO UŻYTKOWNIKA</h4>
                    Jako Usługodawca nie ponosimy odpowiedzialności za problemy techniczne bądź
                    ograniczenia techniczne występujące w sprzęcie komputerowym, urządzeniu końcowym,
                    systemie teleinformatycznym oraz infrastrukturze telekomunikacyjnej, z których korzystasz.
                    Jako Usługodawca ponosimy odpowiedzialność za treści umieszczone w Serwisie w
                    granicach obowiązującego prawa, dlatego mamy prawo do przeglądania, blokowania i
                    usuwania treści, które uznajemy za niezgodne z przepisami prawa lub postanowieniami tego
                    Regulaminu. Reagujemy także na wnioski Użytkowników i osób trzecich zgodnie z
                    obowiązującym prawem. W większości przypadków przekażemy Ci nasze wskazówki co do
                    tego jakie treści naruszają zasady Serwisu i jeżeli dostosujesz swoje treści do naszych
                    wymogów, oznaczać to będzie dalszą możliwość korzystania z Serwisu. W przypadku
                    jednak, gdy naruszenia zasad wynikających z tego Regulaminu powtórzy się kolejny raz albo
                    w sytuacji, gdy naruszenie przepisów może nosić znamiona czynu zabronionego pod groźbą

                    kary bądź narusza inne obowiązujące powszechnie przepisy prawa możemy zablokować lub
                    usunąć Twoje konto na stałe.
                    <br></br><br></br>
                        Jako Usługodawca nie ponosimy odpowiedzialności za:
                </Col>
                <Col xs={12} className=' mt-2 mb-2'>
                    <ul className="terms-ul">
                        <li>
                            skutki korzystania przez Ciebie z Serwisu w sposób sprzeczny jego
                            funkcjonalnościami Serwisu lub obowiązującym prawem, postanowieniami
                            Regulaminu, a także przyjętymi w tym zakresie zwyczajami oraz związane z tym
                            ewentualne szkody, leżącą po Twojej stronie jako Użytkownika lub Twojego dostawcy
                            usług internetowych,
                        </li>
                        <li>
                            szybkość przesyłu danych i związane z tym ograniczenia, w szczególności o
                            charakterze technicznym i technologicznym oraz infrastrukturalnym,
                        </li>
                        <li>
                            kompletność i prawdziwość danych podanych przez Ciebie przy rejestrowaniu się w
                            Serwisie,
                        </li>
                        <li>
                            czasową niemożność korzystania przez Ciebie z Serwisu, wynikającą z dokonywania
                            zgodnie z Regulaminem zmian i ulepszeń w infrastrukturze teleinformatycznej Spółki.
                            O przerwach technicznych i czasie ich trwania Użytkownicy zostaną powiadomieni
                            nie później niż na 3 dni przed planowaną przerwą techniczną,
                        </li>
                        <li>
                            czasową niemożność świadczenia usług przez Spółkę wynikającą z działania siły
                            wyższej, w tym w szczególności zdarzeń losowych, awarii, błędów, zakłóceń
                        </li>
                        <li>
                            utratę przez Ciebie danych spowodowaną awarią sprzętu, systemu lub też innymi
                            okolicznościami z przyczyn nieleżących po stronie Usługodawcy,
                        </li>
                        <li>
                            negatywne konsekwencje braku wylogowania się przez Ciebie z Serwisu po
                            zakończeniu danej akcji,
                        </li>
                        <li>
                            w granicach obowiązujących przepisów prawa, za treść wszelkich wypowiedzi i treści
                            przesyłanych przez Użytkownika lub innych Użytkowników, wysyłanych za
                            pośrednictwem Serwisu, a innych serwisów połączonych w jakikolwiek sposób z
                            Serwisem,
                        </li>
                        <li>
                            skutki nienależytego zabezpieczenia loginu i hasła do Serwisu przez Użytkownika,
                        </li>
                        <li>
                            brak realizacji zleconych przez Serwis płatności lub ich nienależytą realizację, o ile
                            okoliczności te wywołane są lub związane wyłącznie z niewłaściwym
                            funkcjonowaniem infrastruktury informatycznej dostarczanej przez podmioty trzecie
                            takie jak banki, instytucje pieniądza elektronicznego, operatorzy płatności.
                        </li>
                    </ul>
                    Pamiętaj, że nie jesteśmy stroną jakichkolwiek ustaleń zawieranych między Użytkownikami
                    w ramach Serwisu, czy też poza Serwisem i nie ponosimy odpowiedzialności za aktywności
                    Użytkowników w systemie, w szczególności za realizację ustaleń dokonywanych między
                    Użytkownikami, nie oferujemy także refundacji jakichkolwiek Tokenów w imieniu
                    Użytkowników w sytuacji, gdy inni Użytkownicy przekazali im wynagrodzenie poprzez
                    Serwis. W przypadkach naruszania Regulaminu przez Użytkowników prosimy o
                    przekazywanie nam danych Użytkowników celem umożliwienia nam weryfikacji zaistniałych
                    naruszeń.
                </Col>
                <Col xs={12}>
                    <h4 className="terms-title">PODATKI</h4>
                    Nie odpowiadamy za opłacanie podatków, opłat i jakichkolwiek innych należności, jakie
                    musisz odprowadzić zgodnie z przepisami miejsca Twojej rezydencji podatkowej lub pobytu.
                    Nie doradzamy na temat skutków podatkowych czynności wykonywanych poprzez Serwis,

                    zachęcamy do skorzystania w tym zakresie z porady regulowanego radcy prawnego,
                    doradcy podatkowego lub innego kompetentnego profesjonalisty.

                    <h4 className="terms-title">REKLAMACJE</h4>
                    Masz prawo do złożenia reklamacji, jeżeli usługi przewidziane w niniejszym Regulaminie nie
                    są realizowane przez nas lub są realizowane niezgodnie z postanowieniami tego
                    Regulaminu. Reklamację można złożyć w formie pisemnej lub w formie elektronicznej za
                    pomocą poczty elektronicznej przez formularz reklamacyjny lub e-mailowo na adres:
                    support@chillistream.com. Reklamacja powinna zawierać co najmniej login Użytkownika, dane
                    kontaktowe takie jak e-mail oraz opis zgłaszanych zastrzeżeń. Jeżeli podane w reklamacji
                    dane lub informacje wymagają uzupełnienia, przed rozpatrzeniem reklamacji zwrócimy się
                    do składającego reklamację o jej uzupełnienie we wskazanym zakresie wyznaczając mu w
                    tym celu odpowiedni termin, nie krótszy niż 7 dni. Przyjmujemy na siebie obowiązek
                    rozpatrzenia reklamacji w terminie 30 dni od daty jej otrzymania w prawidłowej postaci.
                    Odpowiedź na reklamację wysyłana jest na adres e-mail Użytkownika lub na wskazany przez
                    niego adres pocztowy. Reklamacje i zgłoszenia dotyczące zachowań innych Użytkowników
                    Serwisu należy zgłaszać pod adresem poczty elektronicznej:
                    support@chillistream.com

                    <h4 className="terms-title">USUNIĘCIE KONTA</h4>
                    Możesz w każdym czasie usunąć Konto, równoznaczne jest to z rozwiązaniem umowy o
                    świadczenie usług elektronicznych. Zastrzegamy sobie prawo, aby z ważnych przyczyn,
                    innych niż określone wprost w niniejszym Regulaminie móc usunąć konto Użytkownika, a
                    tym samym rozwiązać umowę o świadczenie usług elektronicznych na rzecz Użytkownika.
                    Dokonamy tego za wypowiedzeniem, z zachowaniem czternastodniowego terminu
                    wypowiedzenia. W oświadczeniu o wypowiedzeniu wskażemy przyczyny rozwiązania danej
                    umowy (usunięcia Konta).

                    <h4 className="terms-title">ZMIANA REGULAMINU</h4>
                    Możemy zmienić ten Regulamin z ważnych przyczyn (w szczególności podyktowanych
                    względami technicznymi lub prawnymi, a także rozwojem naszej infrastruktury
                    informatycznej, wprowadzaniem nowych usług bądź funkcjonalności lub wzrostem naszych
                    kosztów), bez konieczności uzasadniania tych przyczyn Użytkownikom. Zmiana staje się
                    skuteczna w terminie nie krótszym niż 7 dni od momentu powiadomienia Cię o zmianie
                    Regulaminu. Powiadomimy Cię o tym w formie poczty elektronicznej, wysyłając wiadomość
                    na e-mail, którym posługujesz się w Serwisie lub wyświetlając komunikat na stronie głównej
                    Serwisu. Zmiana Regulaminu upoważnia Użytkownika do wypowiedzenia umowy w terminie
                    7 dni od dnia, w którym został powiadomiony o zmianie Regulaminu w tym zakresie. W razie
                    zmiany lub unieważnienia wskutek prawomocnego orzeczenia sądu któregokolwiek z
                    postanowień niniejszego Regulaminu, pozostałe jego postanowienia pozostają w mocy i
                    wiążą Użytkowników i Usługodawcę.
                </Col>
            </Row>

            <hr></hr>
        </Container>
    )
}
export default termsPl