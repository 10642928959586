
import axios from 'axios';
import {getMyProfile} from './myProfileActions'
import { withCookies, Cookies } from 'react-cookie';
import {runPushServiceWorker} from '../pushNotsActions'
const cookies = new Cookies();
export const DEBUG=false
let axiosAuthToken=null
//PRODUKCYJNY
export const Host='wss://api.chillistream.com' //uzywany w do tworzenia ws url w chacie
export const ApiUrl='https://api.chillistream.com/api/'
export const HostUrl='https://api.chillistream.com/'

//REMOTE DEV
//export const Host='wss://chillistreamapi-dev.azurewebsites.net' 
//export const ApiUrl='https://chillistreamapi-dev.azurewebsites.net/api/'
//export const HostUrl='https://chillistreamapi-dev.azurewebsites.net/'


//LOCAL
//export const Host='ws://localhost'
//export const ApiUrl='http://localhost/api/'
//export const HostUrl='http://localhost/'
//LAN
//export const Host='ws://192.168.1.12'
//export const ApiUrl='http://192.168.1.12/api/'
//export const HostUrl='http://192.168.1.12/'

let locale;
  let localeCookie=cookies.get('locale')
  if(!localeCookie){
    locale = navigator.language;
  }
  else{
        locale=localeCookie
  }
export const axiosClient = axios.create({
  headers: {
    'Accept-Language':locale
  }
});

export const upssssText='Upppsss... something went wrong :( Please try again later or contact us at admin@chillistream.com';

export const makeAxiosInctace=()=> {
  let locale2;
  let localeCookie2=cookies.get('locale')
  if(!localeCookie2){
    locale2 = navigator.language;
  }
  else{
        locale2=localeCookie2
  }
  let axiosInstance = axios.create({
    baseURL: ApiUrl,
    timeout: 1000,
    headers: {
      'Authorization': axiosAuthToken,
      'Accept-Language':locale2
    }
  });
  return axiosInstance
  axiosInstance=initAxiosInstance(axiosInstance)
  
}

export const setAxiosLocaleHeader=(newLocale)=>{
  axiosClient.defaults.headers['Accept-Language']=newLocale
}

const initAxiosInstance=(instance)=>{
  return dispatch => {
    instance.interceptors.response.use( (response)=> {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, (error) =>{
      console.log('interceptors error', error)
      if(error.response){
        if(error.response.status && error.response.status>=500){
         // console.log('jest status 500')
          alert(upssssText)
        }
        if(error.response.status && error.response.status>=400){
          if(error.response.data.reason==='userPermanentBan'){
           // console.log('interceptors error userPermanentBan')
            dispatch( {type: "SET_USER_BANNED", data:true});
            dispatch({type:'SET_LOGGED_IN', data:true})
            dispatch({type:'SET_LOGIN_COMPLETED', data:true})
          }
         // dispatch(
         //   {type: "WARNING_MODAL_SHOW", 
         //   text: upssssText+' Id: err-int-x500',
         //   title:'',
        //  });
        }
        if(error.response.status && error.response.status===403){
         // console.log('interceptors bledny token lub bledny endpoint')
          //tu mozemy wywalic modal z informacja z zostales wylogowany
          dispatch(userLoggedOut()) 
        }
        if(error.response.status && error.response.status===401){
        //  console.log('interceptors niezalogowany')
        }
      }
      return Promise.reject(error);
    });
    return instance
  }
}

export const initAxios=()=>{
    return dispatch => {
    //  console.log('initAxios start')
      axiosClient.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      axiosClient.interceptors.response.use( (response)=> {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, (error) =>{
        console.log('interceptors error', error)
        if(error.response){
          if(error.response.status && error.response.status>=500){
          //  console.log('jest status 500')
            dispatch(
              {type: "WARNING_MODAL_SHOW", 
              text: upssssText+' Id: err-int-x500',
              title:'',
            });
          }
          if(error.response.status && error.response.status>=400){
            if(error.response.data==='userPermanentBan'){
           //   console.log('interceptors error userPermanentBan')
              dispatch( {type: "SET_USER_BANNED", data:true});
              dispatch({type:'SET_LOGGED_IN', data:true})
              dispatch({type:'SET_LOGIN_COMPLETED', data:true})
            }
          }
          if(error.response.status && error.response.status===403){
          //  console.log('interceptors bledny token lub bledny endpoint')
            //tu mozemy wywalic modal z informacja z zostales wylogowany
            dispatch(userLoggedOut()) 
          }
          if(error.response.status && error.response.status===401){
         //   console.log('interceptors niezalogowany')
          }
        }
        return Promise.reject(error);
      });
    }
  }

  const checkPushNotsFlow=(dispatch)=>{
    var pushRegId=localStorage.getItem("pushRegId")
    if(!pushRegId){
      //ciacho push modal
      let pushCookie=cookies.get('psmd')
      if(!pushCookie){
      //  dispatch({type:'SHOW_PUSH_NOTS_MODAL', data:true})
        //runPushServiceWorker()
      }
      
    }
    
  }
  export const userLoggedIn =(token)=>{
  //  console.log('userLoggedIn token', token)
    return dispatch => {
      localStorage.setItem("token", token)
        axiosAuthToken=token
        axiosClient.defaults.headers.common['Authorization'] = 'token '+token
      //  dispatch({ type: "RESET_USER_FEED_DATA", data: true});
      //  dispatch({ type: "RESET_MAIN_FEED_DATA", data: true});
        dispatch({ type: "RESET_ALL"});
        dispatch({ type: 'SET_AUTH_TOKEN', data: token})
       // const reloadEvent = new Event('reload');
       // console.log('init getMyProfile')
        getMyProfile(dispatch).then(()=>{
      //    console.log('getMyProfile then')
          dispatch({type:'SET_LOGGED_IN', data:true})
          dispatch({type:'SET_LOGIN_COMPLETED', data:true})
          dispatch({type:'SET_SHOW_LOGIN_MODAL', data:false})
          
          dispatch({type:'MAIN_WS_CONNECT'})
          //setTimeout(()=> window.dispatchEvent(reloadEvent), 300)
          // TO DO
          checkPushNotsFlow(dispatch)
          
        })
    }
  } 
  export const userLoginFetch = (payLoad, callback) => {
   // console.log('userLoginFetch')
    var data = new FormData();
   // data.append( "email", payLoad.email );
    data.append( "email", payLoad.email );
    data.append( "password", payLoad.password );
    return dispatch => {
      let path=ApiUrl+"auth/login/"
        //dispatch({ type: 'fetching', data: true})
        axiosClient.post(path, data)
        .then((response)=> {
     //     console.log(response.data)  
       //  alert(response.data )   
          callback({error:false})
          dispatch(userLoggedIn(response.data.key))
        })
        .catch( (error) =>{
      //   console.log('userLoginFetch error', error)
     //    console.log('userLoginFetch error', error.response)
          if(error.response){
           // console.log('userLoginFetch error', error.response.data)  
            if(error.response.status===400){
              //wyswietlamy bledy w formie
              callback({error:true, data:error.response.data})
              
            }
            else{
              callback({error:true, data:null})
            }
          }
        })
        .then(function () {
          // always executed
          //dispatch({ type: 'fetching', data: false})
        });
      };
    }
  export const userLoggedOut =()=>{
    axiosClient.defaults.headers.common={}
    return dispatch => {
    //  console.log('userLoggedOut')
      localStorage.removeItem("token")
      axiosClient.defaults.headers.common={}
      dispatch({type:'SET_LOGGED_IN', data:false})
      dispatch({ type: "RESET_ALL"});
      dispatch({type:'SET_LOGIN_COMPLETED', data:true})
      dispatch({type:'WS_DISCONNECT'})
      const reloadEvent = new Event('reload');
      window.dispatchEvent(reloadEvent);
    }
  }

  export const logout = payLoad => {
    var data = new FormData();
    //axiosClient.defaults.headers.common={}
    return dispatch => {
      axiosClient.defaults.headers.common={}
      dispatch({ type: "RESET_USER_FEED_DATA", data: true});
      dispatch({ type: "RESET_MAIN_FEED_DATA", data: true});
      dispatch({ type: 'SET_AUTH_TOKEN', data:null})
      let path=ApiUrl+"auth/logout/"
        //dispatch({ type: 'mainLoader', data: true})
        axiosClient.post(path, data)
        .then((response) =>{
        //  console.log(response)
          localStorage.removeItem("token")

          dispatch(userLoggedOut())
        })
        .catch( (error)=> {
          console.log('error',error)
        })
        .then( ()=> {
          // always executed
          //dispatch({ type: 'mainLoader', data: false})
        });
      };
    }

  export const passwordReset = (payLoad, callback) => {
      var data = new FormData();
      data.append( "email", payLoad.email );
      return dispatch => {
        let path=ApiUrl+"auth/password/reset/"
          //dispatch({ type: 'fetching', data: true})
          axiosClient.post(path, data)
          .then((response)=> {
          //  console.log(response.data)  
            callback({error:false})
          })
          .catch( (error) =>{
          //  console.log(error.response.data) 
            if(error.response){
              callback({error:true, data:error.response.data})
              if(error.response.status===400){
                
                //wyswietlamy bledy w formie
           
                
              }
            }
          })
          .then(function () {
            // always executed
            //dispatch({ type: 'fetching', data: false})
          });
        };
      }
  export const passwordResetConfirm = (payLoad, callback) => {
        var data = new FormData();
        data.append( "uid", payLoad.uid );
        data.append( "token", payLoad.token );
        data.append( "new_password1", payLoad.new_password1 );
        data.append( "new_password2", payLoad.new_password2 );
        return dispatch => {
          let path=ApiUrl+"auth/password/reset/confirm/"
            //dispatch({ type: 'fetching', data: true})
            axiosClient.post(path, data)
            .then((response)=> {
            //  console.log(response.data)  
              callback({error:false})
            })
            .catch( (error) =>{
            //  console.log(error.response.data) 
              if(error.response){
                callback({error:true, data:error.response.data})
                if(error.response.status===400){
                  //wyswietlamy bledy w formie
                 
                  
                }
                if(error.response.status===404){
                  //wyswietlamy bledy w formie
                  callback({error:true, data:error.response.data})
                  
                }
              }
            })
            .then(function () {
              // always executed
              //dispatch({ type: 'fetching', data: false})
            });
          };
        }

  export const register = (payLoad, callback) => {
          var data = new FormData();
         // data.append( "username", payLoad.username );
          data.append( "email", payLoad.email );
          data.append( "password1", payLoad.password1 );
          data.append( "password2", payLoad.password2 );
          data.append( "termsAccepted", payLoad.termsAccepted );
          if(payLoad.ref){
            data.append( "ref", payLoad.ref );
          }
         // if(payLoad.termsAccepted===true){
        //    data.append( "termsAccepted", payLoad.termsAccepted );
       //   }
          
          return dispatch => {
            //let path=HostUrl+"rest-auth/registration/"
            let path=ApiUrl+"auth/registration/"
              //dispatch({ type: 'fetching', data: true})
              axiosClient.post(path, data)
              .then((response)=> {
              //  console.log(response.data)  
                callback({error:false})
              })
              .catch( (error) =>{
               // console.log(error.response.data) 
                if(error.response){
                  if(error.response.status===400){
                    //wyswietlamy bledy w formie
                    callback({error:true, data:error.response.data})
                  }
                  else{
                    callback({error:true, data:error.response.data})
                  }
                }
                else{
                  callback({error:true, data:{}})
                }
              })
              .then(function () {
                // always executed
                //dispatch({ type: 'fetching', data: false})
              });
            };
          }

export const registerEmailConfirm = (key, callback) => {
            var data = new FormData();
            data.append( "key", key );
            return dispatch => {
              let path=ApiUrl+"account-auth/registration/account-confirm-email/"+key+"/"
                //dispatch({ type: 'fetching', data: true})
                axiosClient.post(path, data)
                .then((response)=> {
                //  console.log(response.data)  
                  callback({error:false, data:response.data})
                })
                .catch( (error) =>{
              //    console.log(error.response.data) 
                  if(error.response){
                    if(error.response.status===404){
                      
                      //wyswietlamy bledy w formie
                      callback({error:true, data:error.response.data})
                      
                    }
                  }
                })
                .then(function () {
                  // always executed
                  //dispatch({ type: 'fetching', data: false})
                });
              };
            }

export const accountDeleteConfirm = (token, callback) => {
              var data = new FormData();
              data.append( "token", token );
              return dispatch => {
                let path=ApiUrl+"accountdeleteconfirm/"
                  //dispatch({ type: 'fetching', data: true})
                  axiosClient.post(path, data)
                  .then((response)=> {
                  //  console.log(response.data)  
                    callback({error:false, data:response.data})
                  })
                  .catch( (error) =>{
                    console.log(error.response.data) 
                    if(error.response){
                      if(error.response.status===400){
                        
                        //wyswietlamy bledy w formie
                        callback({error:true, data:error.response.data})
                        
                      }
                    }
                  })
                  .then(function () {
                    // always executed
                    //dispatch({ type: 'fetching', data: false})
                  });
                };
              }
            