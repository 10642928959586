import React, { Component, } from 'react';
import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Header from './components/template/header'
import Footer from './components/template/footer'

import Landing1 from './views/landing/landing1'
import LandingMembers from './views/landing/landingMembers'
import LoginRegister from './views/auth/loginRegister'
import EarningsView from './views/streamer/earningsView'
import PayoutSettingsView from './views/streamer/payoutSettingsView'
import VerifyAccountView from './views/streamer/verifyAccountView'
import Faq from './views/landing/faq'
import ViewProfile from './views/visitor/viewProfile'
import StreamerStreaming from './views/streamer/streamerStreaming';

import WalletView from './views/visitor/walletView'
import MyPanel from './views/profile/myPanel'
import MyProfileView from './views/profile/myProfileView'
import BuyTokensModal from './components/buyTokensModal'
import PushNotsModal from './components/pushNotsModal';
import termsEn from './views/docs/termsEn'
import termsPl from './views/docs/termsPl'
import PrivacyPolicyPl from './views/docs/privacyPolicyPl'
import PrivacyPolicyEn from './views/docs/privacyPolicyEn'
import CookiesPolicyPl from './views/docs/cookiesPolicyPl'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initAxios, logout, userLoggedOut, userLoggedIn, reportPost, setAxiosLocaleHeader, } from "./actions/index";
//import {runPushServiceWorker} from './pushNotsActions'
import { Cookies } from 'react-cookie';
import { Oval } from 'react-loader-spinner'
import ConfirmEmail from './views/auth/confirmEmail'
import PassReset from './views/auth/passReset'
import PassResetConfirm from './views/auth/passResetConfirm'
import AccountDeleteConfirm from './views/auth/accountDeleteConfirm'

//import MyChannelSettings from './views/profile/myChannelSettings'
import MyProfileSettings from './views/profile/myProfileSettings'
import EmailChangeConfirm from './views/profile/emailChangeConfirm'



import { IntlProvider } from 'react-intl';
import Polish from './lang/pl.json'
import English from './lang/en.json'
import { MdAddToHomeScreen } from 'react-icons/md';
import OnboardModal from './components/onboardModal'
import ScrollToTop from './scrollToTop'
import ReactGA from "react-ga4";

const cookies = new Cookies();
class App extends Component {
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {
    document.body.classList.add('bg-secondary')
    this.props.initAxios()
    let token = localStorage.getItem("token")
    if (token) {
      this.props.userLoggedIn(token)
    }
    else {
      this.props.userLoggedOut()
    }
  }
  componentDidMount() {
   // console.log('Date.now()', Date.now())
  //  runPushServiceWorker()
    let locale;
    let localeCookie = cookies.get('locale')
   // console.log('localeCookie', localeCookie)
    if (!localeCookie) {
      locale = navigator.language;
    }
    else if (localeCookie === undefined) {
    //   console.log('locale navigator.language', navigator.language)
      locale = navigator.language;
    }
    else {
      locale = localeCookie
    }
    ReactGA.initialize("G-QSDTL930V1");
    let urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    if (urlParams.src) {
      if (urlParams.src === 'ads') {
        //stawiamy ciacho oznaczajace wejscie z reklamy i puszczamy event do GA4
        //adprov- provider reklamy
        //camp - id kampani
        //format - format reklamy
        //site - id strony odsylajacej
        //price - CPC lub CPM
        //
        let traficData = {
          adprov: urlParams.adprov,
         // camp: urlParams.camp,
         // format: urlParams.format,
         // site: urlParams.site,
        //  price: urlParams.price,
        //  src: urlParams.src,
         // click_id: urlParams.click_id,
        }
        let expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 5));
        cookies.set('traffic', traficData, { path: '/', expires: expiryDate })
        ReactGA.event("ads_visit", traficData)
      }

    }
    //console.log('locale',locale)
    this.props.dispatch({ type: 'SET_LOCALE', data: locale })
    document.addEventListener('visibilitychange', this.visibilityChange, false)
    let cookieReaded = cookies.get('cookieReaded')
    let adultContentWarningReaded = cookies.get('ACWReaded')
    if (!cookieReaded) {
      this.props.dispatch({ type: 'SET_COOKIES_READED', data: false })
    }
    window.addEventListener('storage', e => {
      if (e.key === 'token' && e.oldValue && !e.newValue) {
        this.props.userLoggedOut()
      }
      if (e.key === 'token' && !e.oldValue && e.newValue) {
        this.props.userLoggedIn(e.newValue)
      }
    });
    window.addEventListener('online', (e) => { this.props.dispatch({ type: 'CONNECTION_STATE_CHANGE', data: 'online' }) });
    window.addEventListener("offline", (e) => { this.props.dispatch({ type: 'CONNECTION_STATE_CHANGE', data: 'offline' }) });
  }





  state = {
    menuOpen: false
  }

  setLocale = (locale) => {
    let expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 5));
    cookies.set('locale', locale, { path: '/', expires: expiryDate })
    this.props.dispatch({ type: 'SET_LOCALE', data: locale })
    setAxiosLocaleHeader(locale)
  }

  renderIntro = () => {
    return (
      <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{ backgroundColor: '#000' }}>
        <Oval color="#666" secondaryColor='#fff' height={125} width={125} />
      </Container>
    )
  }
  render() {
    let lang;
    let menuLang;
    if (!this.props.loginCompleted) {
      return this.renderIntro()
    }

    if (this.props.locale === "pl-PL") {
      lang = Polish;
      menuLang = 'pl'
    }
    else {
      lang = English;
      menuLang = 'en'
    }

    return (
      <IntlProvider locale={this.props.locale} messages={lang} key={lang} >
        <Router>
          <Container fluid className="px-0  d-flex flex-column align-self-stretch align-items-stretch justify-content-start" >

            <Switch >
              <Container style={{ paddingTop: '0px' }} id="masterContainer" fluid className="px-0 d-flex align-self-stretch flex-column  align-items-stretch justify-content-start" >
                <Route component={Header} />


                <Route exact path="/register/confirmemail/:key/" component={ConfirmEmail} />
                <Route exact path="/auth/account/delete/confirm/:token/" component={AccountDeleteConfirm} />
                <Route exact path="/auth/email/change/confirm/:token/" component={EmailChangeConfirm} />
                <Route exact path="/api/resetpassword/confirm/:uid/:token/" component={PassResetConfirm} />

              
                
            
                

                <Route exact path="/my/profile/" component={MyProfileView} />
     
                <Route exact path="/my/profile/settings" component={MyProfileSettings} />
                <Route exact path="/my/earnings/" component={EarningsView} />
                <Route exact path="/my/payoutsettings/" component={PayoutSettingsView} />
                <Route exact path="/my/verification/" component={VerifyAccountView} />
                <Route exact path="/my/wallet/:status" component={WalletView} />
                <Route exact path="/my/wallet/" component={WalletView} />
                <Route exact path="/my/panel/" component={MyPanel} />
                <Route exact path="/my/stream/" component={StreamerStreaming} />
                
                {this.props.locale==='pl-PL' &&
                  <>
                    <Route exact path="/docs/terms/" component={termsPl} />
                    <Route exact path="/docs/privacy/" component={PrivacyPolicyPl} />
                    <Route exact path="/docs/cookies/" component={CookiesPolicyPl} />
                    
                    </>
                  }
                  {this.props.locale==='en' &&
                  <>
                    <Route exact path="/docs/terms/" component={termsEn} />
                    <Route exact path="/docs/privacy/" component={PrivacyPolicyEn} />
                    <Route exact path="/docs/cookies/" component={CookiesPolicyPl} />
                    </>
                  }
                <Switch >
                  <Route exact path="/login/" component={LoginRegister} />
                  <Route exact path="/faq/" component={Faq} />
                  <Route exact path="/:urlName" component={ViewProfile} />
                </Switch>
                {!this.props.loggedIn &&
                  <Route exact path="/" component={Landing1} />
                }
                {this.props.loggedIn &&
                  <Route exact path="/" component={LandingMembers} />
                }
                <Footer />
              </Container>
            </Switch >
            
          </Container>
          {this.props.loggedIn && this.props.myProfile&&this.props.myProfile.transactionCurrency&&
              <BuyTokensModal />
            }
          {this.props.loggedIn && !this.props.myProfile.onboarded &&
            <OnboardModal />
          }
          {this.props.loggedIn && this.props.showPushNotsModal&&
          <PushNotsModal />
          }
        </Router>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoginModal: state.auth.showLoginModal,
    authToken: state.auth.token,
    locale: state.locale.locale,
    loggedIn: state.auth.loggedIn,
    loginCompleted: state.auth.loginCompleted,
    myProfile: state.profile.myProfile,
    cookiesReaded: state.profile.cookiesReaded,
    userBanned: state.auth.userBanned,
    showPushNotsModal: state.profile.showPushNotsModal,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ initAxios, logout, userLoggedOut, userLoggedIn, }, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
