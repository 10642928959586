import React, {Component} from 'react';
import {connect} from 'react-redux';
import {registerEmailConfirm, userLoggedIn} from "../../actions/index";
import {Redirect} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {Link} from 'react-router-dom'
import {Oval} from 'react-loader-spinner'
import {injectIntl} from 'react-intl'


class ConfirmEmail extends Component {

  componentDidMount=()=>{
    let key=this.props.match.params.key
    this.setState({key:key},
      ()=>this.handleSubmit()
      )

  }

  state = {
    fetching:true,
    success:null,
  }

  handleSubmit=()=>{
    this.setState({success:null, fetching:true})
    this.props.registerEmailConfirm(this.state.key, this.submitted)
  }

  submitted=(data)=>{
   // console.log('submitted data', data)
    if(!data.error){
      //zrobic automtyczne logowanie
      this.setState({success:true})
      setTimeout(()=>{
        this.props.userLoggedIn(data.data.token)
      //  this.setState({redirect:'/login/'})
    }, 2500)
    }
    else{
      this.setState({success:false})
    }
    this.setState({fetching:false})
  }

  render() {
    if (this.props.loggedIn) {
      return <Redirect  to={'/'} />
    }
    if (this.state.redirect) {
      return <Redirect  to={this.state.redirect} />
    }
    return(
      <Container fluid style={{ minHeight:'100vh'}} className="px-0">
        <Container className="px-0 pb-4 pt-5">
          <Row noGutters>

            <Col xs={12} md={6} className='mx-auto'>
              <Row noGutters>
                <Col xs={12} className='p-2 p-md-5 pt-5 text-center'>
                    <Row noGutters>
                        <Col xs={12} className='border p-2 p-md-3 text-center pb-5 fadeIn'>
                          <Row noGutters>
                            <Col xs={12} className=' p-3 text-center'>
                              
                               <h3>Aktywacja konta</h3> 
                              
                              <hr></hr>
                            </Col>
                            {this.state.fetching&&
                              <Col xs={12} className='text-center py-5'>
                                  <div style={{width:'80px'}}className="mx-auto">
                                    <Oval  color="rgba(244, 67, 54, 0.5)" height={50} width={80} />
                                  </div>
                              </Col>
                            }
                            {!this.state.fetching&&this.state.success===false&&
                              <Col xs={12} className='text-center text-danger'>
                                    Link aktywacyjny wygasł, został już użyty lub jest nieprawidłowy 
                              </Col>
                            }
                             {!this.state.fetching&&this.state.success===true&&
                              <Col xs={12} className='text-center'>
                                    <p>Brawo!</p>
                                    <p>Twoje konto jest już aktywne</p>
                                    <p>Za chwile zalogujemy Cię do serwisu.</p>
                              </Col>
                            }
  
                            <Col xs={12} className='pt-1 text-center'>
                               <Link to={'/register/'}> Nie masz konta? Zarejestruj się.</Link><br></br>
                                <Link to={'/resetpassword/'}>Nie pamiętasz hasła?</Link>
                            </Col>
                          </Row>
                        </Col>  
                    </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </Container>
    )
    
    
  }
}

//const mapDispatchToProps = dispatch => ({
 // userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
//})
function mapStateToProps(state) {
    return {
     loggedIn: state.auth.token
    };
  }
export default connect(
    mapStateToProps,
    {
      registerEmailConfirm, 
      userLoggedIn,
    }
  )(injectIntl(ConfirmEmail));
