import { axiosClient, ApiUrl, HostUrl, upssssText } from './authActions'

export function getChannelData(channelName, callback) {
   // console.log('getChannelData') 
   let path = ApiUrl + "audience/"+channelName+"/"
   return function (dispatch) {
     axiosClient.get(path)
       .then((response) => {
      //   console.log('getChannelData', response.data)
       dispatch({ type: "SET_MY PROFILE", data: response.data.myProfile });
         callback(response.data)
       })
       .catch((error) => {
         // handle error
       //   console.log('getChannelData error',error)
       //   console.log('getChannelData error.response',error.response)
         if (error.response) {
           callback(error.response.data )
           if (error.response.status === 400) {
             //   console.log(error.response.data)
             
           }
           if (error.response.status === 404) {
           }
           if (error.response.status === 403) {
           }
         }
       })
       .then(() => {
         dispatch({ type: 'MY PROFILE_FETCHING', data: false })
       });
   }
 }

 export function renewAudienceToken(channelName, callback) {
 // console.log('renewAudienceToken') 
 let path = ApiUrl + "audience/"+channelName+"/"
 return function (dispatch) {
   axiosClient.patch(path)
     .then((response) => {
    //  console.log('renewAudienceToken', response.data)
       dispatch({ type: "SET_MY PROFILE", data: response.data.myProfile });
       callback(response.data)
     })
     .catch((error) => {
       // handle error
   //     console.log('renewAudienceToken error',error)
   //     console.log('renewAudienceToken error.response',error.response)
       if (error.response) {
         callback(error.response.data )
         if (error.response.status === 400) {
          console.log(error.response.data)
          callback(error.response.data)
           
         }
         if (error.response.status === 404) {
         }
         if (error.response.status === 403) {
         }
       }
     })
     .then(() => {
       dispatch({ type: 'MY PROFILE_FETCHING', data: false })
     });
 }
}

 export function getUserProfile(urlName, callback) {
  //  console.log('getUserProfile') 
   let path = ApiUrl + "user/profile/"+urlName+"/"
   return function (dispatch) {
     axiosClient.get(path)
       .then((response) => {
      //   console.log('getUserProfile', response.data)
         callback(response.data)
       })
       .catch((error) => {
         // handle error
        //  console.log('getUserProfile error',error)
        //  console.log('getUserProfile error.response',error.response)
         if (error.response) {
           callback({ error: true, data: error.response.data })
           if (error.response.status === 400) {
             //   console.log(error.response.data)
             
           }
           if (error.response.status === 404) {
           }
           if (error.response.status === 403) {
           }
         }
       })
       .then(() => {
         dispatch({ type: 'MY PROFILE_FETCHING', data: false })
       });
   }
 }

 export function sendTip(fd, callback) {
//  console.log('sendTip')
  let path = ApiUrl + "tip/"
  return function (dispatch) {
    axiosClient.post(path, fd)
      .then((response) => {
      //  console.log('sendTip', response.data)
        dispatch({ type: "SET_MY PROFILE", data: response.data.profile });
        callback(response.data)
      })
      .catch((error) => {
        // handle error
     //   console.log('sendTip error', error)
      //  console.log('sendTip error.response', error.response)
        if (error.response) {
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            callback(error.response.data)

          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
      });
  }
}