import { createStore, applyMiddleware, compose  } from "redux";
import rootReducer from "../reducers/index";
//import thunk from "redux-thunk";
import mainWsMiddleware from '../middleware/mainWsMiddleware'
import channelWsMiddleware from '../middleware/channelWsMiddleware'
import chatWsMiddleware from '../middleware/chatWsMiddleware'

//import audioNotsMiddleware from '../middleware/audioNotsMiddleware'
import { configureStore, } from '@reduxjs/toolkit'

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
  {reducer:rootReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware().prepend(mainWsMiddleware.middleware, channelWsMiddleware.middleware, chatWsMiddleware.middleware),
    //enhancers: [mainWsMiddleware]
  },
 // storeEnhancers(applyMiddleware(thunk, mainWsMiddleware))
);


export default store;