import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

import { Link } from "react-router-dom";
import Oval from 'react-loader-spinner'
import { Redirect } from 'react-router'
import { Cookies } from 'react-cookie';
import landingFreeBg1Tmp from '../../static/images/bg/landingFreeBg1Tmp.jpg'
import bg03 from '../../static/images/bg/bg03.jpg'
import televisionTv from '../../static/images/svg/televisionTv.svg'
import remoteControl from '../../static/images/svg/remoteControl.svg'
import monitorTablet from '../../static/images/svg/monitorTablet.svg'
import moneyEuro from '../../static/images/svg/moneyEuro.svg'

import chart1 from '../../static/images/svg/chart1.svg'
import { FormattedMessage, injectIntl } from 'react-intl'

import { AiOutlineDown } from 'react-icons/ai'

import i01 from '../../static/images/about/i01.png'
import i02 from '../../static/images/about/i02.png'
import i03 from '../../static/images/about/i03.png'
import lsdevices from '../../static/images/landing1/lsdevices.jpg'
import lsdevices2 from '../../static/images/landing1/lsdevices2.jpg'
import lsmoney from '../../static/images/landing1/lsmoney.jpg'
import lsmoney1 from '../../static/images/landing1/lsmoney1.jpg'
import lsbusiness from '../../static/images/landing1/lsbusiness.jpg'
import lsmonitor from '../../static/images/landing1/lsmonitor.jpg'
import lscamera from '../../static/images/landing1/lscamera.jpg'
const cookies = new Cookies();

class Landing1 extends Component {
    constructor(props) {
        super(props);
        this.backToTopRef = React.createRef();
        this.viewMoreRef = React.createRef();
    }

    componentDidMount() {
        document.title = "Chillistream"
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        document.addEventListener("scroll", this.onWindowScroll)
        // const params = parseParams(this.props?.location?.search); 
    }
    scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    viewMore = () => {
        this.viewMoreRef.current.scrollIntoView({ behavior: 'smooth' }, true);
    }

    onWindowScroll = (e) => {

        if (window.scrollY > 100) {
            this.backToTopRef.current.classList.remove('d-none');
            this.backToTopRef.current.classList.add('d-block');
        } else {
            this.backToTopRef.current.classList.remove('d-block');
            this.backToTopRef.current.classList.add('d-none');
        }

    }
    componentWillUnmount() {
        document.removeEventListener("scroll", this.onWindowScroll)
    }

    state = {

    }




    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <>
                <div className="banner home-landing bg-holder" style={{ backgroundImage: "url(" + landingFreeBg1Tmp + ")" }}>
                    <Container>
                        <Row className=" justify-content-center">
                            <Col xl={6} lg={8} md={12} >
                                <div className="banner-info">
                                    <div className="banner-content text-center">
                                        <h1 className="title">Best Way Of Online<br></br>
                                            <span>Streaming</span>
                                        </h1>
                                        <Row className="justify-content-center">
                                            <Col md={12} >
                                                <p>
                                                    {this.props.locale === 'en' &&
                                                        'Making money through live video streams has never been easier. Earn for every minute You stream to your fans.'
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        'Zarabianie poprzez transmisje na żywo jeszcze nigdy nie było takie proste. Zarabiaj za każdą minutę, podczas której streamujesz dla swoich fanów.'
                                                    }
                                                </p>
                                                <Link to={'/login/#reg'} className='btn btn-primary'><FormattedMessage id="common.registerNow" /></Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                    <div className="mainBannerViewMore" onClick={() => this.viewMore()}>
                    <FormattedMessage id="common.viewMore" /> <br></br>
                        <AiOutlineDown size={36} />
                    </div>

                </div>




                <div ref={this.viewMoreRef} className="space-ptb feature-section bg-holder bg-overlay-dark-8" style={{ backgroundImage: "url(" + bg03 + ")" }}>
                    <Container>
                        <Row className="row justify-content-center">
                            <Col xs={12} >
                                <div className="section-title text-center">
                                    <h2 className="title center">

                                        {this.props.locale === 'en' &&
                                            'How it works'
                                        }
                                        {this.props.locale === 'pl-PL' &&
                                            'Jak to działa?'
                                        }
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="feature-info sticky feature-sticky">
                                    <div className="constant order-md-1 order-2">
                                        <img className="img-fluid" src={remoteControl} alt="#" />
                                        <h2 className="title">
                                            {this.props.locale === 'en' &&
                                                'Your channel, your business'
                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                'Twój kanał, twój biznes'
                                            }
                                        </h2>
                                        <p>
                                            {this.props.locale === 'en' &&
                                                'As a live streamer, you are in complete control of your channel and business. You decide what price you want to charge per minute of streaming, and what you will stream. This means that you can choose to stream whatever content you like, for however long you want. You will also receive weekly payouts directly into your bank account, so you can easily manage your finances.'

                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                'Zarabiaj za każdą minute swojego streama. Utwórz transmisje, ustal cene za minutę i zaproś swoich fanów. Masz pełną kontrolę nad swoim kanałem i biznesem. Sam decydujesz co i kiedy będziesz streamować, a wypłatę otrzymasz co tydzień bezpośrednio na swoje konto bankowe.'
                                            }
                                        </p>
                                    </div>
                                    <div className="feature-img text-end order-md-2 order-1">
                                        <img className="img-fluid" src={lsbusiness} alt="#" />
                                    </div>
                                </div>
                                <div className="feature-info sticky feature-sticky">
                                    <div className="feature-img">
                                        <img className="img-fluid" src={lsmoney1} alt="#" />
                                    </div>
                                    <div className="constant">
                                        <img className="img-fluid" src={moneyEuro} alt="#" />
                                        <h2 className="title">
                                            {this.props.locale === 'en' &&
                                                'Make money instantly'
                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                'Zarabiaj natychmiast'
                                            }
                                        </h2>
                                        <p>

                                            {this.props.locale === 'en' &&
                                                "Are you looking for a way to make money by live streaming? This platform is the best place to earn on live video streaming in pay per minute model. Just set your pay per minute price, invite Your fans and earn money from any viewer.You can stream anything you want, whether it's a gaming session, vlog, cooking show, or anything else. And there's no limit to how much you can earn - it all depends on how popular your stream is and how many viewers you have. So why not give it a try? You could be earning money from your live stream in no time!"

                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                'Szukasz sposobu na zarabianie poprzez transmisje na żywo? Ta platforma jest najlepszym miejscem do zarabiania na transmisjach na żywo w modelu pay-per-minute. Po prostu ustal cenę za minutę swojej transmisji, zaproś swoich fanów i zarabiaj pieniądze od każdego widza. Możesz streamować cokolwiek chcesz - czy to sesję gamingową, vlog, program gotowania lub cokolwiek innego. Nie ma limitu ile możesz zarobić - wszystko zależy od popularności Twojej transmisji i liczby widzów. Dlaczego więc nie spróbować? Możesz w krótkim czasie zarabiać pieniądze ze swojej transmisji na żywo!'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="feature-info sticky feature-sticky">
                                    <div className="constant order-md-1 order-2">
                                        <img className="img-fluid" src={monitorTablet} alt="#" />
                                        <h2 className="title">
                                            {this.props.locale === 'en' &&
                                                'Stream from Laptop, Phone, Or Tablet'
                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                'Streamuj z laptopa, telefonu lub tabletu'
                                            }
                                        </h2>
                                        <p>
                                            {this.props.locale === 'en' &&
                                                "Stream from any device like laptop, phone or tablet. This makes it convenient for anyone to share their live stream with others. The best part is that you don't need any special equipment or software to do this. All you need is a compatible device and an internet connection."

                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                "Streamuj z dowolnego urządzenia, takiego jak laptop, telefon lub tablet. Umożliwia to każdemu wygodne udostępnianie swojej transmisji na żywo innym. Najlepsze jest to, że nie potrzebujesz do tego żadnego specjalnego sprzętu ani oprogramowania. Wszystko, czego potrzebujesz, to kompatybilne urządzenie i połączenie internetowe."
                                            }
                                        </p>
                                    </div>
                                    <div className="feature-img order-md-2 order-1">
                                        <img className="img-fluid" src={lsdevices} alt="#" />
                                    </div>
                                </div>
                                <div className="feature-info sticky feature-sticky">
                                    <div className="feature-img">
                                        <img className="img-fluid" src={lscamera} alt="#" />
                                    </div>
                                    <div className="constant">
                                        <img className="img-fluid" src={moneyEuro} alt="#" />
                                        <h2 className="title">

                                            {this.props.locale === 'en' &&
                                                'Your own Pay Per View live streaming channel'

                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                "Twój własny płatny kanał streamingowy"

                                            }

                                        </h2>
                                        <p>

                                            {this.props.locale === 'en' &&
                                                "Our platform enables users to open their own Pay Per View live streaming channels and earn money for every minute of streaming. In addition, they can also receive tips from viewers. This provides a great opportunity for users to earn income from their content."
                                            }
                                            {this.props.locale === 'pl-PL' &&
                                                "Nasza platforma umożliwia użytkownikom prowadzenie streamów w modelu Pay Per View i zarabianie pieniędzy za każdą minutę transmisji. Dodatkowo, mogą także otrzymywać napiwki od widzów. To daje świetną możliwość użytkownikom generowania dochodu ze swoich treści."
                                            }
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className='text-center pt-3'>
                                <Link to={'/login/#reg'} className='btn btn-primary'><FormattedMessage id="common.registerNow" /></Link>
                            </Col>
                        </Row>
                    </Container>



                </div>


                <div className="space-ptb">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl={8} lg={12}>
                                <div className="section-title text-center">
                                    <h2 className="title center">
                                        {this.props.locale === 'en' &&
                                            'Frequently ask questions'
                                        }
                                        {this.props.locale === 'pl-PL' &&
                                            'Często zadawane pytania'
                                        }
                                    </h2>
                                </div>
                                <Accordion id="accordion-01">
                                    <Accordion.Item >
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">

                                                {this.props.locale === 'en' &&
                                                    'What is Chilli Stream'
                                                }
                                                {this.props.locale === 'pl-PL' &&
                                                    'Czym jest Chilli Stream'
                                                }
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordion-01">
                                            <div className="accordion-body">
                                                <p className="mb-4">
                                                    {this.props.locale === 'en' &&
                                                        "Chillistream is a modern streaming platform that allows streamers to achieve rapid income from live broadcasts. With its user-friendly interface and innovative features, it provides a seamless experience for both streamers and viewers. By utilizing the pay-per-minute model and the option for fans to support streamers with tips, Chillistream offers a great opportunity for content creators to monetize their live streams effectively. Whether you're broadcasting from a phone, tablet, or laptop, Chillistream makes it easy for streamers to connect with their audience and start earning money quickly."
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        "Chillistream to nowoczesna platforma streamingowa, która umożliwia streamerom osiąganie szybkich dochodów z transmisji na żywo. Dzięki swojemu przyjaznemu dla użytkownika interfejsowi i innowacyjnym funkcjom, zapewnia płynne doświadczenie zarówno dla streamerów, jak i widzów. Wykorzystując model pay-per-minute oraz opcję wsparcia streamerów przez fanów w postaci napiwków, Chillistream oferuje doskonałą szansę dla twórców treści na skuteczne monetyzowanie swoich transmisji na żywo. Bez względu na to, czy nadajesz z telefonu, tabletu czy laptopa, Chillistream ułatwia streamerom nawiązanie kontaktu z publicznością i szybkie rozpoczęcie zarabiania pieniędzy."
                                                    }
                                                </p>

                                            </div>
                                        </div>
                                    </Accordion.Item >
                                    <Accordion.Item >
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

                                                {this.props.locale === 'en' &&
                                                    'How it works?'
                                                }
                                                {this.props.locale === 'pl-PL' &&
                                                    'Jak to działa?'
                                                }
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordion-01">
                                            <div className="accordion-body">
                                                <p className="mb-4">
                                                    {this.props.locale === 'en' &&
                                                        "Do you have fans? Do you do live streams? Thanks to Chillistream, you can turn it into fast profit. On Chillistream, you earn in a pay-per-minute model. Every fan watching your stream pays you for each minute on your stream. They can also support you with tips."
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        "Masz fanów? Robisz transmisje na żywo? Dzięki Chillistream możesz zamienić to w szybki zysk. Na Chillistream zarabiasz w modelu pay-per-minute. Każdy fan oglądający Twoją trnsmisje płaci Ci za każdą minutę na Twoim streamie. Mogą także wesprzeć Cię napiwkami."
                                                    }

                                                </p>
                                                <ul className="list list-unstyled ckeck-list mb-0">
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'You set the rate per minute yourself. You can change it at any time.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Sam ustawiasz stawkę za minutę. Możesz ją zmienić w każdej chwili.'
                                                            }
                                                        </span></li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'You can broadcast from any device: phone, tablet, laptop.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Możesz nadawać z dowolnego urządzenia: telefon, tablet, laptop.'
                                                            }
                                                        </span>
                                                    </li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'You receive a payout to your bank account every week.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Otrzymujesz wypłatę na swoje konto bankowe co tydzień.'
                                                            }
                                                        </span>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </Accordion.Item >
                                    <Accordion.Item >
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                {this.props.locale === 'en' &&
                                                    'How to start?'
                                                }
                                                {this.props.locale === 'pl-PL' &&
                                                    'Jak zacząć?'
                                                }
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordion-01">
                                            <div className="accordion-body">
                                                <p className="mb-4">
                                                    {this.props.locale === 'en' &&
                                                        '4 quick steps to your first live stream:'
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        '4 szybkie kroki do Twojego pierwszego live streamu:'
                                                    }
                                                </p>
                                                <ul className="list list-unstyled ckeck-list mb-4">
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Registration: Sign up and create an account.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Rejestracja: Zarejestruj się i utwórz swoje konto.'
                                                            }
                                                        </span>
                                                    </li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Verification: Complete the verification process for your account.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Weryfikacja: Ukończ proces weryfikacji swojego konta.'
                                                            }
                                                        </span></li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Invite fans: Encourage your fans to join and watch your live stream.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Zaproś fanów: Zachęć swoich fanów do dołączenia i oglądania Twojego live streamu.'
                                                            }
                                                        </span>
                                                    </li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Start earning: Begin your live stream and start earning money.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Zacznij zarabiać: Zacznij swój live stream i zacznij zarabiać.'
                                                            }
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    {this.props.locale === 'en' &&
                                                        "And that's it! With these four steps, you can start your first live stream, connect with your fans, and begin earning. Remember to engage with your audience and provide them with exciting content to keep them coming back for more. Good luck with your live streaming journey!"
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        "I to wszystko! Dzięki tym czterem krokom możesz rozpocząć swój pierwszy live stream, połączyć się ze swoimi fanami i zacząć zarabiać. Pamiętaj, aby angażować się w swoją publiczność i dostarczać jej ekscytującej treści, aby zachęcić ją do powrotu po więcej. Powodzenia w Twojej przygodzie z live streamingiem!"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Accordion.Item >
                                    <Accordion.Item >
                                        <h2 className="accordion-header" id="HeadingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                {this.props.locale === 'en' &&
                                                    'What tokens are and how to use them?'
                                                }
                                                {this.props.locale === 'pl-PL' &&
                                                    'Czym są tokeny i jak ich używać?'
                                                }

                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordion-01">
                                            <div className="accordion-body">
                                                <p className="mb-4">
                                                    {this.props.locale === 'en' &&
                                                        'Tokens are our internal means of payment, and through them, users settle their time spent on your live stream and tips. The tokens collected by you are exchanged for compensation at the rate of 1 Token = 0.1 Euro. This system simplifies transactions on the platform and provides a clear and transparent method for compensating streamers for their content and engagement with their audience. As a streamer, you can accumulate tokens during your live streams, and then convert them into real earnings according to the exchange rate.'
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        'Tokeny są naszym wewnętrznym środkiem płatności, a za ich pośrednictwem użytkownicy rozliczają się z czasu spędzonego na Twoim live streamie i napiwków. Tokeny zebrane przez Ciebie wymieniane są na wynagrodzenie w wysokości 1 Token = 0,1 Euro. Ten system upraszcza transakcje na platformie i zapewnia klarowną i przejrzystą metodę wynagradzania streamerów za ich treści i zaangażowanie w interakcje z publicznością. Jako streamer możesz gromadzić tokeny podczas swoich live streamów, a następnie wymieniać je na prawdziwe zarobki zgodnie z kursami wymiany.'
                                                    }

                                                </p>

                                            </div>
                                        </div>
                                    </Accordion.Item >
                                    <Accordion.Item >
                                        <h2 className="accordion-header" id="HeadingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
                                                {this.props.locale === 'en' &&
                                                    'How much can i earn?'
                                                }
                                                {this.props.locale === 'pl-PL' &&
                                                    'Ile mogę zarobić?'
                                                }

                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordion-01">
                                            <div className="accordion-body">
                                                <p className="mb-4">
                                                    {this.props.locale === 'en' &&
                                                        'The exact earnings depend on various factors, and it is not possible to determine precisely how much you will earn on the Chillistream platform. Key factors influencing your earnings will be:'
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        'Dokładne zarobki zależą od wielu czynników, a nie można precyzyjnie określić, ile zarobisz na platformie Chillistream. Kluczowe czynniki wpływające na Twoje zarobki to:'
                                                    }
                                                </p>
                                                <ul className="list list-unstyled ckeck-list mb-4">
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Number of fans: The more fans you gather on your channel, the higher the chance of a larger audience during the livestream, resulting in more tokens earned per minute.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Liczba fanów: Im więcej fanów zgromadzisz na swoim kanale, tym większa szansa na większą publiczność podczas live streamu, co przekłada się na większą liczbę tokenów zarobionych na minutę.'
                                                            }
                                                        </span>
                                                    </li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Livestream duration: Longer livestreams allow for the accumulation of more tokens, but it\'s important to maintain quality and engage the audience throughout.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Czas trwania live streamu: Dłuższe live streamy pozwalają na gromadzenie większej liczby tokenów, ale ważne jest utrzymanie jakości i zaangażowanie publiczności przez cały czas.'
                                                            }

                                                        </span>
                                                    </li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Rate per minute: You set the price for each minute of your livestream. A higher rate means higher earnings per minute of viewership.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                'Stawka za minutę: Ustalasz cenę za każdą minutę swojego live streamu. Wyższa stawka oznacza wyższe zarobki za minutę oglądania.'
                                                            }

                                                        </span>
                                                    </li>
                                                    <li className="d-flex"><i className="fas fa-check"></i>
                                                        <span>
                                                            {this.props.locale === 'en' &&
                                                                'Fan support: Additional tips from fans can significantly increase your earnings.'
                                                            }
                                                            {this.props.locale === 'pl-PL' &&
                                                                "Wsparcie fanów: Dodatkowe napiwki od fanów mogą znacząco zwiększyć Twoje zarobki."
                                                            }

                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    {this.props.locale === 'en' &&
                                                        'The example:'
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        'Przykład:'
                                                    }
                                                </p>
                                                <p>

                                                    {this.props.locale === 'en' &&
                                                        "Let's assume that you have 100 fans on your channel, and you set the price for 1 minute of your livestream at 3 tokens."
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        "Załóżmy, że masz 100 fanów na swoim kanale, a cena za 1 minutę Twojego live streamu wynosi 3 tokeny."
                                                    }
                                                </p>
                                                <p>
                                                    <strong>
                                                        {this.props.locale === 'en' &&
                                                            '3 tokens x 100 viewers on the channel x 10 minutes = 300 euros for 10 minutes.'
                                                        }
                                                        {this.props.locale === 'pl-PL' &&
                                                            '3 tokeny x 100 widzów na kanale x 10 minut = 300 euro za 10 minut.'
                                                        }

                                                    </strong>
                                                </p>
                                                <p>
                                                    {this.props.locale === 'en' &&
                                                        "However, it's essential to remember that these numbers are just examples, and actual earnings will vary depending on the circumstances and engagement of your audience. The key to success on the Chillistream platform will be creating attractive and engaging content, building a loyal fan base, and hosting regular and interesting livestreams."
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        "Jednak należy pamiętać, że te liczby są tylko przykładami, a rzeczywiste zarobki będą się różnić w zależności od okoliczności i zaangażowania Twojej publiczności. Kluczem do sukcesu na platformie Chillistream będzie tworzenie atrakcyjnych i angażujących treści, budowanie lojalnej bazy fanów oraz regularne i interesujące live streamy."
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Accordion.Item >
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </div>


                <div className="space-pb">
                    <Container>
                        <Row>
                            <Col md={12} >
                                <div className="bg-dark text-center space-md-ptb px-4">
                                    <h3 className="mb-3">Go Live, Earn Money, and Chill with Chillistream</h3>
                                    <Link to={'/login/#reg'} className='btn btn-primary'><FormattedMessage id="common.getStarted" /></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div ref={this.backToTopRef} id="back-to-top" className="back-to-top d-none " onClick={() => { this.scrollTop() }}>
                    <a href="#"><i className="fas fa-angle-up"></i></a>
                </div>
            </>
        )
    }

}



function mapStateToProps(state) {
    return {
        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        locale: state.locale.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {

            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Landing1));