import { axiosClient, ApiUrl, HostUrl, upssssText } from './authActions'

export const getMyProfile = (dispatch) => {
  // return dispatch => {
  return new Promise((resolve, reject) => {
    //    console.log('getMyProfile')
    let path = ApiUrl + "my/profile/"
    axiosClient.get(path)
      .then((response) => {
        //   console.log('getMyProfile response', response.data)
        dispatch({ type: "SET_MY PROFILE", data: response.data });
        dispatch({ type: "SET_ALERTS", data: response.data.alerts });
        if (!response.data.isOnboarded) {
          dispatch({ type: "SHOW_ONBOARD_MODAL", data: true });
        }
        resolve('ok')
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            reject(error.response.data)
          }
        }
      })
      .then(function () {
        // always executed
      });
  })
  //   };
}

export function updateProfile(formData, callback) {
  // console.log('updateProfile') 
  let path = ApiUrl + "my/profile/"
  return function (dispatch) {
    axiosClient.patch(path, formData)
      .then((response) => {
        dispatch({ type: "SET_MY PROFILE", data: response.data });
        // dispatch({ type: "SET_ALERTS", data: response.data.alerts });
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        // console.log('updateProfile error',error)
        // console.log('updateProfile error.response',error.response)
        if (error.response) {
          callback({ error: true, data: error.response.data })
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'MY PROFILE_FETCHING', data: false })
      });
  }
}
export function saveVerificationPic(formData, callback) {
  // console.log('updateProfile') 
  let path = ApiUrl + "my/profile/verificationpics/"
  return function (dispatch) {
    axiosClient.patch(path, formData)
      .then((response) => {

        // dispatch({ type: "SET_ALERTS", data: response.data.alerts });
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        // console.log('updateProfile error',error)
        // console.log('updateProfile error.response',error.response)
        if (error.response) {
          callback({ error: true, data: error.response.data })
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
      });
  }
}
export function verifyAccount(callback) {
  // console.log('updateProfile') 
  let path = ApiUrl + "my/profile/verification/"
  return function (dispatch) {
    axiosClient.post(path)
      .then((response) => {
        dispatch({ type: "SET_MY PROFILE", data: response.data });
        // dispatch({ type: "SET_ALERTS", data: response.data.alerts });
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        // console.log('updateProfile error',error)
        // console.log('updateProfile error.response',error.response)
        if (error.response) {
          callback(error.response.data )
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'MY PROFILE_FETCHING', data: false })
      });
  }
}

export function updateAvatar(formData, callback) {
  // console.log('updateAvatar') 
  let path = ApiUrl + "my/profile/"
  return function (dispatch) {
    axiosClient.patch(path, formData, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
      })
      .then((response) => {
        dispatch({ type: "SET_MY PROFILE", data: response.data });
        // dispatch({ type: "SET_ALERTS", data: response.data.alerts });
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        // console.log('updateProfile error',error)
        // console.log('updateProfile error.response',error.response)
        if (error.response) {
          callback({ error: true, data: error.response.data })
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'MY PROFILE_FETCHING', data: false })
        dispatch({ type: 'SET_PRICE_ME_IS_SAVING', data: false })
      });
  }
}

export function updatePayotData(formData, callback) {
  //  console.log('updatePayotData') 
  let path = ApiUrl + "profile/my/payoutdata/"
  return function (dispatch) {
    axiosClient.patch(path, formData)
      .then(function (response) {
        // console.log('updatePayotData response.data', response.data)
        dispatch({ type: "SET_MY PROFILE", data: response.data });
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        // console.log('updatePayotData error',error)
        // console.log('updatePayotData error.response',error.response)
        if (error.response) {
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
      });
  }
}

export function changePassword(formData, callback) {
 // console.log('changePassword') 
  let path = ApiUrl + "auth/password/change/"
  return (dispatch)=> {
    axiosClient.post(path, formData)
      .then(function (response) {
      //  localStorage.setItem("token", response.data.token)
        //axiosClient.defaults.headers.common['Authorization'] = 'token ' + response.data.token
        //dispatch({ type: 'SET_AUTH_TOKEN', data: response.data.token })
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        // console.log('changePassword error',error)
        // console.log('changePassword error.response',error.response)
        if (error.response) {
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
      });
  }
}

export function changeEmailInit(formData, callback) {
//  console.log('changeEmailInit') 
  let path = ApiUrl + "auth/email/change/"
  return (dispatch)=> {
    axiosClient.post(path, formData)
      .then(function (response) {
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
      });
  }
}

export function changeEmailConfirm(formData, callback) {
  //console.log('changeEmailInit') 
  let path = ApiUrl + "auth/email/change/confirm/"
  return (dispatch)=> {
    axiosClient.put(path, formData)
      .then(function (response) {
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
      });
  }
}

export function deleteAccountInit(callback) {
  //  console.log('deleteAccount') 
  let path = ApiUrl + "my/profile/delete/init/"
  return function (dispatch) {
    const fd = new FormData()
    axiosClient.post(path, fd)
      .then(function (response) {
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        //  console.log('deleteAccount error',error)
        // console.log('deleteAccount error.response',error.response)
        if (error.response) {
          callback({ error: true, data: error.response.data })
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'SET_DELETE_ACCOUNT_FETCHING', data: false })
      });
  }
}

export function deleteAccountConfirm(fd, callback) {
  //  console.log('deleteAccount') 
  let path = ApiUrl + "my/profile/delete/confirm/"
  return function (dispatch) {
    axiosClient.post(path, fd)
      .then(function (response) {
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        //  console.log('deleteAccount error',error)
        // console.log('deleteAccount error.response',error.response)
        if (error.response) {
          callback({ error: true, data: error.response.data })
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'SET_DELETE_ACCOUNT_FETCHING', data: false })
      });
  }
}

export function uploadVerificationPic(formData, callback) {
  //  console.log('uploadVerificationPic') 
  let path = ApiUrl + "my/profile/verificationpics/"
  return function (dispatch) {
    dispatch({ type: 'SET_VERIFICATION_PICTURE_FETCHING', data: true })
    axiosClient.post(path, formData)
      .then(function (response) {
        //   console.log('uploadVerificationPic response.data', response.data)
        dispatch({ type: "SET_MY PROFILE", data: response.data });
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        //  console.log('uploadVerificationPic error',error)
        //  console.log('uploadVerificationPic error.response',error.response)
        if (error.response) {
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'SET_VERIFICATION_PICTURE_FETCHING', data: false })
      });
  }
}

export function getVerificationPics(callback) {
  //  console.log('uploadVerificationPic') 
  let path = ApiUrl + "my/profile/verificationpics/"
  return function (dispatch) {
    axiosClient.get(path)
      .then(function (response) {
       // console.log('getVerificationPics response.data', response.data)
        callback(response.data)
      })
      .catch((error) => {
        // handle error
        //  console.log('uploadVerificationPic error',error)
        //  console.log('uploadVerificationPic error.response',error.response)
        if (error.response) {
          //
          if (error.response.status === 400) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 404) {
            callback({ error: true, data: error.response.data })
          }
          if (error.response.status === 403) {
            callback({ error: true, data: error.response.data })
          }
        }
      })
      .then(() => {

      });
  }
}


export function checkDisplayName(displayName, callback) {
  return function (dispatch) {
    //   console.log('getFavorites')
    let path = ApiUrl + "my/profile/checkdisplayname/"+displayName+"/"
    axiosClient.get(path)
      .then((response) => {
        //      console.log('getFavorites data', response.data)
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            callback({ 'error': true, data: error.response.data })
          }
        }
      })
      .then(function () {

      });
  }
}
/** 
export function getFavorites(callback) {
  return function (dispatch) {
    //   console.log('getFavorites')
    let path = ApiUrl + 'favorites/'
    axiosClient.get(path)
      .then((response) => {
        //      console.log('getFavorites data', response.data)
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            callback({ 'error': true, data: error.response.data })
          }
        }
      })
      .then(function () {

      });
  }
}
*/
export function search(callback, text = false, type = false, page = false) {
  return function (dispatch) {
    //   console.log('search')
    let path = ApiUrl + 'search/'
    if (text) {
      path = ApiUrl + 'search/' + text + '/'
    }
    if (type) {
      path = ApiUrl + 'search/' + text + '/' + type + '/'
    }
    if (page) {
      path += '?page=' + page
    }
    axiosClient.get(path)
      .then((response) => {
        ///  console.log('search data', response.data)
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          callback({ 'error': true, data: error })

        }
      })
      .then(function () {

      });
  }
}

export function getHashtagHint(callback, text) {
  return function (dispatch) {

    let path = ApiUrl + 'search/hashtag/hint/' + text + '/'
    //  console.log('getHashtagHint', path)
    axiosClient.get(path)
      .then((response) => {
        ///  console.log('getHashtagHint data', response.data)
        dispatch({ type: 'SET_UNREADED_NOTS_COUNT', data: 0 });
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          callback({ 'error': true, data: error })
        }
      })
      .then(function () {

      });
  }
}

export function getNotifications(callback, page) {
  return function (dispatch) {
    let path = ApiUrl + 'notifications/'
    if (page) {
      path += '?page=' + page
    }
    //  console.log('getNotification', path)
    axiosClient.get(path)
      .then((response) => {
        //   console.log('getNotification data', response.data)
        dispatch({ type: 'SET_UNREADED_NOTS_COUNT', data: 0 })
        callback(response.data)
        // this.props.dispatch({type:'SET_NOTS_DATA', data:response.data})
      })
      .catch((error) => {
        if (error.response) {
          callback({ 'error': true, data: error })
        }
      })
      .then(function () {

      });
  }
}

export function getOnboardForm(callback) {
  return function (dispatch) {
    //console.log('getOnboardForm')
    let path = ApiUrl + 'onboard/'
    axiosClient.get(path)
      .then((response) => {
        //   console.log('getOnboardForm data', response.data)
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          callback({ 'error': true, data: error })

        }
      })
      .then(function () {

      });
  }
}
export function saveOnboard(callback, fd) {
  return function (dispatch) {
    // console.log('saveOnboard')
    let path = ApiUrl + 'onboard/'
    axiosClient.post(path, fd)
      .then((response) => {
        //  console.log('saveOnboard data', response.data)
        //ustawiamy my profile w callback
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          callback({ 'error': true, data: error })

        }
      })
      .then(function () {

      });
  }
}

export function getInvoice(callback, id) {
  return function (dispatch) {
    //  console.log('getInvoice')
    let path = ApiUrl + 'invoice/' + id + '/'
    axiosClient.get(path)
      .then((response) => {
        //   console.log('getInvoice data', response.data)
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          //    console.log('getInvoice error')
          if (error.response.status === 400) {
            callback({ 'error': true, data: error.response.data })
          }
        }
      })
      .then(function () {

      });
  }
}

export function getMySubscriptions(callback, type, filter, filter2) {
  return function (dispatch) {
    //  console.log('getInvoice')
    let path = ApiUrl + 'mysubscriptions/' + type + '/' + filter + '/' + filter2 + '/'
    axiosClient.get(path)
      .then((response) => {
        //console.log('getMySubscriptions data', response.data)
        callback(response.data)
      })
      .catch((error) => {
        if (error.response) {
          callback({ 'error': true, data: error.response.data })
          //console.log('getMySubscriptions error')
        }
      })
      .then(function () {

      });
  }
}

export function subscribePushNots(fd, callback) {
 // return function (dispatch) {
     console.log('subscribePushNots')
    let path = ApiUrl + 'my/pushnots/'
    axiosClient.post(path, fd)
      .then((response) => {
        //  console.log('subscribePushNots response data', response.data)
        //ustawiamy my profile w callback
        callback(response.data)
      })
      .catch((error) => {
        console.log(error.response)
        if (error.response) {
          callback({ 'error': true, data: error })

        }
      })
      .then(function () {

      });
//  }
}