import { createReducer } from '@reduxjs/toolkit'
const initialState={
  audiencePlayerStatus: null,
}

const audience = createReducer(initialState, (builder) => {
    builder
    .addCase('SET_AUDIENCE_PLAYER_CONNECTION_STATUS', (state, action) => {
      state.audiencePlayerStatus=action.data
    })

  })

export default audience