import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import {
    checkDisplayName,
    updateProfile,
    updateAvatar,
    changePassword,
    changeEmailInit,
    deleteAccountInit,
} from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { BiCopy } from 'react-icons/bi'
import { FiMail } from 'react-icons/fi'
import { BiLockAlt } from 'react-icons/bi'
import { BsCamera } from 'react-icons/bs'
import { MdOutlineClose } from 'react-icons/md'
import { TbCamera } from 'react-icons/tb'

import CustomCropper from '../../components/customCropper'

import { Link } from 'react-router-dom'
import {
    BsFillEyeSlashFill,
    BsFillEyeFill,
    BsCheckLg,
    BsEye,
    BsEyeSlash
} from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { injectIntl, FormattedMessage } from 'react-intl'
import innerBanner1 from '../../static/images/bg/innerBanner1.jpg'
import default_avatar_boy from '../../static/default_avatar_boy.png'
import default_avatar_girl from '../../static/default_avatar_girl.png'
import default_avatar_unknown from '../../static/default_avatar_unknown.png'
import default_profile_bg from '../../static/default_profile_bg.png'
import toast, { Toaster, ToastBar } from 'react-hot-toast';
//import StreamerStreaming from '../streamer/streamerStreaming';

class MyProfileView extends Component {


    componentDidMount = () => {//zrobic getMy profile
     //   console.log(this.props.myProfile, this.props.myProfile)
     document.title = 'Chillistream - My Profile'
        this.setState({
            displayName: this.props.myProfile.displayName,
            aboutMe: this.props.myProfile.aboutMe,
            email: this.props.myProfile.email,
        })
    }


    state = {
        aboutMe: '',
        displayName: '',
        emailFormErrors: [],
        passFormErrors: {},
        showEmailChangeForm: false,
        showPassChangeForm: false,
        showPasswordChangeForm: false,
        email: '',
        passInputType: 'password',
        old_password: '',
        password: '',
        password2: '',
        new_password1: '',
        new_password2: '',
        displayNameAvailable: undefined,
        newUrlName: undefined,
        profileFormError: [],
        accountDeleteFetching: false,
        avatarInputKey: (Math.random() + 1).toString(36).substring(7),
        channelBgInputKey: (Math.random() + 1).toString(36).substring(7),
        connectionState:'DISCONNECTED'

    }


    setConnectionState = (state) => {
        this.setState({ connectionState: state })
    }
    resetForms = () => {
        this.setState({
            displayName: this.props.myProfile.displayName,
            displayNameAvailable: undefined,
            newUrlName: undefined,
            aboutMe: this.props.myProfile.aboutMe,
            profileFormError: [],
            passFormErrors: [],
            old_password: '',
            password: '',
            password2: '',
            new_password1: '',
            new_password2: '',
            passInputType: 'password',
            emailFormErrors: [],
            emailSavedSuccess: false,

        })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    resetPasswordForm = () => {
        this.setState({
            old_password: '',
            new_password1: '',
            new_password2: '',
            passFormErrors: {},
            passInputType: 'password',
            showPassCheck: false,
        })
    }

    savePassword = () => {
        this.setState({ passFormErrors: {} })
        const fd = new FormData()
        fd.append('old_password', this.state.old_password)
        fd.append('new_password1', this.state.new_password1)
        fd.append('new_password2', this.state.new_password2)
        this.props.changePassword(fd, this.passwordChanged)
    }

    passwordChanged = (data) => {
        if (data.error) {
            this.setState({ passFormErrors: data.data })
            return
        }
        this.setState({ passwordSuccessMessage: data.detail })
        setTimeout(() => { this.setState({ passwordSuccessMessage: undefined }) }, 1500)
    }

    showPassword = (val) => {
        if (val) {
            this.setState({ passInputType: 'text' })
        }
        else {
            this.setState({ passInputType: 'password' })
        }
    }

    renderShowPasswordIcon = () => {
        if (this.state.passInputType === 'text') {
            return (<BsEye onClick={() => { this.showPassword(false) }} className='passEyeIcon' />)
        }
        else {
            return (<BsEyeSlash onClick={() => { this.showPassword(true) }} className='passEyeIcon' />)
        }
    }

    changeEmail = () => {
        this.setState({ emailFormfetching: true, emailFormErrors: [] })
        const fd = new FormData()
        fd.append('email', this.state.email)
        this.props.changeEmailInit(fd, this.emailChanged)
    }

    emailChanged = (data) => {
      //  console.log('emailChanged', data)
        this.setState({ emailFormfetching: false })
        if (data.error) {
            this.setState({ emailFormErrors: data.data })
            return
        }
        this.setState({ emailSavedSuccess: data.detail })

    }
    handleAboutMeChange = (e) => {
        this.setState({ aboutMe: e.target.value })
    }
    handleDisplayNameChange = (e) => {
        clearTimeout(this.state.displayNameTimoutId)
        this.setState({ displayNameCheckFetching: false, displayNameAvailable: undefined })
        this.setState({ displayName: e.target.value },
            () => {
                if (this.state.displayName.length < 3) {
                    return
                }
                if (this.state.displayName === this.props.myProfile.displayName) {
                    return
                }
                const timoutId = setTimeout(() => {
                    this.setState({ displayNameCheckFetching: true, })
                    this.props.checkDisplayName(this.state.displayName, this.displayNameChecked)
                }, 500)
                this.setState({ displayNameTimoutId: timoutId })
            }
        )
    }

    displayNameChecked = (data) => {
      //  console.log('displayNameChecked', data)
        if (this.state.profileFormError.displayName) {
            let profFormErrorCopy = JSON.parse(JSON.stringify(this.state.profileFormError))
            profFormErrorCopy.displayName = undefined
            this.setState({ profileFormError: profFormErrorCopy })

        }
        this.setState({ displayNameCheckFetching: false, displayNameAvailable: data.available, newUrlName: data.newUrlName })
    }

    updateProfile = () => {
        this.setState({ profileFormFetching: true, profileFormError: [] })
        const fd = new FormData()
        if (this.state.displayName !== this.props.myProfile.displayName) {
            fd.append('displayName', this.state.displayName)
        }
        fd.append('aboutMe', this.state.aboutMe)
        this.props.updateProfile(fd, this.profileUpdated)
    }

    profileUpdated = (data) => {
     //   console.log('profileUpdated', data)
        this.setState({ profileFormFetching: false })
        if (!data.error) {
            this.setState({ profileSavedSuccess: true }, () => {
                setTimeout(() => {
                    this.setState({ profileSavedSuccess: false })
                }, 1500)
            })
        }
        else {
            this.setState({ profileFormError: data.data })
        }
    }
    handleAvatarOnChange = (e) => {
        this.setState({ newAvatarEvent: e, showAvatarCropper: true })
    }
    saveAvatar = (data) => {
        this.setState({ avatarSaveFetching: true })
        const fd = new FormData()
        fd.append('avatar', data)
        this.props.updateProfile(fd, this.avatarSaved)
        //upload!
    }
    avatarSaved = (data) => {
        this.setState({ avatarSaveFetching: false, avatarSaveSuccess: true })
    }
    resetChannelBgPicInput = () => {
        this.setState({ channelBgInputKey: (Math.random() + 1).toString(36).substring(7) })

    }

    handleChannelBgPicInputOnChange = (e) => {
        this.setState({ newChannelBgPicEvent: e, showChannelBgPicCropper: true })
    }

    saveChannelBgPic = (data) => {
        this.setState({ channelBgPicSaveFetching: true })
        const fd = new FormData()
        fd.append('channelBgPic', data)
        this.props.updateProfile(fd, this.channelBgPicSaved)
    }
    channelBgPicSaved = (data) => {
      //  console.log('channelBgPicSaved', data)
        this.setState({ channelBgPicSaveFetching: false, channelBgPicSaveSuccess: true })
    }

    accountDeleteToast = () => {
        toast((t) => (
            <Row>
                <Col xs={12}>
                    <div className="section-title">
                        <h4><FormattedMessage id="myProfile.deleteAccount" /></h4>
                    </div>
                </Col>
                <Col xs={12} className='mb-3 text-center '>
                    
                    <FormattedMessage id="myProfile.deleteAccountWarningInfo" />
                </Col>
                <Col xs={6} className='text-center mb-3'>
                    <Button size={'sm'} variant={'primary'} onClick={() => { toast.dismiss(t.id); this.accountDeleteInit() }}>
                    <FormattedMessage id="common.delete" />
                    </Button>
                </Col>
                <Col xs={6} className='mb-3 text-center '>
                    <Button size={'sm'} variant={'light'} onClick={() => toast.dismiss(t.id)}>
                    <FormattedMessage id="common.cancel" />
                    </Button>
                </Col>
            </Row>
        ), { duration: Infinity, });

    }
    accountDeleteInit = () => {
        this.setState({ accountDeleteFetching: true })
        this.props.deleteAccountInit(this.accountDeleteFetched)
    }
    accountDeleteFetched = (data) => {
       // console.log('accountDeleteFetched', data)
        this.setState({ accountDeleteFetching: false })
        if (!data.error) {
            this.setState({ accountDeleteSuccessMessage: data.detail })
        }
        //accountDeleteSuccessMessage

    }

    copyToClipboard = (text) => {
      //  console.log('copyToClipboard', text)
        try {
            navigator.clipboard.writeText(text);
        }
        catch (e) {
            console.log(e)
        }

      //  console.log('toast')
        toast.success('Copied to clipboard')
    }
    render() {
        var host = window.location.protocol + "//" + window.location.hostname + "/"
        if (!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }

        let avatar = default_avatar_unknown
        if (this.props.myProfile.gender === 1) {
            avatar = default_avatar_girl
        }
        if (this.props.myProfile.gender === 2) {
            avatar = default_avatar_boy
        }
        if (this.props.myProfile.avatar) {
            avatar = this.props.myProfile.avatar
        }
        let bgPic=innerBanner1;
        if(this.props.myProfile.channelBgPic){
            bgPic=this.props.myProfile.channelBgPic
        }
        
        return (
            <>
                <Toaster
                >
                    {(t) => (
                        <ToastBar toast={t}
                            style={{
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            }}
                        >
                            {({ icon, message }) => (
                                <>
                                    {icon}
                                    {message}
                                </>
                            )}
                        </ToastBar>
                    )}

                </Toaster>
                <div className="inner-banner bg-holder bg-overlay-black-3" style={{ backgroundImage: "url(" + bgPic + ")" }}>
                    <Container className="position-relative">
                        <Row >
                            <Col md={12} className='profileHeader'>
                                <label className="  profBgChangeBtn">
                                    <TbCamera size={22} />
                                    <input id="profBgUploadImageInput"
                                        key={this.state.avatarInputKey}
                                        //disabled={this.props.postEditModalFilesInputFetching ? true : false}
                                        //   multiple
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        style={{ display: 'none' }}
                                        onChange={(e) => this.handleChannelBgPicInputOnChange(e)}
                                    />
                                </label>
                                <div className="header-inner-title">

                                </div>
                                <div className="profile-info">
                                    <div className="profile-avatar">
                                        <img className="img-fluid" src={avatar} alt="" />
                                        <label className='profImgChangeBtn'>
                                            <TbCamera size={22} />
                                            <input id="profBgUploadImageInput"
                                                key={this.state.avatarInputKey}
                                                //disabled={this.props.postEditModalFilesInputFetching ? true : false}
                                                //   multiple
                                                type="file"
                                                accept="image/jpeg, image/png"
                                                style={{ display: 'none' }}
                                                onChange={(e) => this.handleAvatarOnChange(e)}
                                            />
                                        </label>
                                    </div>
                                    <div className='name-info'>
                                        <h5 className="title">{this.props.myProfile.displayName}</h5>
                                        <span className='capitalize '>@{this.props.myProfile.urlName}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* 
                <Container className="position-relative mt-3" fluid={this.state.connectionState==='CONNECTED'?true:false}>
                    
                    <StreamerStreaming setConnectionState={this.setConnectionState}/>
                </Container>
               */}
                <div className="">
                    <Container>
                        <Row className=" justify-content-center">
                            <Col lg={12}>
                                <div className="my-account">
                                    <Row>
                                        <Col xl={3} md={4} >
                                            <div className="profile-side-bar">
                                                <div className="profile-tabs tabs">
                                                    <ul className="nav nav-tabs nav-pills mb-3 flex-column" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button onClick={() => this.resetForms()} className="nav-link active" id="pills-edit-tab" data-bs-toggle="pill" data-bs-target="#pills-editProfile" type="button" role="tab" aria-controls="pills-editProfile" aria-selected="false"><i className="fa-regular fa-user"></i> <FormattedMessage id="myProfile.editProfile" /></button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button onClick={() => this.resetForms()} className="nav-link " id="pills-security-tab" data-bs-toggle="pill" data-bs-target="#pills-security" type="button" role="tab" aria-controls="pills-security-tab" aria-selected="true"><BiLockAlt /><FormattedMessage id="myProfile.security" /></button>
                                                        </li>

                                                        <li className="nav-item" role="presentation">
                                                            <button onClick={() => this.resetForms()} className="nav-link" id="pills-email-tab" data-bs-toggle="pill" data-bs-target="#pills-email" type="button" role="tab" aria-controls="pills-email" aria-selected="false"><FiMail /> <FormattedMessage id="myProfile.changeEmail" /></button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={9} md={8} className="border-left">
                                            <div className="subscriptions-info tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade edit-form show active" id="pills-editProfile" role="tabpanel" aria-labelledby="pills-editProfile" tabIndex="0">
                                                    <div className="section-title">
                                                        <h4><FormattedMessage id="myProfile.editProfile" /></h4>
                                                    </div>
                                                    <Row >
                                                        <Col xs={12} className="mb-3">
                                                            <label className="form-label"><FormattedMessage id="myProfile.aboutMe" />:</label>
                                                            <Form.Control
                                                                as="textarea"
                                                                id="aboutMeInput"
                                                                type="text"
                                                                value={this.state.aboutMe}
                                                                name={'aboutMe'}
                                                                placeholder="About Me"
                                                                onChange={this.handleAboutMeChange}
                                                                disabled={this.state.profileFormFetching}
                                                                isInvalid={this.state.profileFormError.aboutMe}
                                                                autoComplete="none"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.state.profileFormError.aboutMe && this.state.profileFormError.aboutMe.map((item, index) => {
                                                                    return <div key={'aboutMeErrFbop' + index}>{item}</div>
                                                                })}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <label className="form-label"><FormattedMessage id="myProfile.nickname" />: {this.state.displayNameCheckFetching && <Spinner size="sm" animation="border" variant="warning" />}{this.state.displayNameAvailable === true && <span className='text-primary'>Available</span>}{this.state.displayNameAvailable === false && <span className='text-danger'>Aalready Taken</span>}</label>
                                                            <Form.Control
                                                                id="pseudonimInput"
                                                                type="text"
                                                                value={this.state.displayName}
                                                                name={'displayName'}
                                                                placeholder="Nickname"
                                                                onChange={this.handleDisplayNameChange}
                                                                disabled={this.state.profileFormFetching}
                                                                isInvalid={this.state.profileFormError.displayName}
                                                                autoComplete="off"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.state.profileFormError.displayName && this.state.profileFormError.displayName.map((item, index) => {
                                                                    return <div key={'displayNameErrFbop' + index}>{item}</div>
                                                                })}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        {(this.state.displayName !== this.props.myProfile.displayName) && this.state.newUrlName &&
                                                            <Col md={12} className="mb-3">
                                                                <label className="form-label"><FormattedMessage id="myProfile.NewLinkToYourProfile" />:</label>
                                                                <Row>
                                                                    <Col className='ps-5 bg-overlay-black-4'>
                                                                        <span className=''>{host + this.state.newUrlName}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        }
                                                        <Col md={12} className="mb-3">
                                                            <label className="form-label"><FormattedMessage id="myProfile.LinkToYourProfile" />:</label>
                                                            <Row>
                                                                <Col className='ps-5 profileEditUrlName break-all' onClick={() => this.copyToClipboard(host + this.props.myProfile.urlName)}>
                                                                    <span className=''>{host + this.props.myProfile.urlName}</span> <BiCopy />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xl={6} className='mt-3'>
                                                            {!this.state.profileSavedSuccess &&
                                                                <Button
                                                                    onClick={() => this.updateProfile()}
                                                                    variant={'primary'}
                                                                    className=" me-2"
                                                                    disabled={this.state.profileFormFetching}
                                                                >
                                                                    <FormattedMessage id="common.save" /> {this.state.profileFormFetching && <Spinner size={'sm'} />}
                                                                </Button>
                                                            }
                                                            {this.state.profileSavedSuccess &&
                                                                <span className='btn btn-primary'><FormattedMessage id="common.save" /> <BsCheckLg /></span>

                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade edit-form  " id="pills-security" role="tabpanel" aria-labelledby="pills-security" tabIndex="0">
                                                    <div className="section-title">
                                                        <h4><FormattedMessage id="myProfile.security" /></h4>
                                                    </div>
                                                    <Row >
                                                        <Col xs={12} className="">
                                                            <Row>
                                                                <Col xs={'auto'}>
                                                                    <label className="form-label"><FormattedMessage id="myProfile.changePassword" />:</label>
                                                                </Col>
                                                                <Col className='text-end pe-3 pb-2 '>
                                                                    {this.renderShowPasswordIcon()}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <Form.Control
                                                                id="oldPassInput"
                                                                type={this.state.passInputType}
                                                                value={this.state.old_password}
                                                                name={'old_password'}
                                                                isInvalid={this.state.passFormErrors.old_password}
                                                                placeholder={this.props.intl.formatMessage({ id: "profileSettings.oldPassword" })}
                                                                onChange={this.handleChange}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.state.passFormErrors.old_password && this.state.passFormErrors.old_password.map((item, index) => {
                                                                    return <div key={'passErrFbop' + index}>{item}</div>
                                                                })}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col md={6} className="mb-3">
                                                            <Form.Control
                                                                id="PassInput1"
                                                                type={this.state.passInputType}
                                                                value={this.state.new_password1}
                                                                name={'new_password1'}
                                                                placeholder={this.props.intl.formatMessage({ id: "profileSettings.newPassword" })}
                                                                onChange={this.handleChange}
                                                                isInvalid={this.state.passFormErrors.new_password1}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.state.passFormErrors.new_password1 && this.state.passFormErrors.new_password1.map((item, index) => {
                                                                    return <div key={'passErrFbp' + index}>{item}</div>
                                                                })}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <Form.Control
                                                                id="PassInput2"
                                                                type={this.state.passInputType}
                                                                value={this.state.new_password2}
                                                                name={'new_password2'}
                                                                placeholder={this.props.intl.formatMessage({ id: "profileSettings.confirmNewPassword" })}
                                                                onChange={this.handleChange}
                                                                isInvalid={this.state.passFormErrors.new_password2}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.state.passFormErrors.new_password2 && this.state.passFormErrors.new_password2.map((item, index) => {
                                                                    return <div key={'passErrFbp2' + index}>{item}</div>
                                                                })}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        <Col xl={6} className='mt-3'>
                                                            {!this.state.passwordSuccessMessage &&
                                                                <Button
                                                                    onClick={() => { this.savePassword(); }}
                                                                    variant={'primary'}
                                                                    className=" me-2"
                                                                    disabled={this.state.profileFormFetching}
                                                                >
                                                                    <FormattedMessage id="common.save" /> {this.state.profileFormFetching && <Spinner size={'sm'} />}
                                                                </Button>
                                                            }
                                                            {this.state.passwordSuccessMessage &&
                                                                <span className='btn btn-primary'><FormattedMessage id="myProfile.passwordUpdated" /> <BsCheckLg /></span>

                                                            }
                                                        </Col>
                                                        <Col xs={12} className='mt-5'>
                                                            {!this.state.accountDeleteSuccessMessage && !this.state.accountDeleteFetching &&
                                                                <button disabed={this.state.accountDeleteFetching}
                                                                    onClick={() => { this.accountDeleteToast() }}
                                                                    className="btn btn-link text-danger"><i className="fa-solid fa-arrow-right-from-bracket"></i><FormattedMessage id="myProfile.deleteAccount" />
                                                                </button>
                                                            }
                                                            {!this.state.accountDeleteSuccessMessage && this.state.accountDeleteFetching &&
                                                                <span className="btn btn-link text-danger" style={{ cursor: 'default', textDecoration: 'none' }}><FormattedMessage id="common.pleaseWait" />...  {this.state.accountDeleteFetching && <Spinner size={'sm'} />}</span>
                                                            }
                                                            {!this.state.accountDeleteFetching && this.state.accountDeleteSuccessMessage &&
                                                                <span className="btn btn-link text-danger" style={{ cursor: 'default', textDecoration: 'none' }}><FormattedMessage id="myProfile.AccountDeleteInitInfo" /></span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade edit-form  " id="pills-email" role="tabpanel" aria-labelledby="pills-email" tabIndex="0">
                                                    <div className="section-title">
                                                        <h4><FormattedMessage id="myProfile.changeEmail" /></h4>
                                                    </div>
                                                    <Row >
                                                        <Col xs={12} className="">
                                                            <label className="form-label"><FormattedMessage id="myProfile.changeEmail" /></label>

                                                            <Form.Control
                                                                //  id="emailInput"
                                                                type="email"
                                                                value={this.state.email}
                                                                placeholder="email"
                                                                name={'email'}
                                                                onChange={this.handleChange}
                                                                isInvalid={this.state.emailFormErrors.email}
                                                                disabled={this.state.emailFormfetching}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.state.emailFormErrors.email && this.state.emailFormErrors.email.map((item, index) => {
                                                                    return <div key={'emailErr' + index}>{item}</div>
                                                                })}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        <Col xl={6} className='mt-3'>
                                                            {!this.state.emailSavedSuccess &&
                                                                <Button
                                                                    onClick={() => this.changeEmail()}
                                                                    variant={'primary'}
                                                                    className=" me-2"
                                                                    disabled={this.state.emailFormfetching}
                                                                >
                                                                    <FormattedMessage id="common.save" /> {this.state.emailFormfetching && <Spinner size={'sm'} />}
                                                                </Button>
                                                            }
                                                            {this.state.emailSavedSuccess &&
                                                                <span className='text-primary'>{this.state.emailSavedSuccess}</span>

                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal
                    centered
                    show={this.state.showAvatarCropper}
                    onHide={() => this.resetAvatarInput()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header >
                        <Container className=''>
                            <Col xs={12} className=''>
                                <Row>
                                    <Col xs={'auto'} >
                                        <h4><FormattedMessage id="myProfile.profilePicture" /></h4>
                                    </Col>
                                    <Col className='text-end'>
                                        <MdOutlineClose
                                            onClick={() => {
                                                !this.state.avatarSaveFetching && this.setState({
                                                    showAvatarCropper: false,
                                                    newAvatarEvent: undefined,
                                                    avatarSaveSuccess: undefined
                                                })
                                            }}
                                            className='closeButton'
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </Modal.Header>
                    <Modal.Body>
                        <CustomCropper e={this.state.newAvatarEvent}
                            setCroppedData={(data) => this.saveAvatar(data)}
                            fetching={this.state.avatarSaveFetching}
                            success={this.state.avatarSaveSuccess}
                            aspectRatio={1}
                        />
                    </Modal.Body>
                </Modal>

                <Modal
                    centered
                    show={this.state.showChannelBgPicCropper}
                    onHide={() => this.resetChannelBgPicInput()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header >
                        <Container className=''>
                            <Col xs={12} className=''>
                                <Row>
                                <Col xs={'auto'} >
                                        <h4><FormattedMessage id="myProfile.backgroundPicture" /></h4>
                                    </Col>
                                    <Col className='text-end'>
                                        <MdOutlineClose
                                            onClick={() => {
                                                !this.state.channelBgPicSaveFetching && this.setState({
                                                    showChannelBgPicCropper: false,
                                                    newChannelBgPicEvent: undefined,
                                                    channelBgPicSaveSuccess: undefined
                                                })
                                            }}
                                            className='closeButton'
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </Modal.Header>
                    <Modal.Body>
                        <CustomCropper e={this.state.newChannelBgPicEvent}
                            setCroppedData={(data) => this.saveChannelBgPic(data)}
                            fetching={this.state.channelBgPicSaveFetching}
                            success={this.state.channelBgPicSaveSuccess}
                            aspectRatio={1920/366}
                        />
                    </Modal.Body>
                </Modal>
            </>
        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        checkDisplayName,
        updateProfile,
        updateAvatar,
        changePassword,
        changeEmailInit,
        deleteAccountInit,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn,
        myProfile: state.profile.myProfile
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(MyProfileView));
