import { createReducer } from '@reduxjs/toolkit'
const initialState={
    connected: false,
    broadcastPlayerStatus: null,
    broadcastStatus:'offline',
    rtcProps: null,
    localTracks: {},
    cameraEnabled:false,
    broadcastId:undefined,
    currentBroadcastIncome:0,// DOCHOD Z BIERZACEJ TRANSMISJI
    isHost:false,
}

const broadcast = createReducer(initialState, (builder) => {
    builder
    .addCase('SET_IS_HOST', (state, action) => {
      state.isHost=action.data
    })
    .addCase('SET_BROADCAST_STATUS', (state, action) => {
      state.broadcastStatus=action.data
    })
    .addCase('SET_BROADCAST_CONNECTED', (state, action) => {
      state.connected=action.data
    })
    .addCase('SET_BROADCAST_ID', (state, action) => {
      state.broadcastId=action.data
    })
    .addCase('SET_CURRENT_INCOME', (state, action) => {
      state.currentBroadcastIncome=action.data
    })
    .addCase('SET_BROADCAST_PLAYER_CONNECTION_STATUS', (state, action) => {
      state.broadcastPlayerStatus=action.data
    })

    .addCase('SET_LOCAL_TRACKS', (state, action) => {
        state.localTracks=action.data
      })
    .addCase('SET_RTC_PROPS', (state, action) => {
        state.rtcProps=action.data
      })
    .addCase('SET_CAMERA_ENABLED', (state, action) => {
        state.cameraEnabled=action.data
      })
    .addCase('RESET_BROADCATS', (state, action) => {
        return initialState
      })
  })

export default broadcast