import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoLarge from '../../static/logoLarge.png'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from "react-router-dom";
function Footer(props) {
    const date=new Date().getFullYear()
    //let showContact=false
    const [showContact, setShowContact] = useState(false);
    return (
        <>
            <footer className="footer bg-dark">
                <div className="min-footer">
                    <Container>
                        <Row className=" align-items-center">
                            <Col sm={3}>
                                <a className="footer-logo" href="index.html">
                                    <img className="img-fluid" src={logoLarge} alt="logo" />
                                </a>
                            </Col>
                        </Row>
                        <hr className="mt-4 mb-4 mb-md-5" />
                        <Row>
                            <Col xs={12} lg={4} md={12} >
                                <h4 className="footer-title">Turn Your Passion into Profit</h4>
                                <p>
                                Let your passions bloom into a garden of profit, where every seed of creativity blossoms into success.
                                </p>
                                <div className="social-icon mt-lg-4">
                                    <ul>
                                        <li><a href="https://www.facebook.com/chillistream" target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                                        {/** 
                                        <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                        */}
                                        <li><a href="https://www.instagram.com/chillistream_com/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col  lg={6} sm={6} className="col mt-4 mt-md-5 mt-lg-0 mx-sm-auto">
                                <div className="footer-link">
                                    <h4 className="footer-title"><FormattedMessage id="footer.exploreMore" /></h4> 
                                    <ul className="list-unstyled mb-0">
                                        <li><Link to={'/docs/terms'}><FormattedMessage id="footer.TermsAmdCond" /></Link></li>
                                        <li><Link to={'/docs/privacy'}><FormattedMessage id="footer.PrivacyPolicy" /></Link></li>
                                        <li><Link to={'/docs/cookies'}><FormattedMessage id="footer.CookiesPolicy" /></Link></li>
                                        <li><Link to={'/faq/'}>FAQ</Link></li>
                                        {!showContact&&
                                            <li ><span className='cursor' onClick={()=>{setShowContact(true)}}><FormattedMessage id="footer.Contact" /></span></li>
                                        }
                                        {showContact&&
                                            <li className='text-primary'>support@chillistream.com</li>
                                        }
                                    </ul>
                                </div>
                            </Col>
                            {/*
                            <Col  lg={2} sm={3} className="col mt-4 mt-md-5 mt-lg-0">
                                <div className="footer-link">
                                    <h4 className="footer-title">Explore More</h4>
                                    <ul className="list-unstyled mb-0">
                                        <li><a href="#">Terms and conditions</a></li>
                                        <li><a href="#">Privacy policy</a></li>
                                        <li><a href="#">FAQ</a></li>
                                        <li><a href="#">Contact</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col  lg={2} sm={3} className="col mt-4 mt-md-5 mt-lg-0">
                                <div class="footer-link">
                                    <h4 className="footer-title">Company</h4>
                                    <ul className="list-unstyled mb-0">
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#">Advertise With Us</a></li>
                                        <li><a href="#">Contact US</a></li>
                                        <li><a href="#">FAQ</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={4} sm={6} className=" mt-4 mt-md-5 mt-lg-0">
                               
                                <div className="footer-link">
                                    <h4 className="footer-title">Shows & Movies by Genres</h4>
                                    <div className="footer-menu d-flex">
                                        <ul className="list-unstyled mb-0">
                                            <li><a href="#">Drama Shows</a></li>
                                            <li><a href="#">Comedy Shows</a></li>
                                            <li><a href="#">Animation</a></li>
                                            <li><a href="#">Reality Shows</a></li>
                                            <li><a href="#">Romantic Shows</a></li>
                                        </ul>
                                        <ul className="list-unstyled mb-0">
                                            <li><a href="#">Action Movies</a></li>
                                            <li><a href="#">Drama Movies</a></li>
                                            <li><a href="#">Romantic Movies</a></li>
                                            <li><a href="#">Comedy Movies</a></li>
                                            <li><a href="#">Horror Movies</a></li>
                                        </ul>
                                    </div>
                                </div>
                              
                            </Col>
                        // */}
                        </Row>
                        <hr className="footer-hr" />
                        <Row className=" justify-content-center">
                            <Col md={10} >
                                <div className="owl-carousel owl-nav-bottom-center client-logo" data-nav-arrow="false" data-items="6" data-md-items="4" data-sm-items="3" data-xs-items="3" data-xx-items="2" data-space="40" data-autoheight="true">
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/01.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/02.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/03.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/04.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/05.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/01.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/02.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-fluid" src="images/client-logo/03.png" alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="footer-bottom bg-black text-center">
                    <Container>
                        <Row className=" copyright">
                            <Col md={12} >
                                <p className="mb-0"> &copy; Copyright <span id="copyright">{date} </span> <a href="index.html"> Chillistream.com </a> All Rights Reserved</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    );
}

export default injectIntl(Footer);