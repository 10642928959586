import { createReducer } from '@reduxjs/toolkit'
const initialState={
    locale:null,
}

const locale = createReducer(initialState, (builder) => {
    builder
    .addCase('SET_LOCALE', (state, action) => {
      state.locale=action.data
    })
  })

export default locale