import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoLarge from '../../static/logoLarge.png'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from "react-router-dom";
function PrivacyPolicyEn(props) {
    window.scrollTo(0, 0);
    return (
        <Container style={{ paddingTop: '100px' }}>
            <Row className="">
                <Col xs={12} className=' '>
                    <h3 className="">POLITYKA PRYWATNOŚCI OCHRONY DANYCH OSOBOWYCH</h3>
                </Col>
                <Col xs={12} className=' '>
                    <h4 className="terms-title">I. PREAMBUŁA</h4>
                    Odwiedzając Naszą witrynę, powierzasz nam swoje dane, które przetwarzamy do konkretnych celów, ale zawsze z troską o ochronę Twojej prywatności. 
                    Niniejsza Polityka prywatności służy jako pomoc w zrozumieniu, jakie dane zbieramy i w jakim celu oraz do czego je wykorzystujemy. 
                    Informacje określone w niniejszym dokumencie są dla Ciebie bardzo ważne, ponieważ poruszyliśmy w nim sprawy związane z przetwarzaniem danych osobowych, 
                    szczególnie wobec treści nowych przepisów o ochronie danych osobowych, w tym Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016r.
                     w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE 
                     (dalej zwane „RODO”). Z tego względu, w niniejszym dokumencie informujemy o podstawach prawnych przetwarzania danych osobowych, sposobach ich zbierania i wykorzystywania,
                      a także o prawach podmiotów danych z tym związanych. Zależy Nam abyś zapoznał się z tym dokumentem i oto Cię prosimy.

                      <h4 className="terms-title">II. INFORMACJE OGÓLNE</h4>
                      Czym są dane osobowe i co oznacza ich przetwarzanie? Dane osobowe oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej. 
                      Przetwarzaniem danych osobowych jest w zasadzie każda czynność na danych osobowych, niezależnie od tego, czy jest dokonywana w sposób zautomatyzowany czy nie, 
                      np. zbieranie, przechowywanie, utrwalanie, porządkowanie, modyfikowanie, przeglądanie, wykorzystywanie, udostępnianie, ograniczanie, usuwanie lub niszczenie. 
                      Przetwarzamy Twoje określone w niniejszym dokumencie dane osobowe w różnych celach, przy czym w procesie przetwarzania zastosowane mogą mieć różne metody zbierania, 
                      podstawy legalności przetwarzania, wykorzystywania, ujawniania oraz okresy przechowywane, które zawsze określone są w stosunku do celu, 
                      w jakim dane są przez Nasz przetwarzane. Kiedy niniejsza Polityka prywatności ma zastosowanie? Niniejsza polityka prywatności ma zastosowanie do wszystkich przypadków, 
                      w których Jesteśmy administratorem danych osobowych i przetwarzamy dane osobowe. Dotyczy to zarówno przypadków, w których przetwarzamy dane osobowe pozyskane 
                      bezpośrednio od osoby, której dane dotyczą, jak i przypadku, w którym dane osobowe pozyskaliśmy z innych źródeł. W jaki sposób, 
                      w oparciu o jakie podstawy prawne i jakiego typu dane osobowe przetwarzamy ? W celu zachowania przejrzystości w zakresie sposobów i podstaw prawnych 
                      przetwarzania danych osobowych, a także celów dla których przetwarzamy dane osobowe, każdorazowo jako Administrator Danych wskazujemy niezbędne informacje w 
                      tym zakresie każdej osobie, której dane osobowe przetwarzamy. Kierując się określoną powyżej zasadą, by nasze wyjaśnienie tych kwestii było jak najbardziej czytelne, 
                      przedstawiamy w niniejszym dokumencie zestawienie operacji przetwarzania danych osobowych opartych na różnych podstawach legalności procesu przetwarzania w tym 
                      ilekroć gdy wskazujemy podstawę legalności procesu przetwarzania opartego na uzasadnionym interesie administratora danych, staramy się przeanalizować i zrównoważyć 
                      Nasz interes oraz potencjalny wpływ procesu na osobę fizyczną, której dane dotyczą (zarówno pozytywne jak i negatywne następstwa procesu) oraz prawa osoby fizycznej 
                      wynikające z przepisów. Oświadczamy, że jako Administrator Danych nie przetwarzamy danych osobowych w oparciu o Nasz uzasadniony interes w przypadku, gdy dojdziemy 
                      do wniosku że wpływ na osobę, której dane dotyczą przeważałby nad naszymi interesami (wówczas możemy przetwarzać dane osobowe jeśli np. posiadamy odpowiednią zgodę 
                      lub wymagają tego lub zezwalają na to przepisy prawa).

                      <h4 className="terms-title">III. INFORMCJA O ADMINISTRATORZE DANYCH I KATEGORII OSÓB FIZCZNYCH, KTÓYCH DANE DOTYCZĄE</h4>
                      Administrator Danych Spełniając Nasz prawny obowiązek informujemy, że Administratorem Danych osobowych zbieranych za pośrednictwem strony www.chillistream.com jest 
                      WM Media sp. z o.o., NIP: 5242952600, REGON: 52336518800000, z siedzibą w Warszawie, UL. JÓZEFA MEHOFFERA 70C/1, 03-131 Warszawa. Masz prawo skontaktować się z 
                      nami wysyłając nam informację na adres e-mail support@watchmemore.com Kategorie osób fizycznych, których dane dotyczą: Przetwarzane dane osobowe na niniejszej 
                      stronie dotyczy: - osób fizycznych (uzytkowników) rejestrujących się w serwisie chillistream.com (formularz rejestracji, rejestracja za pośrednictwem konta Facebook) - 
                      osób fizycznych (uzytkowników) odwiedzających serwis chillistream.com

                      <h4 className="terms-title">IV. CEL I ZAKRES ZBIERANIA DANYCH ORAZ ODBIORCY DANYCH</h4>
                      Osoba fizyczna odwiedzająca stronę internetową lub korzystające z usług świadczonych poprzez stronę drogą elektroniczną, zawsze mają kontrolę nad danymi osobowymi, 
                      które nam podają. W założeniu Nasza strona ogranicza zbieranie i wykorzystywanie informacji o ich użytkownikach do niezbędnego minimum, wymaganego do świadczenia na 
                      ich rzecz usług na pożądanym poziomie, stosownie do art. 18 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną. Każdorazowo cel i zakres danych 
                      przetwarzanych przez Administratora Danych wynika z niezbędnego minimum związanego z konicznością świadczenia na rzecz użytkownika usług w przypadkach: - 
                      dostosowywania treści strony do oczekiwań użytkownika, - analizowania problemów pracy serwera, zapobieganiu ewentualnych naruszeń i zapewnieniu bezpieczeństwa s
                      trony www, - analizowania statystyki odwiedzin strony www w tym również analizowania danych demograficznych użytkowników odwiedzających stronę 
                      (informacji o regionie i urządzeniu z którego nastąpiło połączenie), - działań podejmowanych przez Nas na Twoją rzecz. Przykładowo, 
                      jeżeli Chcesz zadać Nam pytanie lub zostać twórcą treści i publikować w naszym serwisie, możesz zostać poproszony o podanie określonego zakresu danych osobowych 
                      1. Cel zbierania danych osobowych Twoje dane osobowe zbieramy w jednym z poniżej definiowanych celów: - 
                      w celu dostosowania zawartości serwisu do Twoich potrzeb i zainteresowań oraz prawidłowego zarządzania stroną www. 
                      W tym celu na Naszej stronie wykorzystywane są pliki Cookies (polityka plików cookies), które pozwalają Nam na rozpoznawanie urządzenia użytkownika, 
                      żeby odpowiednio wyświetlić stronę w zależności o urządzenia czy przeglądarki wykorzystywanych do jej przeglądania, tworzenia statystyk, 
                      które pomagają zrozumieć, w jaki sposób użytkownicy korzystają ze strony oraz w celu identyfikacji naruszeń i zapewnieniu bezpieczeństwa 
                      strony www, zarządzać sesjami logowania oraz określać stan ustawień Twojego profilu 2. Zakres danych przetwarzanych przez stronę www w zależności od celu, 
                      w jakim dane są przetwarzane dotyczą: a: formularza rejestracyjnego w zakresie: - Login oraz hasło; - Adresu e-mail b: zarządzania stroną www, w zakresie - 
                      Adresu IP; - Danych urządzenia oraz rodzaju wykorzystywanej przeglądarki; - Danych demograficznych. c: Dopasowania proponowanych treści do Twoich 
                      preferencji: - Wiek - Płeć - Zainteresowania d: Umożliwienia rozliczeń serwisu z użytkownikiem: - Imię i nazwisko - Adres zamieszkania - Numer konta bankowego 
                      lub adres konta PayPal Dane osobowe nie są publikowane w żadnej części serwisu i służą wyłącznie do dokonania rozliczeń. 3. Możemy również przetwarzać 
                      zanonimizowane dane eksploatacyjne związane z odwiedzaniem Naszej strony www (tzw. logi – adres IP, domena). Dane te służą Nam do generowania statystyk pomocnych w 
                      administrowaniu stroną. Dane te mają charakter zbiorczy i anonimowy, tj. nie zawierają cech identyfikujących osobę fizyczną – użytkownika strony, 
                      a Logi nie są ujawniane osobom trzecim z zastrzeżeniem możliwości udostępniania informacji dotyczących numeru IP dane użytkownika strony na żądanie uprawnionego 
                      do tego w oparciu o obowiązujące przepisy prawa, organów państwa w związku z prowadzonymi przez nie postępowaniami. 4. Automatyczne przetwarzanie danych osobowych 
                      Informacje, które gromadzimy w związku z korzystaniem z naszych usług/produktów online udostępnionych na stronie www mogą być przetwarzane w sposób zautomatyzowany 
                      (w tym w formie profilowania), jednakże nie będzie to wywoływać wobec osoby fizycznej żadnych skutków prawnych lub w podobny sposób istotnie wpływać na jej sytuację. 
                      Szczególną wagę przykładamy do kwestii profilowania i wskazujemy, że: - na potrzeby profilowania nie przetwarzamy żadnych danych sensytywnych, - 
                      na potrzeby profilowania przetwarzamy z reguły dane, które uprzednio podlegały pseudonimizacji lub takie dane, które zostały przez nas zagregowane - 
                      jeśli nie możemy osiągnąć celu inaczej, niż poprzez profilowanie danych osobowych nie zspeudonimizowanych lub niezagregowanych, wykorzystujemy do tego 
                      typowe dane: adres e-mail i IP lub Cookies - profilujemy w celu analizy lub prognozy osobistych preferencji oraz zainteresowań osób korzystających z Naszej 
                      strony lub produktów lub usług i dopasowywania treści znajdujących się w Naszej stronie, - profilujemy w celach marketingowych, tj. dopasowania oferty 
                      marketingowej do www. preferencji. 5. Odbiorcy danych Nie udostępniamy danych osobowych firmom, organizacjom ani osobom spoza WM Media sp. z o.o., 
                      jeśli nie zaistnieje jedna z następujących okoliczności: - Istnieją przyczyny prawne – w takim przypadku udostępniamy dane osobowe firmom, organizacjom i 
                      osobom trzecim jeśli w dobrej wierze uznamy, że udostępnienie, wykorzystanie, zachowanie lub ujawnienie danych jest uzasadnione w związku z: - 
                      dążeniem do spełniania wymogów obowiązującego prawa, przepisów, procesów prawnych lub prawomocnych żądań instytucji państwowych. - 
                      egzekwowaniem obowiązujących Warunków korzystania ze strony (regulamin strony www), w tym badaniem potencjalnych naruszeń; - 
                      monitorowania ruchu na stronie, czasu spędzania itp. - wykrywaniem oszustw i zapobieganiem im, a także rozwiązywaniem innych problemów dotyczących oszustw, 
                      bezpieczeństwa i kwestii technicznych; - ochroną praw własności lub bezpieczeństwa Administratora Danych, użytkowników strony www.watchmemore.com oraz 
                      pozostałych osób w sposób wymagany bądź dozwolony przez przepisy prawa. 6. Zmiana celu przetwarzania Nie przewidujemy, lecz nie możemy tego wykluczyć. 
                      Wiemy na pewno, że jeśli zmieni się cel przetwarzania Twoich danych to poinformujemy Cię o takiej sytuacji, abyś miał świadomość i mógł skorzystać z 
                      przysługujących Tobie praw.

                      <h4 className="terms-title">V. PODSTAWY PRAWNE PRZETWARZANIA DANYCH</h4>
                      Podstawą prawną przetwarzania danych osobowych jest przesłanka: - przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, 
                      lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy (art. 6 ust. 1 lit b) RODO). 
                      Podstawa ta odnosi się do danych zebranych poprzez formularz kontaktowy lub formularz zamówienia kontaktu, które zostały udostępnione na Naszej stronie www. 
                      W tym przypadku podanie danych osobowych jest niezbędne do realizacji na Twoje żądanie określonej czynności zgodnie z celem przetwarzania, np., 
                      jeśli nie podasz Nam adresu e-mail, nie będziemy mogli udzielić Tobie odpowiedzi na przysłane zapytanie zgodnie z treścią przesłanej wiadomości.
                      Zwracamy się z prośbą o niepodawanie za pośrednictwem formularza kontaktowego informacji ze szczególnych kategorii danych osobowych 
                      (takich jak informacje o rasie czy pochodzeniu etnicznym, poglądach politycznych, przekonaniach religijnych lub filozoficznych, przynależności do związków zawodowych, 
                      informacje dotyczące zdrowia fizycznego lub psychicznego, dane genetyczne, dane biometryczne, informacje o życiu seksualnym czy orientacji seksualnej oraz 
                      przeszłości kryminalnej). W przypadku podania takich informacji z jakiegoś powodu, oświadczamy że jako Administrator Danych w celu zapewnienia przejrzystości 
                      przetwarzania danych w określonym celu, usuniemy taką treść wiadomości, o czym poinformujemy Cię na skazany adres e-mail. - Twoja zgoda na przedstawienie usług 
                      i produktów oraz przekazania informacji handlowej usług i produktów za pomocą środków komunikacji elektronicznej, w szczególności poczty elektronicznej w stosunku 
                      do przesłanego zapytania (art. 6 ust. 1. lit. a) RODO); - Twojej zgody, na przetworzenie określonych kategorii danych zawsze w określonym celu, 
                      o którą prosimy abyś nam wyraził (art. 6 ust. 1 lit. a) RODO). - konieczność realizowania prawnie uzasadnionych celów Administratora Danych (art. 6 ust. 1 lit. f) RODO),
                       które wyrażone są poprzez: - zarządzanie stroną www - dostosowaniem jej zawartości do Twoich potrzeb i oczekiwań oraz zapewnienie bezpieczeństwa Twoich danych w 
                       cyberprzestrzeni.

                       <h4 className="terms-title">VI. CZAS PRZETWARZANIA</h4>
                       Naszym celem jest ochrona Ciebie przed negatywnymi konsekwencjami przetwarzania Twoich danych osobowych. W tym celu, staraliśmy się zoptymalizować czas 
                       przechowywania Twoich danych zebranych za pomocą strony www. Czas ten określiliśmy: a. w przypadku, gdy Administrator Danych przetwarza dane osobowe na podstawie zgody, 
                       okres przetwarzania trwa do momentu wycofania tej zgody przez użytkownika lub zakończenia celu w jakim zostały one zebrane; b. w przypadku, gdy 
                       Administrator Danych przetwarza dane osobowe na podstawie uzasadnionego interesu administratora danych, okres przetwarzania trwa do momentu ustania ww. interesu 
                       (np. okres przedawnienia roszczeń cywilnoprawnych) lub do momentu sprzeciwienia się osoby, której dane dotyczą, dalszemu takiemu przetwarzaniu – w sytuacjach, 
                       gdy sprzeciw taki zgodnie z przepisami prawa przysługuje; c. przypadku, gdy Administrator Danych przetwarza dane osobowe, ponieważ jest to konieczne z uwagi na 
                       obowiązujące przepisy prawa, okresy przetwarzania danych w tym celu określają te przepisy (jest to na przykład obowiązana zgodnie z przepisami prawa do 
                       przechowywania Danych osobowych Użytkownika przez dłuższy okres lub jeśli Dane osobowe Użytkownika są potrzebne do wniesienia zarzutów prawnych lub podjęcia obrony 
                       przed zarzutami prawnymi, firma zachowa Dane osobowe Użytkownika do zakończenia odpowiedniego okresu przechowywania lub do momentu rozstrzygnięcia przedmiotowych 
                       zarzutów; d. w przypadku braku konkretnych wymogów prawnych lub umownych, podstawowy okres przechowywania danych w przypadku zapisów i innej dokumentacji dowodowej 
                       sporządzonej w trakcie wykonywania umowy maksymalnie 10 lat.

                       <h4 className="terms-title">VII. COOKIES</h4>
                       Pliki Cookies (ciasteczka) są to niewielkie informacje tekstowe w postaci plików tekstowych, wysyłane przez serwer i zapisywane po stronie osoby 
                       odwiedzającej stronę www (np. na dysku twardym komputera, laptopa, czy też na karcie pamięci smartfona – w zależności, 
                       z jakiego urządzenia korzysta odwiedzający Naszą stronę www). Szczegółowe informacje dot. plików Cookies, 
                       a także okoliczności ich powstania można znaleźć m.in. <Link to={'/docs/cookies'}>tutaj</Link>

                       <h4 className="terms-title">VIII. TWOJE PRAWA</h4>
                       Warto Abyś wiedział, jakie Masz prawa w związku z przetwarzaniem Twoich danych osobowych: - W każdym dowolnym czasie masz prawo do wycofania udzielonej zgody, 
                       co spowoduje zaprzestanie przetwarzania danych osobowych, przetwarzanych na podstawie udzielonej zgody. 
                       Konsekencja wycofania zgody bedzie usunięcie Twojego konta ze strony watchmemore.com Cofnięcie zgody nie wpływa na zgodność przetwarzania danych osobowych, 
                       którego dokonaliśmy na podstawie udzielonej zgody przed jej cofnięciem. Prawo to można wykonywać poprzez wysłanie e-maila na adres: support@chillistream.com 
                       lub poprzez zlecenie usunięcia konta poprez link znajdujacy sie w zakładce 'Ustawienia' dostępnej dla zalogowanych użytkowników. - 
                       Masz prawo żądania usunięcia (prawo do bycia zapomnianym) dotyczących Ciebie danych osobowych z systemów informatycznych oraz z akt. 
                       Prawo to zostanie przez Administratora zrealizowane, o ile nie spoczywa na Administratorze prawny obowiązek zachowania, a tym samym przetwarzania danych, 
                       pomimo wniesienia prawa do bycia zapomnianym. Prawo to można wykonywać poprzez wysłanie e-maila na adres: support@chillistream.com. - 
                       Masz prawo dostępu do swoich danych (żądania wydania kopii danych). Prawo to można wykonywać poprzez wysłanie e-maila na adres: support@chillistream.comm.com. 
                       Masz prawo do sprostowania (aktualizacji danych). Informujemy, że zaktualizowanie danych, nie spowoduje usunięcia danych przed dokonaniem aktualizacji, 
                       co związane jest z obowiązkiem zachowania spójności danych, przetwarzanych przez Administratora w określonym celu. Prawo to można wykonywać poprzez wysłanie 
                       e-maila na adres: support@watchmemore.com. - Masz prawo do ograniczenia przetwarzania, w tym również prawo do wniesienia sprzeciwu wobec przetwarzania danych 
                       powołując się na określoną sytuację. Informujemy, że wskazane prawo zostanie przez Administratora zrealizowane wyłącznie w sytuacji, 
                       gdy wykonanie określonej czynności nie jest sprzeczne z obowiązującymi przepisami prawa, które obligują Administratora do przetwarzania danych. 
                       Prawo to można wykonywać poprzez wysłanie e-maila na adres: support@watchmemore.com. - Dodatkowo masz prawo do przeniesienia swoich danych z systemów 
                       informatycznych na rzecz wskazanego podmiotu oraz do wniesienia skargi do Urzędu Nadzorczego na działania Administratora Danych w przetwarzaniu danych osobowych.

                       <h4 className="terms-title">IX. BEZPIECZEŃSTWO</h4>
                       Dokładamy wszelkich starań, aby chronić użytkowników przed nieuprawnionym dostępem, 
                       nieautoryzowaną modyfikacją, ujawnieniem oraz zniszczeniem informacji znajdujących się w posiadaniu spółki WM Media sp. z o.o. 
                       W szczególności: - stosujemy szyfrowanie SSL; - kontrolujemy Nasze metody gromadzenia, przechowywania i przetwarzania informacji, w tym fizyczne środki bezpieczeństwa, 
                       aby chronić przed nieuprawnionym dostępem do systemu; - dostępu do danych osobowych udzielamy jedynie tym pracownikom, kontrahentom i przedstawicielom, 
                       którzy muszą mieć do nich dostęp, aby przetwarzać je na Nasze potrzeby. Ponadto na mocy umowy są oni zobowiązani do zachowania ścisłej poufności, 
                       a w przypadku niewypełnienia tych zobowiązań mogą ponieść konsekwencje, łącznie z zakończeniem współpracy.

                       <h4 className="terms-title">X. SKARGI, WĄTPLIWOŚCI, PYTANIA</h4>
                       Jakiekolwiek pytania, zastrzeżenia lub wątpliwości dotyczące treści niniejszej Polityki prywatności lub sposobu w jaki przetwarzamy dane osobowe, 
                       jak również skargi dotyczące tych kwestii (choć mamy nadzieję, że nie będzie konieczności wniesienia tego rodzaju skarg), 
                       prosimy kierować mailowo wraz ze szczegółowymi informacjami dotyczącymi skargi na adres: support@watchmemore.com. 
                       Wszelkie otrzymane skargi zostaną rozpatrzone i udzielimy na nie odpowiedzi.

                       <h4 className="terms-title">XI. POSTANOWIENIA KOŃCOWE</h4>
                       Niniejsza Polityka prywatności może czasami ulegać zmianom. Prawa użytkownika wynikające z niniejszej Polityki prywatności nie zostaną ograniczone bez wyraźnej zgody użytkownika. 
                       Wszelkie zmiany Polityki prywatności będą publikowane na tej stronie, a o istotnych zmianach będziemy informować w bardziej widoczny sposób (w przypadku niektórych usług łącznie z 
                       wysłaniem odpowiedniego powiadomienia e-mail). Zachowujemy również poprzednie wersje niniejszej Polityki prywatności w archiwum, aby umożliwić użytkownikom zapoznanie się z nimi. <br></br>
                       Ostatnia aktualizacja niniejszego dokumentu miała miejsce dnia 04/08/2023 r.
                </Col>
            </Row>
        </Container>
    );
}
export default PrivacyPolicyEn;