import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { updateProfile, } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BsCheckLg } from 'react-icons/bs'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsFillEyeSlashFill, BsFillEyeFill, BsArrowLeftShort } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { injectIntl, FormattedMessage } from 'react-intl'
import bank from '../../static/images/svg/bank.svg'
const IBAN_BREAKS = [2, 6, 10, 14, 18, 22, 26]

class PayoutSettingsView extends Component {


    componentDidMount = () => {
        let ibanCountryCode;
        let ibanNumber;
        let bic;
        if (this.props.myProfile.iban) {
            ibanCountryCode = this.props.myProfile.iban.slice(0, 2);
            ibanNumber = this.props.myProfile.iban.slice(2, this.props.myProfile.iban.length);
            ibanNumber = this.addSpacesToIban(ibanNumber)
            bic = this.props.myProfile.bic
        }
        if(!ibanCountryCode){
            ibanCountryCode='?'
        }
        this.setState({
            bankAccountName: this.props.myProfile.bankAccountName,
            bankAccountSurname: this.props.myProfile.bankAccountSurname,
            iban: this.props.myProfile.iban,
            ibanCountryCode: ibanCountryCode,
            ibanNumber: ibanNumber,
        }, () => {
          //  console.log(this.state)
        })
    }


    state = {
        bankAccountName: '',
        bankAccountSurname: '',
        iban: '',
        ibanCountryCode: '',
        ibanNumber: '',
        formErrors: [],
        bic: '',
    }
    addSpacesToIban = (iban) => {
        let sp = IBAN_BREAKS
        let M = iban.length, N = sp.length, l = 0, r = 0;
        let res = new Array(M + N).fill(' ');
        // Iterate over M+N length
        for (let i = 0; i < M + N; i++) {

            if (l < N && i == sp[l] + l)
                l++;
            else
                res[i] = iban[r++];
        }
        // Return the required string
        return res.join('');
    }
    saveBankForm = () => {
        let err = {}
        if (this.state.ibanCountryCode !== 'PL') {
            if (!this.state.bic) {
                err.bic = ['Pleas provide Your bank bic/swift code.']
            }
        }
     //   console.log('this.state.ibanCountryCode ', this.state.ibanCountryCode)
        if (this.state.ibanCountryCode === '?' || this.state.ibanCountryCode === undefined) {
            if (!this.state.bic) {
                err.ibanCountryCode = ['Pleas select country.']
            }
        }
        if (!this.state.ibanNumber) {
            err.iban = ['Pleas provide Your bank account number.']
        }
        if (!this.state.bankAccountName) {
            err.bankAccountName = ['This field is required.']
        }
        if (!this.state.bankAccountSurname) {
            err.bankAccountSurname = ['This field is required.']
        }
        if (Object.keys(err).length > 0) {
            this.setState({ formErrors: err })
            return
        }
        this.setState({ bankFormFetching: true, formErrors: [] })
        const fd = new FormData()
        fd.append('bankAccountName', this.state.bankAccountName)
        fd.append('bankAccountSurname', this.state.bankAccountSurname)
        let ibanNumber = this.state.ibanNumber
        ibanNumber = ibanNumber.replace(/\s/g, "")
        fd.append('ibanCountryCode', this.state.ibanCountryCode)
        fd.append('iban', this.state.ibanCountryCode + ibanNumber)
        
        if (this.state.ibanCountryCode === 'PL') {
            fd.append('bic', '')
        }
        else {
            fd.append('bic', this.state.bic)
        }
        this.props.updateProfile(fd, this.bankUpdated)
    }

    bankUpdated = (data) => {
        this.setState({ bankFormFetching: false })
        if (!data.error) {
            this.setState({ bankSavedSuccess: true }, () => {
                setTimeout(() => {
                    this.setState({ bankSavedSuccess: false })
                }, 1500)
            })
        }
        else {
            this.setState({ formErrors: data.data })
        }
    }
    ibanKeyUp = (e) => {
        let value = e.target.value
        if (e.key.toLowerCase() !== 'backspace' && e.key.toLowerCase() !== 'delete') {
            value = value.replace(/\s/g, "")
            value = this.addSpacesToIban(value)
        }
        this.setState({ ibanNumber: value })
    }
    handleFormchange = (e) => {
        let value = e.target.value
        this.setState({ [e.target.name]: value })
        if (e.target.name === 'ibanCountryCode') {
            this.setState({ formErrors: [] })
        }
    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }
        return (
            <div class="space-pt overflow-hidden h-70 myEarnings">
                <Container className='payoutSettings'>
                    <Row >
                        <Col md={12} className='pt-4' >
                            <div class="section-title">
                                <img src={bank} alt="#" height={45} /> <h2 className="title"><FormattedMessage id= "payoutSettings.payoutSettings" /></h2>
                            </div>
                        </Col>
                        {this.props.myProfile.isVerified === 0 &&
                            <Col xs={12} className=' mb-3 mt-3'>
                                <Link to={'/my/verification/'}><BsArrowLeftShort /><FormattedMessage id= "payoutSettings.goToVerification" /></Link>
                            </Col>
                        }
                        {this.props.myProfile.isVerified !== 0 &&
                            <Col xs={12} className=' mb-3 mt-3'>
                                <Link to={'/'}><BsArrowLeftShort /><FormattedMessage id= "common.backToUserPanel" /></Link>
                            </Col>
                        }
                    </Row>

                    <Row >
                        <Col md={12} className='pt-4' >
                            <div className="section-title">
                                <h4><FormattedMessage id= "payoutSettings.bankAccount" /></h4>
                            </div>
                        </Col>
                    </Row>
                    <Row >

                        <Col md={6} className="mb-3">
                            <label className="form-label"><FormattedMessage id= "payoutSettings.firstName" />: </label>
                            <Form.Control
                                id="firstNameInput"
                                type="text"
                                value={this.state.bankAccountName}
                                name={'bankAccountName'}
                                placeholder="First Name"
                                onChange={this.handleFormchange}
                                disabled={this.state.bankFormFetching}
                                isInvalid={this.state.formErrors.bankAccountName}
                                autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.formErrors.bankAccountName && this.state.formErrors.bankAccountName.map((item, index) => {
                                    return <div key={'bankAccountNamerrFbop' + index}>{item}</div>
                                })}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={6} className="mb-3">
                            <label className="form-label"><FormattedMessage id= "payoutSettings.lastName" />: </label>
                            <Form.Control
                                id="surnameInput"
                                type="text"
                                value={this.state.bankAccountSurname}
                                name={'bankAccountSurname'}
                                placeholder="Last Name"
                                onChange={this.handleFormchange}
                                disabled={this.state.bankFormFetching}
                                isInvalid={this.state.formErrors.bankAccountSurname}
                                autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.formErrors.bankAccountSurname && this.state.formErrors.bankAccountSurname.map((item, index) => {
                                    return <div key={'bbankAccountSurnamerrFbop' + index}>{item}</div>
                                })}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={6} className="mb-3">
                            <label className="form-label"><FormattedMessage id= "payoutSettings.country" />: </label>
                            <Form.Select size={'sm'}
                                name="ibanCountryCode"
                                onChange={this.handleFormchange}
                                aria-label="Default select example"
                                value={this.state.ibanCountryCode}
                                isInvalid={this.state.formErrors.ibanCountryCode}
                            >
                                <option value='?'>{ this.props.intl.formatMessage({ id: 'payoutSettings.country' })}...</option>
                                <option value="PL">Poland</option>
                                <option value="NL">Netherlands</option>
                                <option value="BE">Belgium</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {this.state.formErrors.ibanCountryCode && this.state.formErrors.ibanCountryCode.map((item, index) => {
                                    return <div key={'bicErrFbop' + index}>{item}</div>
                                })}
                            </Form.Control.Feedback>

                        </Col>
                        { this.state.ibanCountryCode !== 'PL' && this.state.ibanCountryCode !== '?' && this.state.ibanCountryCode!==undefined&&
                            <Col md={6} className="mb-3">
                                <label className="form-label">BIC/SWIFT: </label>
                                <Form.Control
                                    id="surnameInput"
                                    type="text"
                                    value={this.state.bic}
                                    name={'bic'}
                                    placeholder="BIC/SWIFT"
                                    onChange={this.handleFormchange}
                                    disabled={this.state.bankFormFetching}
                                    isInvalid={this.state.formErrors.bic}
                                    autoComplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.state.formErrors.bic && this.state.formErrors.bic.map((item, index) => {
                                        return <div key={'bicErrFbop' + index}>{item}</div>
                                    })}
                                </Form.Control.Feedback>
                            </Col>
                        }
                        <Col md={6} className="mb-3">
                            <label className="form-label">IBAN: </label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" >
                                    {this.state.ibanCountryCode && this.state.ibanCountryCode}
                                </InputGroup.Text>
                                <Form.Control
                                    className='ibanInput'
                                    id="surnameInput"
                                    type="text"
                                    value={this.state.ibanNumber}
                                    name={'ibanNumber'}
                                    placeholder="Bank Account Number"
                                    onChange={this.handleFormchange}
                                    onKeyUp={this.ibanKeyUp}
                                    disabled={this.state.bankFormFetching}
                                    isInvalid={this.state.formErrors.iban}
                                    autoComplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.state.formErrors.iban && this.state.formErrors.iban.map((item, index) => {
                                        return <div key={'ibanerrFbop' + index}>{item}</div>
                                    })}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>

                        <Col xs={12} className=' mb-3 mt-3'>
                            {!this.state.bankSavedSuccess &&
                                <Button variant={'primary'} disabled={this.state.bankFormFetching} onClick={() => { this.saveBankForm() }}>
                                    <FormattedMessage id= "payoutSettings.saveBankAccount" /> {this.state.bankFormFetching && <Spinner size={'sm'} />}
                                </Button>
                            }
                            {this.state.bankSavedSuccess &&
                                <Button variant={'primary'} >
                                    <FormattedMessage id= "common.saved" />! <BsCheckLg />
                                </Button>
                            }
                        </Col>

                    </Row>
                </Container>
            </div>

        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        updateProfile,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn,
        myProfile: state.profile.myProfile,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(PayoutSettingsView));
