import React from 'react';
import { Oval } from 'react-loader-spinner'

function OverlaySpinner(props) {
    let width=80

    return (
        <div className='fsAbsoluteOverlay'>
            <div className='absolute-center' >
                <Oval height = "80"
                    width = "80"
                    radius = "9"
                    color = '#f6be00'
                    secondaryColor='#f6be00'
                    />
            </div>
            
        </div>
    );
}

export default OverlaySpinner;