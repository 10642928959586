import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { getFavorites, getLastVisits, } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BsBroadcastPin } from 'react-icons/bs'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { ImSpinner2 } from 'react-icons/im'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/css/navigation'
//import 'swiper/modules/pagination/pagination.min.css'
import tvShow07 from '../../static/images/tvShow/tvShow07.jpg'
import innerBanner1 from '../../static/images/bg/innerBanner1.jpg'
import StreamerThumb from '../../components/common/streamerThumb'
import remoteControl from '../../static/images/svg/remoteControl.svg'
import profile from '../../static/images/svg/profile.svg'
import money from '../../static/images/svg/money.svg'
import verification from '../../static/images/svg/verification.svg'
import bank from '../../static/images/svg/bank.svg'
import broadcast from '../../static/images/svg/broadcast.svg'
import wallet from '../../static/images/svg/wallet.svg'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
class LandingMembers extends Component {

    constructor(props) {
        super(props)
        this.sliderContRef = React.createRef();
    }

    componentDidMount = () => {
        document.title = 'Chillistream - Members'
        window.addEventListener('resize', this.handleResize)
        setTimeout(() => {
            this.handleResize()
        }, 200)
        
        this.getFavorites()
        this.getLastVisits()
    }

    handleResize = () => {
        let width = window.innerWidth
        let slidesPerView = 2;
        if (width > 470 && width < 640) {
            slidesPerView = 3
        }
        if (width > 640 && width < 1250) {
            slidesPerView = 4
        }
        if (width > 1250 && width < 1750) {
            slidesPerView = 5
        }
        if (width > 1750 && width < 2200) {
            slidesPerView = 6
        }
        if (width > 2200 && width < 2500) {
            slidesPerView = 7
        }
        if (width > 2500 && width < 2700) {
            slidesPerView = 8
        }
        if (width > 2700 && width < 3200) {
            slidesPerView = 9
        }
        if (width > 3200 && width < 3600) {
            slidesPerView = 10
        }
        if (width > 3600) {
            slidesPerView = 11
        }
        let slideWidth = this.sliderContRef.current.clientWidth / slidesPerView - this.state.slidespaceBetween
        let slideHeight = slideWidth * 1.05
        this.setState({ slideWidth: slideWidth, slideHeight: slideHeight })
       // console.log('slidesPerView, width', slideWidth, this.state.slidesPerView)
        this.setState({ slidesPerView: slidesPerView })
    }
    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize)
    }
    state = {
        slidesPerView: 2,
        slidespaceBetween: 15,
        slideWidth: 0,
        slideHeight: 0,

    }

    getFavorites = () => {
        this.setState({ favoritesFetching: true })
        this.props.getFavorites(this.favoritesFetched)
    }
    favoritesFetched = (data) => {
        this.setState({ favoritesFetching: false })
        if (!data.error) {
            this.setState({ favoritesData: data })
        }
    }

    getLastVisits = () => {
        this.setState({ lastVisitsFetching: true })
        this.props.getLastVisits(this.lastVisitsFetched)

    }

    lastVisitsFetched = (data) => {
        this.setState({ lastVisitsFetching: false })
        if (!data.error) {
            this.setState({ lastVisitsData: data })
        }
    }

    renderStreamThumbItem = () => {
        //nie uzywany
        return (
            <SwiperSlide>
                <div className="item">
                    <div className="movies-categories">
                        <div className="movies-img">
                            <img className="img-fluid" src={tvShow07} alt="#" />
                            <div className="info-top">
                                <a className="tag" href="#">Crime</a>
                                <div className="ms-auto">
                                    <a href="javascript:void(0)" className="like"></a>
                                    <a className="views" href="#"><i className="far fa-eye"></i> 14M</a>
                                </div>
                            </div>
                            <div className="movies-info">
                                <div className="content">
                                    <a className="time" href="#"><i className="far fa-clock me-2"></i>0hr : 36mins</a>
                                    <div className="info-content">
                                        <div className="movies-title">
                                            <a className="play-btn popup-youtube" href="https://www.youtube.com/watch?v=n_Cn8eFo7u8"><i className="fa-solid fa-play"></i></a>
                                            <h5><a className="title mt-0" href="tv-show-single.html">Always has been.</a></h5>
                                        </div>
                                        <div className="share-info">
                                            <a href="javascript:void(0)" className="add-icon"></a>
                                            <div className="share-box">
                                                <a href="#"> <i className="fas fa-share-alt"></i> </a>
                                                <ul className="list-unstyled share-box-social">
                                                    <li> <a href="#"><i className="fab fa-facebook-f"></i></a> </li>
                                                    <li> <a href="#"><i className="fab fa-twitter"></i></a> </li>
                                                    <li> <a href="#"><i className="fab fa-linkedin"></i></a> </li>
                                                    <li> <a href="#"><i className="fab fa-instagram"></i></a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        )
    }
    renderStreamThumbSpinner = () => {
        return (
            <SwiperSlide>
                <div style={{ width: this.state.slideWidth, height: this.state.slideHeight, position: 'relative' }}>
                    <div className='absolute-center '>
                        <ImSpinner2 className='rotate streamThumbSpinner' />
                    </div>

                </div>
            </SwiperSlide>
        )

    }

    renderStreamerThumbItem = (item) => {
    //    console.log(item)
        return <StreamerThumb item={item} slideWidth={this.state.slideWidth} slideHeight={this.state.slideHeight} />
    }


    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }
        return (
            <>
                <div className="inner-banner bg-holder bg-overlay-secondary-3" style={{ backgroundImage: "url(" + innerBanner1 + ")" }}>
                    <Container className="position-relative">
                        <Row >
                            <Col md={12}>
                                <div className="header-inner-title">
                                    <h2 className="title"></h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="space-pt overflow-hidden ">
                    <Container fluid >
                        <Row >
                            <Col md={12} >
                                <div className="section-title mb-0">
                                    <h2 className="title">Account details</h2>
                                </div>
                                <Row className='g-0'>
                                    <Col xs={12} className='homeSwiperMenuWrapper'>
                                        <Swiper
                                            spaceBetween={this.state.slidespaceBetween}
                                            slidesPerView={this.state.slidesPerView+1}
                                          //  onSlideChange={() => console.log('slide change')}
                                          //  onSwiper={(swiper) => console.log(swiper)}
                                            navigation
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                        >
                                            <SwiperSlide>
                                                <div className="homeMenuSwiperItem ">
                                                    <Link to={'/my/profile/'}>
                                                        <div className="feature-info ">
                                                            <div className="constant order-md-1 order-2 text-center">
                                                                <img className="" src={profile} alt="#" />
                                                                <h4 className="title"><FormattedMessage id="landing2.profie" /></h4>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="homeMenuSwiperItem ">
                                                    <Link to={'/my/stream/'}>
                                                        <div className="feature-info ">
                                                            <div className="constant order-md-1 order-2 text-center">
                                                                <img className="" src={broadcast} alt="#" />
                                                                <h4 className="title"><FormattedMessage id="landing2.streaming" /></h4>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="homeMenuSwiperItem ">
                                                    <Link to={'/my/wallet/'}>
                                                        <div className="feature-info ">
                                                            <div className="constant order-md-1 order-2 text-center">
                                                                <img className="img-fluid" src={wallet} alt="#" />
                                                                <h4 className="title"><FormattedMessage id="landing2.wallet" /></h4>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="homeMenuSwiperItem ">
                                                    <Link to={'/my/earnings/'}>
                                                        <div className="feature-info ">
                                                            <div className="constant order-md-1 order-2 text-center">
                                                                <img className="img-fluid" src={money} alt="#" />
                                                                <h4 className="title"><FormattedMessage id="landing2.earnings" /></h4>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                          
                                            <SwiperSlide>
                                                <div className="homeMenuSwiperItem ">
                                                    <Link to={'/my/payoutsettings/'}>
                                                        <div className="feature-info ">
                                                            <div className="constant order-md-1 order-2 text-center">
                                                                <img className="img-fluid" src={bank} alt="#" />
                                                                <h4 className="title"><FormattedMessage id="landing2.payoutSettings" /></h4>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="homeMenuSwiperItem ">
                                                    <Link to={'/my/verification/'}>
                                                        <div className="feature-info ">
                                                            <div className="constant order-md-1 order-2 text-center">
                                                                <img className="img-fluid" src={verification} alt="#" />
                                                                <h4 className="title"><FormattedMessage id="landing2.verifyAccount" /></h4>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} >

                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="space-pt overflow-hidden ">
                    <Container fluid >
                        <Row >
                            <Col md={12} >
                                <div className="section-title">
                                    <h2 className="title"><FormattedMessage id="landing2.recentlyViewed" /></h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='bg-dark '>
                                <div className="categories-slider " ref={this.sliderContRef}>
                                    {this.state.lastVisitsData && this.state.lastVisitsData.length === 0 &&
                                        <Row>
                                            <Col>
                                                <h4>
                                                <FormattedMessage id="landing2.recentlyViewedEmptyInfo" />
                                                </h4>
                                            </Col>
                                        </Row>
                                    }
                                    <Swiper
                                        spaceBetween={this.state.slidespaceBetween}
                                        slidesPerView={this.state.slidesPerView}
                                        //onSlideChange={() => console.log('slide change')}
                                     //   onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        {this.state.lastVisitsData && this.state.lastVisitsData.map((item, index) => {
                                            return <SwiperSlide key={'lsts'+index}>{this.renderStreamerThumbItem(item)}</SwiperSlide>
                                        })}
                                        {this.state.favoritesFetching &&
                                            <SwiperSlide >
                                                {this.renderStreamThumbSpinner()}
                                            </SwiperSlide>
                                        }

                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="space-sm-ptb overflow-hidden">
                    <Container fluid >
                        <Row >
                            <Col md={12} >
                                <div className="section-title">
                                    <h2 className="title"><FormattedMessage id="landing2.favotiteStreamers" /></h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} >
                                <div className="categories-slider">
                                    {this.state.favoritesData && this.state.favoritesData.length === 0 &&
                                        <Row>
                                            <Col>
                                                <h4>
                                                <FormattedMessage id="landing2.favotiteStreamersEmptyInfo" />
                                                </h4>
                                            </Col>
                                        </Row>
                                    }
                                    <Swiper
                                        spaceBetween={this.state.slidespaceBetween}
                                        slidesPerView={this.state.slidesPerView}
                                       // onSlideChange={() => console.log('slide change')}
                                      //  onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        {this.state.favoritesData && this.state.favoritesData.map((item, index) => {
                                            return <SwiperSlide key={'lsts3'+index}>{this.renderStreamerThumbItem(item)}</SwiperSlide>
                                        })}
                                        {this.state.lastVisitsFetching &&
                                            <SwiperSlide >
                                                {this.renderStreamThumbSpinner()}
                                            </SwiperSlide>
                                        }
                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getFavorites,
        getLastVisits,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(LandingMembers));
