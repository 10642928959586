import { Host } from '../actions/authActions'
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'

const authtoken = localStorage.getItem("token")
const mainWsMiddleware = createListenerMiddleware()
let socket = null;
let reconnectTimeoutId=null
let connectionAttempts=1

const reconnectTimer=(listenerApi)=>{
    if (!navigator.onLine) {
        return
    }
    if(reconnectTimeoutId){
        clearTimeout(reconnectTimeoutId)
    }
    let timeout=3000
    if(connectionAttempts>=5){
        connectionAttempts=1
        timeout=11000
    }
    else{
        connectionAttempts=connectionAttempts+1
        timeout=3000
    }
    let state = listenerApi.getState()
    if (state.auth.loggedIn) {
        reconnectTimeoutId=setTimeout(() => {
            listenerApi.dispatch({type:'MAIN_WS_CONNECT'});
        }, timeout)
    }
}

const onOpen = listenerApi => (event) => {
    connectionAttempts=1
    if(reconnectTimeoutId){
        clearTimeout(reconnectTimeoutId)
        reconnectTimeoutId=null
    }
    //console.log('ws middleware websocket open', event.target.url);
};

const onClose = listenerApi => (e) => {
  //  console.log('mainWs onClose', e)
    reconnectTimer(listenerApi)
};

const onError = listenerApi => (e) => {
  //  console.log('mainWs onError', e)
    reconnectTimer(listenerApi)
  };

const onMessage = listenerApi => (event) => {
    const payload = JSON.parse(event.data);
   // console.log('main ws middleware receiving server message', payload);
    switch (payload.command){
        case 'updateChatList':

            break;
    }
}



mainWsMiddleware.startListening({
    actionCreator: 'mainWsMiddleware',
    effect: async (action, listenerApi) => {
      //  console.log('jest mainWsMiddleware', action, listenerApi)
        let state = listenerApi.getState()
        switch (action.type) {
            case 'MAIN_WS_CONNECT':
                if (socket) {
                    socket.close();
                }
                setTimeout(() => {
                    socket = null
                    let myId = null;
                    if (state.profile.myProfile && state.auth.loggedIn) {
                       // myId = state.profile.myProfile.userId
                        let wsProtocol = 'wss'
                        if (window.location.protocol === 'http:') {
                            wsProtocol = 'ws'
                        }
                        let host = Host + '/ws/main/?token=' + state.auth.token
                        socket = new WebSocket(host);
                        socket.onopen = onOpen(listenerApi);
                        socket.onclose = onClose(listenerApi);
                        socket.onmessage = onMessage(listenerApi);
                        socket.onerror = onError(listenerApi);
                    }
                }, 300)
                break;
                
                case 'CONNECTION_STATE_CHANGE':
                    if(action.data==='offline'){
                        if (socket) {
                            socket.close();
                            socket = null
                        }
                    }
                    if(action.data==='online'){
                        clearTimeout(reconnectTimeoutId)
                        listenerApi.dispatch({type:'MAIN_WS_CONNECT'})
                    }
                    break;
                case 'MAIN_WS_UPDATE_BROADCAST_STATUS':
                    //przeniesione do channelWs
                   // console.log('jest MAIN_WS_UPDATE_BROADCAST_STATUS', action.data)
                    //WYSYLAMY PRZEZ WS I W CONSUMERZE PODBIJAMY CACHE ZE STATUSEM NADAWANIA
                ////    let status=action.data
              //      let payload={
                //                'command':'MAIN_WS_UPDATE_BROADCAST_STATUS',
               //                 'data':{
               ///                     'status':status //online, offline
               //                 }
               //     }
                  //  socket.send(JSON.stringify(payload))
                break;
        }
    }

})

export default mainWsMiddleware