import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { getWallet, getCheckoutData, testCheckout } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsArrowLeftShort, } from 'react-icons/bs'
import { GiTwoCoins } from 'react-icons/gi'
import Spinner from 'react-bootstrap/Spinner';
import { injectIntl, FormattedMessage } from 'react-intl'
import money from '../../static/images/svg/money.svg'
import blikBtn from '../../static/blikBtn.png'
import hotpayWhite from '../../static/hotpayWhite.png'

class WalletView extends Component {


    componentDidMount = () => {
        document.title = 'Chillistream - Wallet'
        this.setState({ walletFetching: true })
        this.props.getWallet(this.walletFetched)
    }


    state = {
      //  transactionCurrency: this.props.myProfile.transactionCurrency
        transactionCurrency: 'pln'
    }
    walletFetched = (data) => {
        this.setState({ walletFetching: false })

        //  console.log('walletFetched', data)
        this.setState({ balanceData: data })
    }

    renderTokenPacketItem = (item, index) => {
        return (
            <Col xs={6} md={3} lg={4} xl={3} xxl={2} className='px-2 pb-2' key={'tokPack' + index}>
                <div className='walletItem feature-info py-4 px-3' onClick={() => this.showCheckoutModal(item)}>
                    <Row>
                        <Col xs={12} className='text-center '>
                            <h4 className='text-primary'>{item.tokensAmount} <FormattedMessage id="wallet.tokens" /> </h4>
                        </Col>
                        <Col xs={12} className='text-center'>
                            {this.state.transactionCurrency === 'eur' &&
                                <h3>{item.price.eur} &euro;</h3>
                            }
                            {this.state.transactionCurrency === 'pln' &&
                                <h3>{item.price.pln} PLN</h3>
                            }
                        </Col>
                    </Row>
                </div>
            </Col>
        )
    }
    showCheckoutModal = (item) => {
        this.setState({ showCheckoutModal: true, chekoutFetching: true, checkoutItem: item })
        this.props.getCheckoutData(item.id, this.checkoutFetched)
    }
    checkoutFetched = (data) => {
        this.setState({
            chekoutFetching: false,
            HPLink: data.HPLink,
            packetData: data.packetData,
        })
        //    console.log('checkoutFetched', data)
    }

    testCheckout = () => {
        this.setState({ testCheckoutFethcing: true })
        const fd = new FormData()
        fd.append('packetId', this.state.checkoutItem.id)
        this.props.testCheckout(fd, this.testCheckoutFetched)
    }
    testCheckoutFetched = (data) => {
        this.setState({ testCheckoutFethcing: false })
        this.setState({ balanceData: data }, () => {
            this.checkoutModalOnHide()
        })

    }

    checkoutModalOnHide = () => {
        //czyscimy caly checkout
        this.setState({
            showCheckoutModal: false,
            checkoutItem: undefined,
            testCheckoutFethcing: false,
            HPLink: undefined,
            packetData: undefined,
        })
    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }
        return (
            <div class="space-pt overflow-hidden h-70 myEarnings">
                <Container  >
                    <Row >
                        <Col md={12} className='pt-4' >
                            <div className="section-title">
                                <img src={money} alt="#" height={45} /> <h2 className="title"><FormattedMessage id="wallet.myWallet" /></h2>
                            </div>
                        </Col>
                        <Col xs={12} className=' mb-4 '>
                            <Link to={'/'}><BsArrowLeftShort /><FormattedMessage id="common.backToUserPanel" /></Link>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12} className='pt-0' >
                            <h2 className="title"><FormattedMessage id="wallet.balance" />: {this.state.walletFetching && <Spinner size={'sm'} />} {this.state.balanceData && this.state.balanceData.tokens} <GiTwoCoins className='text-primary' /></h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='pt-4' >
                            <div className="section-title">
                                <h2 className="title"><FormattedMessage id="wallet.buyTokens" />:</h2>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        {/** 
                        <Col xs={'auto'} className='mb-4' >
                            <button className={this.state.transactionCurrency === 'eur' ? 'btn btn-primary statc' : 'btn btn-secondary '} onClick={() => this.setState({ transactionCurrency: 'eur' })}>EUR</button>
                        </Col>
                        */}
                        <Col xs={'auto'} className='mb-4' >
                            <button className={this.state.transactionCurrency === 'pln' ? 'btn btn-primary statc' : 'btn btn-secondary'} onClick={() => this.setState({ transactionCurrency: 'pln' })}>PLN</button>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} className='px-3'>
                            <Row className='g-0'>
                                {this.state.balanceData && this.state.balanceData.packets.map((item, index) => {
                                    return this.renderTokenPacketItem(item, index)
                                })}

                            </Row>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    centered
                    backdrop={this.state.testCheckoutFethcing ? 'static' : undefined}
                    show={this.state.showCheckoutModal}
                    //  onShow={}
                    onHide={() => {
                        this.checkoutModalOnHide()
                    }
                    }
                >
                    <Modal.Body>
                        {this.state.checkoutItem &&
                            <Row>
                                <Col xs={12} className='text-center'>
                                    <div className='section-title'>
                                        <h4>Checkout</h4>
                                    </div>
                                </Col>
                                {this.state.chekoutFetching &&
                                    <Col xs={12} className='text-center'>
                                        <Spinner size={'lg'} />
                                    </Col>
                                }
                                {!this.state.chekoutFetching && this.state.packetData &&
                                    <>
                                        <Col xs={12} className='text-center'>
                                            {this.state.transactionCurrency === 'eur' &&
                                                <h4><FormattedMessage id="wallet.buy" /> {this.state.packetData.tokensAmount} <FormattedMessage id="wallet.tokensFor" /> {this.state.packetData.price.eur} &euro;</h4>
                                            }
                                            {this.state.transactionCurrency === 'pln' &&
                                                <h4><FormattedMessage id="wallet.buy" /> {this.state.packetData.tokensAmount} <FormattedMessage id="wallet.tokensFor" /> {this.state.packetData.price.pln} PLN</h4>
                                            }
                                        </Col>
                                        <Col xs={12} className='mt-4 text-center mx-auto p-1 pt-3' style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}>
                                        <a href={this.state.HPLink} target='_blank' >
                                            ZAPŁAĆ BLIKIEM <br></br>
                                                <Image src={blikBtn} height={80} />
                                            </a>
                                        </Col>
                                        <Col xs={12} className='mt-4 text-center mx-auto p-3 pb-4' style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}>
                                        <a href={this.state.HPLink} target='_blank' >
                                            MIKROPŁATNOŚCI <br></br>
                                           
                                                <Image src={hotpayWhite} height={50} />
                                            </a>
                                        </Col>
                                
                                    </>
                                }

                                {/**                           
                                <Col xs={12} className='mt-3'>
                                    <button className='btn btn-primary' onClick={() => { this.testCheckout() }}><FormattedMessage id="wallet.tokensFor" /> {this.state.testCheckoutFethcing && <Spinner size={'sm'} />}</button>
                                </Col>
                            */  }
                            </Row>
                        }
                    </Modal.Body>
                </Modal>
            </div>

        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getWallet,
        getCheckoutData,
        testCheckout,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn,
        myProfile: state.profile.myProfile,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(WalletView));
