import { axiosClient, ApiUrl, HostUrl, upssssText } from './authActions'


export function createBroadcast(camerasTracksIds, streamMode, callback) {
 // console.log('fetchBroadcastData', camerasTracksIds)
  let path = ApiUrl + "broadcast/tokens/"
  let fd = new FormData()
  fd.append('camerasTracksIds', JSON.stringify(camerasTracksIds))
  fd.append('streamMode', streamMode)
  return function (dispatch) {
    axiosClient.post(path, fd)
      .then((response) => {
    //    console.log('fetchBroadcastData', response.data)
        callback(response.data)
      })
      .catch((error) => {
        // handle error
    //    console.log('fetchBroadcastData error', error)
    ///    console.log('fetchBroadcastData error.response', error.response)
        if (error.response) {
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            callback(error.response.data)

          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'MY PROFILE_FETCHING', data: false })
      });
  }
}

export function updateBroadcastToken(broadcastId, camerasTracksIds, streamMode, callback) {
 // console.log('updateBroadcastToken', broadcastId)
  let path = ApiUrl + "broadcast/tokens/" + broadcastId + "/"
  let fd = new FormData()
  fd.append('camerasTracksIds', JSON.stringify(camerasTracksIds))
  fd.append('streamMode', streamMode)
  
  return function (dispatch) {
    axiosClient.patch(path,fd)
      .then((response) => {
      //  console.log('updateBroadcastToken', response.data)
        callback(response.data)
      })
      .catch((error) => {
        // handle error
      //  console.log('fetchBroadcastToken error', error)
  //     console.log('fetchBroadcastToken error.response', error.response)
        if (error.response) {
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            callback(error.response.data)

          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
        dispatch({ type: 'MY PROFILE_FETCHING', data: false })
      });
  }
}


export function updateBroadcast(broadcastId, data, callback) {
  // console.log('updateBroadcastToken', broadcastId)
   let path = ApiUrl + "broadcast/" + broadcastId + "/"
   let fd = new FormData()
   fd.append('broadcastData', JSON.stringify(data))
   return function (dispatch) {
     axiosClient.patch(path,fd)
       .then((response) => {
       //  console.log('updateBroadcastToken', response.data)
         callback(response.data)
       })
       .catch((error) => {
         // handle error
       //  console.log('fetchBroadcastToken error', error)
   //     console.log('fetchBroadcastToken error.response', error.response)
         if (error.response) {
           if (error.response.status === 400) {
             //   console.log(error.response.data)
             callback(error.response.data)
 
           }
           if (error.response.status === 404) {
           }
           if (error.response.status === 403) {
           }
         }
       })
       .then(() => {
         dispatch({ type: 'MY PROFILE_FETCHING', data: false })
       });
   }
 }
export function fetchBroadcastViewersList(callback) {
 // console.log('fetchBroadcastViewersList')
  let path = ApiUrl + "broadcast/viewers/"
  return function (dispatch) {
    axiosClient.get(path)
      .then((response) => {
      //  console.log('fetchBroadcastViewersList', response.data)
        callback(response.data)
      })
      .catch((error) => {
        // handle error
      //  console.log('fetchBroadcastData error', error)
       // console.log('fetchBroadcastData error.response', error.response)
        if (error.response) {
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            callback(error.response.data)

          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
       // dispatch({ type: 'MY PROFILE_FETCHING', data: false })
      });
  }
}
export function fetchCurrentStreamIncome(callback) {
 // console.log('fetchCurrentStreamIncome')
  let path = ApiUrl + "my/currentstreamincome/"
  return function (dispatch) {
    axiosClient.get(path)
      .then((response) => {
       // console.log('fetchCurrentStreamIncome', response.data)
        callback(response.data)
      })
      .catch((error) => {
        // handle error
      //  console.log('fetchCurrentStreamIncome error', error)
     //   console.log('fetchCurrentStreamIncome error.response', error.response)
        if (error.response) {
          if (error.response.status === 400) {
            //   console.log(error.response.data)
            callback(error.response.data)

          }
          if (error.response.status === 404) {
          }
          if (error.response.status === 403) {
          }
        }
      })
      .then(() => {
       // dispatch({ type: 'MY PROFILE_FETCHING', data: false })
      });
  }
}

export function checkCanSendNotifications(callback) {
  // console.log('fetchCurrentStreamIncome')
   let path = ApiUrl + "notifymyusers/"
   return function (dispatch) {
     axiosClient.get(path)
       .then((response) => {
         callback(response.data)
       })
       .catch((error) => {
         // handle error
       //  console.log('fetchCurrentStreamIncome error', error)
      //   console.log('fetchCurrentStreamIncome error.response', error.response)
         if (error.response) {
           if (error.response.status === 400) {
             //   console.log(error.response.data)
             callback(error.response.data)
 
           }
           if (error.response.status === 404) {
           }
           if (error.response.status === 403) {
           }
         }
       })
       .then(() => {
        // dispatch({ type: 'MY PROFILE_FETCHING', data: false })
       });
   }
 }
 
 export function SendFansNotifications(callback) {
  // console.log('fetchCurrentStreamIncome')
   let path = ApiUrl + "notifymyusers/"
   return function (dispatch) {
     axiosClient.post(path)
       .then((response) => {
         callback(response.data)
       })
       .catch((error) => {
         // handle error
       //  console.log('fetchCurrentStreamIncome error', error)
      //   console.log('fetchCurrentStreamIncome error.response', error.response)
         if (error.response) {
           if (error.response.status === 400) {
             //   console.log(error.response.data)
             callback(error.response.data)
 
           }
           if (error.response.status === 404) {
           }
           if (error.response.status === 403) {
           }
         }
       })
       .then(() => {
        // dispatch({ type: 'MY PROFILE_FETCHING', data: false })
       });
   }
 }

 export function saveBroadcastGoal(fd, callback) {
  // console.log('saveBroadcastGoal')
   let path = ApiUrl + "broadcast/goal/"
   return function (dispatch) {
     axiosClient.post(path, fd)
       .then((response) => {
         callback(response.data)
       })
       .catch((error) => {
         // handle error
       //  console.log('fetchCurrentStreamIncome error', error)
       //  console.log('saveBroadcastGoal error.response', error.response)
         if (error.response) {
           if (error.response.status === 400) {
                console.log(error.response.data)
             callback({error:true, errors:error.response.data})
 
           }
           if (error.response.status === 404) {
           }
           if (error.response.status === 403) {
           }
         }
       })
       .then(() => {
        // dispatch({ type: 'MY PROFILE_FETCHING', data: false })
       });
   }
 }

 export function finishBroadcastGoal(callback) {
  // console.log('saveBroadcastGoal')
   let path = ApiUrl + "broadcast/goal/"
   return function (dispatch) {
     axiosClient.delete(path)
       .then((response) => {
     //   console.log('finishBroadcastGoal', response.data)
         callback(response.data)
       })
       .catch((error) => {
         // handle error
       //  console.log('fetchCurrentStreamIncome error', error)
         console.log('finishBroadcastGoal error.response', error.response)
         if (error.response) {
           if (error.response.status === 400) {
         //       console.log(error.response.data)
             callback({error:true, errors:error.response.data})
 
           }
           if (error.response.status === 404) {
           }
           if (error.response.status === 403) {
           }
         }
       })
       .then(() => {
        // dispatch({ type: 'MY PROFILE_FETCHING', data: false })
       });
   }
 }