import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { updateProfile, logout, checkDisplayName } from "../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { ThreeDots } from 'react-loader-spinner'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { injectIntl, FormattedMessage } from 'react-intl'

class OnboardModal extends Component {


  componentDidMount = () => {
    const date = new Date();
    let currentYear = date.getFullYear()
    currentYear = currentYear - 18
    let years = []
    for (let i = currentYear; i > currentYear - 100; i--) {

      years.push(i)
    }
    this.setState({ displayName: this.props.myProfile.displayName, gender: this.props.myProfile.gender, years: years })
  }


  state = {
    displayName: '',
    gender: 0,
    birthYear: 0,
    errors: [],
    step: 1,
    years: null,
    displayNameCheckFetching: false,
    displayNameAvailable: true
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDisplayNameChange = (e) => {
    clearTimeout(this.state.displayNameTimoutId)
    this.setState({ displayNameCheckFetching: false, displayNameAvailable: undefined })
    this.setState({ displayName: e.target.value },
      () => {
        if (this.state.displayName.length < 3) {
          return
        }
        if (this.state.displayName === this.props.myProfile.displayName) {
          this.setState({ displayNameAvailable: true })
          return
        }
        const timoutId = setTimeout(() => {
          this.setState({ displayNameCheckFetching: true, })
          this.props.checkDisplayName(this.state.displayName, this.displayNameChecked)
        }, 500)
        this.setState({ displayNameTimoutId: timoutId })
      }
    )
  }

  displayNameChecked = (data) => {
    this.setState({ displayNameCheckFetching: false, displayNameAvailable: data.available })
  }

  handleSave = () => {
    this.setState({ errors: [], fetching: true })
    const fd = new FormData()
    fd.append('displayName', this.state.displayName)
    fd.append('gender', this.state.gender)
    fd.append('birthYear', this.state.birthYear)
    fd.append('onboarded', true)
    this.props.updateProfile(fd, this.fetched)

  }

  fetched = (data) => {
   // console.log('fetched', data)
    this.setState({ fetching: false })
    if (data.error) {
      this.setState({ errors: data.data })
    }

  }

  render() {
    if (!this.props.loggedIn) {
      return <Redirect to={'/'} />
    }
    return (
      <Modal
        centered
        show={true}
        //onHide={this.handleDiplayNameModalClose}
        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>
          <Container>
            <Row >
              <Col xs={12} className='pt-2 pb-4 mb-4 mx-auto mb-3 '>
                {this.state.step === 1 &&
                  <Row>
                    <Col xs={12} className='text-center'>
                      <div className='section-title'>
                        <h4><FormattedMessage id={"onboarding.welcome"} /></h4>
                      </div>
                    </Col>
                    <Col xs={12} className='pt-2'>
                      <p><FormattedMessage id={"onboarding.chooseYourNickname"} /></p>
                      <p><FormattedMessage id={"onboarding.itWillBeVisible"} /></p>
                    </Col>
                    <Col xs={12} className='pt-2'>
                      <FormGroup
                      //validationState={this.getValidationState()}
                      >
                        <FloatingLabel
                          // controlId="emailInput"
                          label="twój pseudonim"
                          className="mb-3"
                        >
                          <Form.Control
                            //  id="emailInput"
                            type="displayName"
                            value={this.state.displayName}
                            placeholder="displayName"
                            name={'displayName'}
                            onChange={this.handleDisplayNameChange}
                            isInvalid={this.state.errors.displayName}
                            disabled={this.state.fetching}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.errors.displayName && this.state.errors.displayName.map((item, index) => {
                              return <div key={'emailErr' + index}>{item}</div>
                            })}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        {this.state.displayNameCheckFetching &&
                          <ThreeDots
                            height="25"
                            width="50"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        }
                        {this.state.displayNameAvailable !== undefined && this.state.displayNameAvailable === true &&
                          <small className='text-primary'>
                            <FormattedMessage id={"onboarding.nicknameAvailable"} />
                          </small>
                        }
                        {this.state.displayNameAvailable !== undefined && this.state.displayNameAvailable === false &&
                          <small className='text-danger'>
                            <FormattedMessage id={"onboarding.nicknameUnavailable"} />
                          </small>
                        }
                      </FormGroup>
                    </Col>
                    <Col xs={12} className=' text-center'>
                      {!this.state.fetching &&
                        <Button onClick={() => { this.setState({ step: 2 }) }}
                          disabled={!this.state.displayNameAvailable}
                        >
                           <FormattedMessage id={"onboarding.next"} />
                        </Button>
                      }
                    </Col>
                  </Row>
                }
                {this.state.step === 2 &&
                  <Row>
                    <Col xs={12} className=' h3'>
                      <small>
                      <FormattedMessage id={"onboarding.lastStep"} />
                      </small>
                    </Col>
                    <Col xs={12} className='pt-2'>

                      <p>
                      <FormattedMessage id={"onboarding.itWillHelp"} />
                      
                      </p>
                    </Col>
                    <Col xs={12} className='pt-2 text-center'>
                      <Form.Select
                        name='gender'
                        onChange={this.handleChange}
                        isInvalid={this.state.errors.gender}
                      >
                        <option value={0}  > {this.props.intl.formatMessage({ id: "onboarding.chooseGender" })}...</option>
                        <option value={1}  > {this.props.intl.formatMessage({ id: "onboarding.female" })}</option>
                        <option value={2}  > {this.props.intl.formatMessage({ id: "onboarding.male"})}</option>
                        <option value={3}  > {this.props.intl.formatMessage({ id: "onboarding.other"})}</option>
                        <option value={4}  > {this.props.intl.formatMessage({ id: "onboarding.prefferNotSay"})}</option>
                      </Form.Select >
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.gender && this.state.errors.gender.map((item, index) => {
                          return <div key={'genderErrOnb' + index}>{item}</div>
                        })}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} className='pt-4 text-left'>
                      <Form.Select
                        name='birthYear'
                        onChange={this.handleChange}
                        isInvalid={this.state.errors.birthYear}
                      >
                        <option value={0}  > Rok urodzenia...</option>
                        {this.state.years.map((item, index) => {
                          return (
                            <option value={item}  >{item}</option>
                          )
                        })}
                      </Form.Select >
                      <small className='text-muted'>
                        *<FormattedMessage id={"onboarding.birthYearPrivacyInfo"} />
                      </small>
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.birthYear && this.state.errors.birthYear.map((item, index) => {
                          return <div key={'birthYearErrOnb' + index}>{item}</div>
                        })}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} className='pt-3 text-center'>
                      {!this.state.fetching &&
                        <Button onClick={() => { this.handleSave() }}>
                          Zapisz
                        </Button>
                      }
                      {this.state.fetching &&
                        <ThreeDots
                          height="25"
                          width="50"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      }
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
            <Col xs={12} className='pt-2 text-center'>
              <span className='fakeLink text-muted cursor' onClick={() => this.props.logout()}>
                Wyloguj
              </span>
            </Col>
          </Container>
        </Modal.Body>
      </Modal>
    )


  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({
    updateProfile,
    checkDisplayName,
    logout
  }, dispatch)
})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    myProfile: state.profile.myProfile,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OnboardModal))