import React, { Component } from 'react';
import { connect } from 'react-redux';
import { passwordReset } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { ThreeDots } from 'react-loader-spinner'
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import {injectIntl} from 'react-intl'
import XButton from '../../components/xButton'

class PassReset extends Component {
  state = {
    email: "",
    password: "",
    errors: false,
    btnDisabled: false,
    fetching: false,
    success: false,


  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    this.setState({ errors: false, fetching: true })
    this.props.passwordReset(this.state, this.submitted)
  }

  submitted = (data) => {
  //  console.log('submitted', data)
    if (!data.error) {
      this.setState({ success: true, fetching: false })

    }
    else{
      this.setState({fetching:false, errors:data.data})
    }
  }
  render() {
    if (this.props.loggedIn) {
      return <Redirect to={'/'} />
    }
    return (
      <Container style={{ minHeight: '100vh' }} className="px-0">
        <Row noGutters>
          <Col xs={12} md={6} className='mx-auto py-3 px-3 containerBorder borderRounded mt-4'>
            {this.state.success&&
            <Row noGutters>
              <Col xs={12} className="py-3 text-center">
              {this.props.intl.formatMessage({ id: "auth.passResetInfo1" })}<br></br>
              {this.props.intl.formatMessage({ id: "auth.passResetInfo2" })}
              </Col>
            </Row>
            }
            {!this.state.success&&
            <Row noGutters>
              <Col xs={12} className=' h3'>
                {this.props.intl.formatMessage({ id: "auth.passReset" })}
              </Col>
              <Col xs={12} className='pt-2 '>
                    <FormGroup>
                      <FloatingLabel
                        // controlId="pseudonimInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control
                          id="emailInput"
                          type={"email"}
                          value={this.state.email}
                          name={'email'}
                          placeholder="Email"
                          isInvalid={this.state.errors.email}
                          onChange={this.handleChange}
                          disabled={this.state.fetching}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.errors.email && this.state.errors.email.map((item, index) => {
                            return <div key={'pass1ErrFbop' + index}>{item}</div>
                          })}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </FormGroup>
                </Col>
                <Col xs={12} className=' text-center'>
                    {!this.state.fetching &&
                     <XButton
                          onClick={() => { this.handleSubmit(); }}
                          disabled={!this.state.displayNameAvailable}
                      >
                         {this.props.intl.formatMessage({ id: "common.save" })}
                      </XButton>
                    }
                    {this.state.fetching &&
                      <ThreeDots
                        height="25"
                        width="50"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    }
                  </Col>
                  <Col xs={12} className='pt-3 text-center'>
                    <Link to={'/login/'}> {this.props.intl.formatMessage({ id: "auth.areYouAMember" })}</Link><br></br>
                    <Link to={'/register/'}> {this.props.intl.formatMessage({ id: "auth.dontHaveAccount" })}</Link><br></br>
                  </Col>
            </Row>
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

//const mapDispatchToProps = dispatch => ({
// userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
//})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.token
  };
}
export default connect(
  mapStateToProps,
  { passwordReset, }
)(injectIntl(PassReset));
