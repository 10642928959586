import { axiosClient, ApiUrl, HostUrl, upssssText } from './authActions'

export function getPrivList(callback) {
    let path = ApiUrl + "my/privlist/"
    return function (dispatch) {
      axiosClient.get(path)
        .then((response) => {
          callback(response.data)
        })
        .catch((error) => {
          // handle error
           console.log('getPrivList error',error)
           console.log('getPrivList error.response',error.response)
          if (error.response) {
            callback({ error: true, data: error.response.data })
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {

        });
    }
  }

export function addToPrivList(fd, callback) {
    // console.log('updateProfile') 
    let path = ApiUrl + "my/privlist/"
    return function (dispatch) {
      axiosClient.post(path, fd)
        .then((response) => {
          callback(response.data)
        })
        .catch((error) => {
          // handle error
           console.log('addToPrivList error',error)
           console.log('addToPrivList error.response',error.response)
          if (error.response) {
            callback({ error: true, data: error.response.data })
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {

        });
    }
  }

  export function editPrivListItem(fd, callback) {
    // console.log('updateProfile') 
    let path = ApiUrl + "my/privlist/"
    return function (dispatch) {
      axiosClient.patch(path, fd)
        .then((response) => {
          callback(response.data)
        })
        .catch((error) => {
          // handle error
           console.log('editPrivListItem error',error)
           console.log('editPrivListItem error.response',error.response)
          if (error.response) {
            callback({ error: true, data: error.response.data })
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {

        });
    }
  }