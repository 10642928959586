import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { toggleFavorites } from "../../actions/index";
import Image from 'react-bootstrap/Image'
import { SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import default_avatar_boy from '../../static/default_avatar_boy.png'
import default_avatar_girl from '../../static/default_avatar_girl.png'
import default_avatar_unknown from '../../static/default_avatar_unknown.png'

class StreamerThumb extends Component {


    componentDidMount = () => {
       // console.log('StreamerThumb this.props.item', this.props.item,)
    }

    state = {
        item: this.props.item,
    }

    toggleFavorites = () => {
        this.setState({ favsFetching: true })
    //    console.log('toggleFavorites')
        this.props.toggleFavorites(this.state.profile.urlName, this.favoritesFetched)
    }
    favoritesFetched = (data) => {
        this.setState({ favsFetching: false })
        this.setState({ item: data })
    }

    render() {
      //  console.log('this.props.item', this.props.item)
        let avatar = default_avatar_unknown
        if (this.props.item.gender === 1) {
        avatar = default_avatar_girl
        }
        if (this.props.item.gender === 2) {
        avatar = default_avatar_boy
        }
        if (this.props.item.avatar) {
        avatar = this.props.item.avatar
        }
        return (
                <div className="item">
                    <Link to={'/' + this.state.item.urlName + '/'}>
                        <div className="movies-categories">
                            {!this.state.item.streamPoster &&
                                <Image src={avatar} roundedCircle fluid className='absolute-center' />
                            }
                            <div className="movies-img" style={{ backgroundImage: 'url(' + this.state.item.streamPoster + ')', width: this.props.slideWidth, height: this.props.slideHeight }}>
                                <div className="info-top" >
                                    {this.state.item.hostOnline.status === 'online' &&
                                        <span className="tag">Online</span>
                                    }
                                    {this.state.item.hostOnline.status === 'offline' &&
                                        <span className="tag offline" >Offline</span>
                                    }
                                    <div className="ms-auto">
                                        {!this.state.item.inFavorites &&
                                            <AiOutlineHeart style={{ color: '#fff', marginRight: '5px' }} size={18} />
                                        }
                                        {this.state.item.inFavorites &&
                                            <AiFillHeart style={{ color: '#fff', marginRight: '5px' }} size={18} />
                                        }
                                        {/** 
                                        <a className="views pe-3" href="#"><i className="far fa-eye"></i> 14M</a>
                                    */}
                                    </div>
                                </div>
                                <div className="movies-info">
                                    <div className="content">
                                        {this.state.item.time &&
                                            <span className="time" href="#"><i className="far fa-clock me-2"></i>{this.state.item.time}</span>
                                        }
                                        <div className="info-content">
                                            <div className="movies-title">
                                                <h5><span className="title mt-0 capitalize" >{this.state.item.displayName}</span></h5>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        toggleFavorites,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        //loggedIn: state.auth.loggedIn
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(StreamerThumb));
