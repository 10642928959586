import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { ThreeDots } from 'react-loader-spinner'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


function CustomCropper(props) {
    const [image, setImage] = useState();
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            //cropper.disable()
            props.setCroppedData(cropper.getCroppedCanvas().toDataURL('image/jpeg', 90));

        }
    };
    const enable = () => {
        //  cropper.enable()
    }
    const handleAvatarOnChange = () => {
        if (!props.e) {
            return
        }
        props.e.preventDefault();
        let files;
        if (props.e.dataTransfer) {
            files = props.e.dataTransfer.files;
        } else if (props.e.target) {
            files = props.e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        try {
            reader.readAsDataURL(files[0]);
        }
        catch (error) {
            console.log(error)
        }

    }
    handleAvatarOnChange()
    return (
        <Container>
            {!props.success &&
                <Row>
                    <Col xs={12} className={props.fetching ? 'opacity-50' : ''}>
                        <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.0}
                            initialAspectRatio={2 / 1}
                            // preview=".img-preview"
                            src={image}
                            viewMode={2}
                            aspectRatio={props.aspectRatio}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                    </Col>

                    <Col xs={12} className='py-3  text-start'>
                        {!props.fetching &&
                            <Button
                                variant={'primary'}
                                onClick={() => { getCropData(); }}
                            >
                                Save
                            </Button>
                        }
                        {props.fetching &&
                            <ThreeDots
                                height="25"
                                width="50"
                                radius="9"
                                color="#f6be00"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        }
                    </Col>

                </Row>
            }
            {props.success &&
                <Row>
                    <Col xs={12} className='text-center'>
                        <h4>Saved</h4>
                    </Col>
                </Row>
            }
        </Container>
    );
}

export default CustomCropper;