import { axiosClient, ApiUrl, HostUrl, upssssText } from './authActions'

export function getBalance(callback) {
    // console.log('updateProfile') 
    let path = ApiUrl + "my/balance/"
    return function (dispatch) {
      axiosClient.get(path)
        .then((response) => {
          callback(response.data)
        })
        .catch((error) => {
          // handle error
          // console.log('updateProfile error',error)
          // console.log('updateProfile error.response',error.response)
          if (error.response) {
            callback({ error: true, data: error.response.data })
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {
        //  dispatch({ type: 'MY PROFILE_FETCHING', data: false })
        });
    }
  }