import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { userLoginFetch, logout, setAxiosLocaleHeader } from "../../actions/index";
import { Redirect } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { FaChevronDown } from 'react-icons/fa'
import { RiWallet3Line } from 'react-icons/ri'

import { Oval } from 'react-loader-spinner'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Cookies } from 'react-cookie';
import i01 from '../../static/images/avatar/i01.jpg'
import i02 from '../../static/images/avatar/i02.jpg'
import i03 from '../../static/images/avatar/i03.jpg'

import ms17 from '../../static/images/movie/singleCategories/ms17.jpg'
import ms18 from '../../static/images/movie/singleCategories/ms18.jpg'
import ms19 from '../../static/images/movie/singleCategories/ms19.jpg'
import logoLarge from '../../static/logoLarge.png'

const cookies = new Cookies();

class Header extends Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.header2Ref = React.createRef();
  }

  componentDidMount = () => {
    //console.log(this.props)
    document.addEventListener("scroll", this.onWindowScroll)
    if (this.props.locale === 'pl-PL') {
      this.setState({ langVerbal: 'Polish' })
    }
    else {
      this.setState({ langVerbal: 'English' })
    }

  }


  state = {
    expanded: false,
    langVerbal: ''
  }

  onWindowScroll = (e) => {
    if (this.headerRef.current) {
      this.headerRef.current.classList.remove('is-sticky');
      if (window.scrollY > 150) {
        this.headerRef.current.classList.add('is-sticky');
      } else {
        this.headerRef.current.classList.remove('is-sticky');
      }
    }
    if (this.header2Ref.current) {
      this.header2Ref.current.classList.remove('is-sticky');
      if (window.scrollY > 150) {
        this.header2Ref.current.classList.add('is-sticky');
      } else {
        this.header2Ref.current.classList.remove('is-sticky');
      }
    }
  }
  setExpanded = (expanded) => {
    this.setState({ expanded: expanded })
  }
  showBuyTokensmodal = () => {
    this.props.dispatch({ type: 'SET_SHOW_BUY_TOKENS_MODAL', data: true })
  }

  setLocale = (locale) => {
    console.log('setLocale', locale)
    let expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 5));
    cookies.set('locale', locale, { path: '/', expires: expiryDate })
    this.props.dispatch({ type: 'SET_LOCALE', data: locale })
    setAxiosLocaleHeader(locale)
    if (locale === 'pl-PL') {
      this.setState({ langVerbal: 'Polish' })
    }
    else {
      this.setState({ langVerbal: 'English' })
    }
  }

  render() {


    if (this.props.loggedIn) {
      return (
        <>
          <header ref={this.header2Ref} className="header header-sticky header-transparent">
            <Navbar expand="lg"
              expanded={this.state.expanded}
              className="navbar navbar-static-top ">
              <Container className="main-header position-relative" fluid>
                <button type="button" className="navbar-toggler" onClick={() => this.setExpanded(this.state.expanded ? false : "expanded")}><i className="fas fa-align-left"></i></button>
                <Navbar.Brand >
                  <Link to={'/'}><img src={logoLarge} alt="logo" height={25} /></Link>
                </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end justify-content-lg-center">
                  <Nav >
                    <li className=" nav-item  mega-menu" onClick={() => this.setExpanded(false)} >
                      <Link to={'/'} className="nav-link " ><FormattedMessage id= "navi.home"/></Link>
                    </li>
                    <li className=" nav-item  mega-menu" onClick={() => this.setExpanded(false)} >
                      <Link to={'/my/stream/'} className="nav-link " >Stream</Link>  
                    </li>

                    <li className="nav-item dropdown active">
                      <a className="nav-link" href="javascript:void(0)" data-bs-toggle="dropdown"><FormattedMessage id= "navi.profile"/><i className="fas fa-chevron-down fa-xs"></i></a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                          <Link to={'/my/profile/'} className="dropdown-item" >
                            <span><FormattedMessage id= "navi.myProfile"/></span>
                          </Link>
                        </li>
                        <li>
                          <Link to={'/my/wallet/'} className="dropdown-item" >
                            <span><FormattedMessage id= "navi.myWallet"/></span>
                          </Link>
                        </li>
                        <li>
                          <Link to={'/my/earnings/'} className="dropdown-item" >
                            <span><FormattedMessage id= "navi.myEarnings"/></span>
                          </Link>
                        </li>
                        <li>
                          <Link to={'/my/payoutsettings/'} className="dropdown-item" >
                            <span><FormattedMessage id= "navi.payoutSettings"/></span>
                          </Link>
                        </li>
                        <li>
                          <Link to={'/my/verification/'} className="dropdown-item" >
                            <span><FormattedMessage id= "navi.accountVerification"/></span>
                          </Link>
                        </li>
                        {/** 
                        <li className="active"><a className="dropdown-item" href="web-series-single.html"><span>Web Series Single</span></a></li>
                        */}
                      </ul>
                    </li>

                    <li className=" nav-item  mega-menu">
                      <a onClick={() => { this.props.logout() }} className="nav-link text-danger" href="javascript:void(0)" ><FormattedMessage id= "navi.logout"/><i className="fa-solid fa-arrow-right-from-bracket"></i></a>
                    </li>
                  </Nav >

                </Navbar.Collapse>
                <div className="add-listing">
                  <ul className="list-unstyled mb-0">
                    <li className="search">
                      <div className='headerWalletCont cursor' onClick={() => { this.showBuyTokensmodal() }}>
                        <div className='headerWalletAmountPil'>
                          {this.props.myProfile.wallet}
                        </div>
                        <RiWallet3Line size={22} />
                      </div>
                    </li>
                    <li><Link to={'/my/panel/'}><i className="fa-regular fa-user"></i></Link></li>
                    {/** 
                    <li className="dropdown">
                      <a href="javascript:void(0)" className="notifications dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-regular fa-bell"></i><span className="count">3</span></a>
                      <div className="dropdown-menu mt-0 notifications-menu" aria-labelledby="dropdownMenuButton2">
                        <h6 className="notifications-title">Notifications</h6>
                        <div className="notifications-list">
                          <a href="#" className="notifications-info">
                            <div className="notifications-author">
                              <img className="img-fluid" src={i01} alt="" />
                            </div>
                            <div className="notifications-details">
                              <p>sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                            <div className="movie-img">
                              <img className="img-fluid" src={ms17} alt="" />
                            </div>
                          </a>
                        </div>
                        <div className="notifications-list">
                          <a href="#" className="notifications-info">
                            <div className="notifications-author">
                              <img className="img-fluid" src={i02} alt="" />
                            </div>
                            <div className="notifications-details">
                              <p>sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                            <div className="movie-img">
                              <img className="img-fluid" src={ms18} alt="" />
                            </div>
                          </a>
                        </div>
                        <div className="notifications-list">
                          <a href="#" className="notifications-info">
                            <div className="notifications-author">
                              <img className="img-fluid" src={i03} alt="" />
                            </div>
                            <div className="notifications-details">
                              <p>sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                            <div className="movie-img">
                              <img className="img-fluid" src={ms19} alt="" />
                            </div>
                          </a>
                        </div>
                      </div>
                    </li>
                    */}
                  </ul>
                </div>
              </Container>
            </Navbar>
          </header>
        </>
      )
    }


    if (!this.props.loggedIn) {
      return (
        <>
          <header ref={this.headerRef} className="header landing-header header-style-01 header-sticky">
            <Navbar expand="lg"
              //fixed="top" 
              className="navbar navbar-static-top ">
              <Container className=" main-header position-relative">
                <Navbar.Brand >
                  <Link to={'/'}><img src={logoLarge} alt="logo" height={25} /></Link>

                </Navbar.Brand>
                <div className="add-listing login-listing">
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={<>{this.state.langVerbal}<FaChevronDown className="ms-2" /></>}
                    menuVariant="dark"
                    className="pe-2 pe-sm-4"
                  >
                    <NavDropdown.Item href="#" onClick={(e) => {e.stopPropagation();this.setLocale('en')}}>
                      <img className="img-fluid country-flag" src="/static/template/images/country-flags/01.jpg" alt="" />English
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={(e) => {e.stopPropagation();this.setLocale('pl-PL')}}>
                      <img className="img-fluid country-flag" src="/static/template/images/country-flags/01.jpg" alt="" />Polish
                    </NavDropdown.Item>

                  </NavDropdown>
                  {this.props.location.pathname !== '/' &&
                    <div className="login me-3">
                      <Link to="/"><FormattedMessage id= "navi.home"/></Link>
                    </div>
                  }
                  {!this.props.location.pathname.startsWith('/login') &&
                    <div className="login pe-2 pe-sm-4">
                      <Link to="/login/"><i className="fa-regular fa-circle-user"></i><FormattedMessage id= "navi.login"/></Link>
                    </div>
                  }

                </div>
              </Container>
            </Navbar>
          </header>
          <div id="search">
            <button type="button" className="close"><i className="fa-solid fa-xmark"></i></button>
            <form>
              <input type="search" value="" placeholder="type keyword(s) here" />
              <button type="submit" className="btn btn-primary"><FormattedMessage id= "navi.search"/></button>
            </form>
          </div>
        </>
      )
    }

  }
}


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        logout
      }, dispatch)
  }
}
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    myProfile: state.profile.myProfile,
    locale: state.locale.locale,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Header));
