import { Host } from '../actions/authActions'
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import coins1Audio from '../static/audio/coins1Audio.mp3'
const authtoken = localStorage.getItem("token")
const chatWsMiddleware = createListenerMiddleware()
let socket = null;

let reconnectTimeoutId = null
let connectionAttempts = 1
let leaveChannel = false
let channelName = null
let presenceTimerId = null
let pingPongTimerId = null
let pong = null;
//dodac PING/PONG zeby sprawdzac stan polaczenia
const checkPong = (listenerApi) => {
    //   console.log('chat checkPong pong', pong)
    let now = Math.round(+new Date() / 1000);
    let pongdifference = now - pong
    //  console.log('chat checkPong pongdifference', pongdifference)
    if (!pong || pongdifference > 6) {
        // resetujrmy polaczenie
        //   console.log('no PONG on chatWs, reconnect')
        stopPingPongTimer()
        listenerApi.dispatch({ type: 'CHAT_WS_CONNECT', data: channelName });

    }
}
const stopPingPongTimer = () => {
    clearInterval(pingPongTimerId)
    pingPongTimerId = null
    pong = null
}
const startPingPongTimer = (listenerApi) => {
    clearInterval(pingPongTimerId)
    pingPongTimerId = null
    pong = null
    pingPongTimerId = setInterval(() => {
        pong = null
        listenerApi.dispatch({ type: 'CHAT_WS_PING' });
        setTimeout(() => {
            checkPong(listenerApi)
        }, 5000)

    }, 10000)
    // listenerApi.dispatch({ type: 'CHAT_WS_PING'});

}

const reconnectTimer = (listenerApi) => {
    //  console.log('chat reconnectTimer start')
    if (!navigator.onLine) {
        return
    }
    if (reconnectTimeoutId) {
        clearTimeout(reconnectTimeoutId)
    }
    let timeout = 3000
    if (connectionAttempts >= 5) {
        //tu zmieniamy?? Jezli nie polaczyl sie z czatem tyle razy to jest 
        //wiekszy pro problem z polaczeniem (np padaka serwera lub restart), czyli zadne dane nie przechdza.
        //Lepiej przerwac polaczenie transmisje??
        connectionAttempts = 1
        timeout = 11000
    }
    else {
        connectionAttempts = connectionAttempts + 1
        timeout = 3000
    }
    let state = listenerApi.getState()
    if (state.auth.loggedIn) {
        reconnectTimeoutId = setTimeout(() => {
            //     console.log('chat reconnectTimer timeout body')
            listenerApi.dispatch({ type: 'CHAT_WS_CONNECT', data: channelName });
        }, timeout)
    }
}

const stopPresenceTimer = () => {
    //console.log('stopPresenceTimer')
    clearInterval(presenceTimerId)
    presenceTimerId = null
}

const startPresenceTimer = (listenerApi) => {
    //  console.log('startPresenceTimer')
    if (presenceTimerId) {
        clearInterval(presenceTimerId)
        presenceTimerId = null
    }
    presenceTimerId = setInterval(() => {
        //   console.log('chatWs updating presence from timer')
        listenerApi.dispatch({ type: 'CHAT_WS_UPDATE_CHANNEL_PRESENCE' })
    }, 60000)
    listenerApi.dispatch({ type: 'CHAT_WS_UPDATE_CHANNEL_PRESENCE' })
}

const onOpen = listenerApi => (event) => {
    // console.log('chatWs websocket open', event.target.url);

    connectionAttempts = 1
    if (reconnectTimeoutId) {
        clearTimeout(reconnectTimeoutId)
        reconnectTimeoutId = null
    }
    startPresenceTimer(listenerApi)
    startPingPongTimer(listenerApi)
    //listenerApi.dispatch({
    //     type:'CHAT_WS_SEND_MESSAGE',
    //    data:'Hello, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae metus ut enim mattis molestie et nec mauris. Mauris erat metus, vehicula at dignissim id, auctor et sapien. Suspendisse mollis nunc dui, non lobortis diam finibus at. Nullam dui dolor, sodales id libero at, eleifend ornare lectus. Proin ultrices nec arcu in posuere. '
    //   })

};

const onClose = listenerApi => (e) => {
    // console.log('chatWs onClose', e)
    stopPresenceTimer()
    stopPingPongTimer()
    if (!leaveChannel) {
        reconnectTimer(listenerApi)
    }
    else {
        clearTimeout(reconnectTimeoutId)
        channelName = null
        leaveChannel = false
    }
};

const onError = listenerApi => (e) => {
    console.log('chatWs onError', e)
    stopPresenceTimer()
    stopPingPongTimer()
    reconnectTimer(listenerApi)
};

const onMessage = listenerApi => (event) => {
    const payload = JSON.parse(event.data);
    let state = listenerApi.getState()
    //  console.log('chatWs websocket receiving server message', payload);
    switch (payload.command) {
        case 'PONG':
            pong = Math.round(+new Date() / 1000);
            //    console.log('chatWs PONG', pong)
            break;
        case 'BROADCAST_CONNECTION_STATUS':
            if (payload.data.status === 'online') {
                //jakos zmieniamy status wyswietlonego kanalu na online i wyswietlamy info. Redis???
            }
            if (payload.data.status === 'offline') {
                // czy potrzebne??
            }
            break;
        case 'CHAT_WS_USER_JOINED':
            //tylko dla hosta?
            //jak sprawdzic?
            listenerApi.dispatch({ type: 'SET_PRESENCE_COUNT', data: payload.data.presenceCount })
            // console.log('state', state)
            if (state.broadcast.isHost) {
                //   console.log('jest isHost')
                listenerApi.dispatch({ type: 'CHANNEL_WS_GET_INCOME' })
                listenerApi.dispatch({
                    type: 'ADD_CHAT_MESSAGE',
                    data: payload.data
                })
            }

            break;
        case 'CHAT_WS_USER_LEFT':
            //wyswietlamy informacje o nowym LEAVIE. Dodajemy do chatData i wyswietlamy w chacie?
            listenerApi.dispatch({ type: 'SET_PRESENCE_COUNT', data: payload.data.presenceCount })
            if (state.broadcast.isHost) {
                listenerApi.dispatch({
                    type: 'ADD_CHAT_MESSAGE',
                    data: payload.data
                })
            }
            break;
        case 'CHAT_WS_NEW_TIP_SENT':
            //wyswietlamy informacje o nowym LEAVIE. Dodajemy do chatData i wyswietlamy w chacie?
            listenerApi.dispatch({
                type: 'ADD_CHAT_MESSAGE',
                data: payload.data
            })
            //console.log('playAudio')
            try {
                new Audio(coins1Audio).play();
            }
            catch (err) {
                console.log('error playing audio', err)
            }
            //  new Audio(coins1Audio).play();
            if (state.broadcast.isHost) {
                //   console.log('jest isHost')
                listenerApi.dispatch({ type: 'CHANNEL_WS_GET_INCOME' })
            }
            break;

        case 'CHAT_WS_NEW_MESSAGE':
            //dispach do chat reducer
            listenerApi.dispatch({
                type: 'ADD_CHAT_MESSAGE',
                data: payload.data
            })
            break;
        case 'CHAT_WS_SET_PRESENCE_COUNT':
            //  console.log('CHAT_WS_SET_PRESENCE_COUNT', payload.data)
            listenerApi.dispatch({ type: 'SET_PRESENCE_COUNT', data: payload.data })

            break
        case 'CHAT_WS_CAMERA_ORIENTATION_CHANGED':
            const event = new CustomEvent("RemoteCameraOrientationChange");
            window.dispatchEvent(event);
            break

    }
}



chatWsMiddleware.startListening({
    actionCreator: 'chatWsMiddleware',
    effect: async (action, listenerApi) => {
        let state = listenerApi.getState()
        let payload;
        switch (action.type) {
            case 'CHAT_WS_DISCONNECT':
                leaveChannel = true
                channelName = null
                if (socket) {
                    socket.close();
                    socket = null;
                }
                break;
            case 'CHAT_WS_CONNECT':
                if (socket) {
                    socket.close();
                }
                if (reconnectTimeoutId) {
                    clearTimeout(reconnectTimeoutId)
                }
                setTimeout(() => {
                    socket = null
                    if (state.profile.myProfile && state.auth.loggedIn) {
                        channelName = action.data
                        if (channelName) {
                            let wsProtocol = 'wss'
                            if (window.location.protocol === 'http:') {
                                wsProtocol = 'ws'
                            }
                            let host = Host + '/ws/chat/?token=' + state.auth.token + '&channelName=' + channelName
                            socket = new WebSocket(host);
                            socket.onopen = onOpen(listenerApi);
                            socket.onclose = onClose(listenerApi);
                            socket.onmessage = onMessage(listenerApi);
                            socket.onerror = onError(listenerApi);
                        }
                        else{
                            console.log('chatWsMiddleware cant connect chat socket, no channelName in action.data')
                        }   

                    }
                }, 300)
                break;
            case 'CHAT_WS_PING':
                payload = {
                    'command': 'PING',
                    'data': {}
                }
                socket.send(JSON.stringify(payload));
                break;
            case 'CHAT_WS_SEND_MESSAGE':
                payload = {
                    'command': 'CHAT_WS_SEND_CHAT_MESSAGE',
                    'data': { 'message': action.data }
                }
                socket.send(JSON.stringify(payload));
                break;
            case 'CHAT_WS_CAMERA_ORIENTATION_CHANGED':
                payload = {
                    'command': 'CHAT_WS_CAMERA_ORIENTATION_CHANGED',
                    'data': {}
                }
                socket.send(JSON.stringify(payload));
                break;
            case 'CHAT_WS_UPDATE_CHANNEL_PRESENCE':
                //  console.log('CHAT_WS_UPDATE_CHANNEL_PRESENCE w middleware')
                payload = {
                    'command': 'CHAT_WS_UPDATE_CHANNEL_PRESENCE',
                    'data': {}
                }
                socket.send(JSON.stringify(payload));
                //   console.log('CHAT_WS_UPDATE_CHANNEL_PRESENCE w wyslane')
                payload = {
                    'command': 'CHAT_WS_GET_PRESENCE_COUNT',
                    'data': {}
                }
                socket.send(JSON.stringify(payload));

                break;

            case 'CONNECTION_STATE_CHANGE':
                if (action.data === 'offline') {
                    if (socket) {
                        socket.close();
                        socket = null
                    }
                }
                if (action.data === 'online') {
                    clearTimeout(reconnectTimeoutId)
                    listenerApi.dispatch({ type: 'CHAT_WS_CONNECT' })
                }
                break;

            case 'CHAT_WS_USER_JOINED':
                //wrzucamy do listy przez redux?
                //odswierzamy liste userow z serwera?

                break;

        }
    }

})

export default chatWsMiddleware