import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { getBalance, saveVerificationPic, verifyAccount, getVerificationPics } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs'
import Spinner from 'react-bootstrap/Spinner';

import { Oval } from 'react-loader-spinner'
import { injectIntl, FormattedMessage } from 'react-intl'
import verification from '../../static/images/svg/verification.svg'
import { BsCheckLg, BsCameraFill } from 'react-icons/bs'
import toast, { Toaster, ToastBar } from 'react-hot-toast';


class VerifyAccountView extends Component {

    constructor(props) {
        super(props)
        this.captureStreamPlayerRef = React.createRef();
        this.captureStreamHolderRef = React.createRef();
        this.snapShotCanvasRef = React.createRef();

    }


    componentDidMount = () => {
        document.title = 'Chillistream - Verification'
        //  console.log(this.props.myProfile)
        window.addEventListener('resize', this.handleResize)
        // this.setPlayerConfig()
        this.setState({ verificationPicsFetching: true })
        this.props.getVerificationPics(this.verificationPicsFetched)
    }

    componentWillUnmount = () => {
        // this.stopGetIncomesTimer()
        window.removeEventListener('resize', this.handleResize)
        if (this.state.cameraStream) {
            this.state.cameraStream.getTracks().forEach(function (track) { track.stop(); });
        }
    }
    state = {
        cameraStream: undefined,
        playerConfig: { width: '0px', height: 'Opx' },
    }

    handleResize = () => {
        //    console.log('handleResize')
        //this.setPlayerConfig()
        this.calculatePlayerSize()
    }

    verificationPicsFetched = (data) => {
        // console.log('verificationPicsFetched', data)
        this.setState({ verificationPicsFetching: false })
        this.setState({ verificationPics: data })

    }


    getPerrmision = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then((stream) => {
                navigator.mediaDevices
                    .getUserMedia({ video: false, audio: true })
                    .then((stream) => {
                        // video.srcObject = stream;
                        //   video.play();
                    })
                    .catch((err) => {
                        toast.error(`An microphone error occurred: ${err}`);
                    });
                this.enableCamera(true)
            })
            .catch((err) => {
                toast.error(`An camera error occurred: ${err}`);
            });

    }
    calculatePlayerSize = () => {
        if (!this.state.playerConfig) {
            return
        }
        if (!this.state.playerConfig.cameraWidth) {
            return
        }
        if (!this.state.playerConfig.cameraHeight) {
            return
        }
        if (this.state.playerConfig.cameraWidth > this.state.playerConfig.cameraHeight) {
            let playerWidth = this.state.playerConfig.cameraWidth
            let playerHeight = this.state.playerConfig.cameraHeight
            let aspectRatio = playerWidth / playerHeight
            let calculatedHeight = window.innerHeight - 50
            let calculatedWidth = calculatedHeight * aspectRatio
            if (calculatedWidth > window.innerWidth - 50) {
                calculatedWidth = window.innerWidth - 50
                calculatedHeight = calculatedWidth / aspectRatio
            }
            this.setState({ playerConfig: { ...this.state.playerConfig, width: calculatedWidth, height: calculatedHeight } },
                () => {
                    //  console.log('playerConfig', this.state.playerConfig)
                })
        }
        else {
            let playerWidth = this.state.playerConfig.cameraWidth
            let playerHeight = this.state.playerConfig.cameraHeight
            let aspectRatio = playerWidth / playerHeight
            let calculatedWidth = window.innerWidth - 50
            let calculatedHeight = calculatedWidth / aspectRatio
            if (calculatedHeight > window.innerHeight - 50) {
                calculatedHeight = window.innerHeight - 50
                calculatedWidth = calculatedHeight * aspectRatio
            }
            this.setState({ playerConfig: { ...this.state.playerConfig, width: calculatedWidth + 'px', height: calculatedHeight + 'px' } },
                () => {
                    //   console.log('playerConfig', this.state.playerConfig)
                }
            )
        }
    }
    enableCamera = (ommitCheck) => {
        navigator.mediaDevices
            .getUserMedia({
                video: {
                    facingMode: this.state.captureConf.facingMode,
                    width: { min: 720 },
                    height: { min: 720 },
                },
                audio: false
            })
            .then((stream) => {

                stream.getTracks().forEach((track) => {
                    let trackSettings = track.getSettings()
                    console.log(trackSettings);
                    if (trackSettings.width && trackSettings.height) {
                        let playerConfig = {
                            cameraWidth: trackSettings.width,
                            cameraHeight: trackSettings.height,
                        }
                        this.setState({ playerConfig: playerConfig }, () => {
                            this.calculatePlayerSize()
                        })

                    }
                })
                this.setState({ cameraStream: stream, showCapturePlayer: true, }, () => {
                    this.captureStreamPlayerRef.current.srcObject = stream;
                    this.captureStreamPlayerRef.current.setAttribute('autoplay', '');
                    this.captureStreamPlayerRef.current.setAttribute('muted', '');
                    this.captureStreamPlayerRef.current.setAttribute('playsinline', '')
                    setTimeout(() => {
                        this.captureStreamPlayerRef.current.play();
                    }, 500)
                  
                })
            })
            .catch((err) => {
                toast.error(`An error occurred: ${err}`);
            });
        return
        if (!ommitCheck) {
            navigator.permissions.query({ name: "camera" }).then(res => {
                if (res.state !== "granted") {
                    toast((t) => (
                        <div className='text-center'>
                            <p>To take photos, please grant permission for this app to access your camera.</p>
                            <div className='text-center pb-3'>
                                <Button size={'sm'} variant={'primary'} onClick={() => { toast.dismiss(t.id); this.getPerrmision(true) }}>
                                    Grant Premission
                                </Button>
                            </div>
                            <div className='text-center'>
                                <Button size={'sm'} variant={'light'} onClick={() => toast.dismiss(t.id)}>
                                    Later
                                </Button>
                            </div>
                        </div>
                    ), { duration: Infinity, });

                    return
                }
                else {
                    this.enableCamera(true)
                }
            });
        }
        if (ommitCheck) {
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        facingMode: this.state.captureConf.facingMode,
                        width: { min: 720 },
                        height: { min: 720 },
                    },
                    audio: false
                })
                .then((stream) => {
                    // video.srcObject = stream;
                    //   video.play();

                    this.setState({ cameraStream: stream, showCapturePlayer: true, }, () => {
                        //inicjujemy video
                        //captureStreamPlayerRef

                        this.captureStreamPlayerRef.current.srcObject = stream;
                        this.captureStreamPlayerRef.current.play();
                        // setTimeout(()=>{
                        //      this.setPlayerConfig()
                        //   }, 500)
                    })
                })
                .catch((err) => {
                    toast.error(`An error occurred: ${err}`);
                });
        }
    }


    balanceFetched = (data) => {
        //  console.log('balanceFetched', data)
        this.setState({ balanceData: data })
    }

    capturePicture = () => {
        let cameraSettings = this.state.cameraStream.getVideoTracks()[0].getSettings()
        //   console.log('cameraSettings', cameraSettings)
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = cameraSettings.width
        canvas.height = cameraSettings.height
        ctx.drawImage(this.captureStreamPlayerRef.current, 0, 0, cameraSettings.width, cameraSettings.height);
        const dataUrl = canvas.toDataURL("image/jpeg", 0.8);
        this.setState({ snapshotDataUrl: dataUrl })
        this.setState({ showCaptureCanvas: true, }, () => {
            this.snapShotCanvasRef.current.src = dataUrl
        })
    }

    takeFrontSideIdPic = () => {

        let conf = {
            rounded: true,
            title: 'Front Side of Your Id',
            type: 'idFront',
            timer: false,
            facingMode: 'environment',
        }
        this.setState({ captureConf: conf }, () => {
            this.enableCamera()
        })
    }
    takeBackSideIdPic = () => {
        let conf = {
            rounded: true,
            title: 'Back Side of Your Id',
            type: 'idBack',
            timer: false,
            facingMode: 'environment',
        }
        this.setState({ captureConf: conf }, () => {
            this.enableCamera()
        })
    }
    takeFacePic = () => {
        let conf = {
            rounded: true,
            title: 'Selfie With ID Card',
            type: 'facePic',
            timer: false,
            facingMode: 'user',
        }
        this.setState({ captureConf: conf }, () => {
            this.enableCamera()
        })
    }
    saveCapturedImage = () => {
        this.setState({ snapshotUploadFetching: true })
        const fd = new FormData()
        if (this.state.captureConf.type === 'idFront') {
            fd.append('idFront', this.state.snapshotDataUrl)
        }
        if (this.state.captureConf.type === 'idBack') {
            fd.append('idBack', this.state.snapshotDataUrl)
        }
        if (this.state.captureConf.type === 'facePic') {
            fd.append('idSelfie', this.state.snapshotDataUrl)
        }
        this.props.saveVerificationPic(fd, this.capturedImageSaved)
    }

    capturedImageSaved = (data) => {
        //  console.log('capturedImageSaved', data)
        this.setState({ snapshotUploadFetching: false })
        if (!data.error) {
            this.setState({ snapshotUploadSuccess: true, verificationPics: data }, () => {
                setTimeout(() => {
                    this.hideCapturePlayer()
                }, 1500)
            })
        }
        // toast.success('Okładka albumu została zapisana.', { duration: 4000, })


    }


    hideCapturePlayer = () => {
        //killujemy player i streamy
        if (this.captureStreamPlayerRef.current) {
            this.captureStreamPlayerRef.current.pause();
            this.captureStreamPlayerRef.current.src = "";
        }

        this.state.cameraStream.getTracks().forEach(function (track) { track.stop(); });
        this.setState({
            showCapturePlayer: false,
            videoStream: undefined,
            captureConf: undefined,
            cameraStream: undefined,
            showCaptureCanvas: false,
            snapshotDataUrl: undefined,
            snapshotUploadFetching: false,
            snapshotUploadSuccess: false
        })
    }

    setPlayerConfig = () => {
        if (!this.state.cameraStream) {
            return
        }
        if (!this.state.showCapturePlayer) {
            return
        }
        let screenOrientation;
        let currentWidth = this.captureStreamHolderRef.offsetWidth
        let currentHeight = this.captureStreamHolderRef.offsetHeight
        let cameraSettings = this.state.cameraStream.getVideoTracks()[0].getSettings()
        // console.log('cameraSettings', cameraSettings)
        let aspectRatio = cameraSettings.aspectRatio
        let calculatedHeight = Math.floor(currentWidth / aspectRatio)
        let calculatedWidth = currentWidth
        if (calculatedWidth > calculatedHeight) {
            screenOrientation = 'horizontal'
        }
        else {
            screenOrientation = 'vertical'
        }
        calculatedWidth = '100%'
        if (calculatedHeight > document.documentElement.clientHeight - 50) {
            calculatedHeight = document.documentElement.clientHeight - 50
            calculatedWidth = calculatedHeight * aspectRatio
        }
        if (calculatedWidth > document.documentElement.clientWidth - 50) {
            calculatedWidth = document.documentElement.clientWidth - 50
            calculatedHeight = calculatedWidth / aspectRatio
        }
        let conf = {
            height: calculatedHeight,
            width: calculatedWidth,
            screenOrientation: screenOrientation,
            cameraWidth: cameraSettings.width,
            cameraHeight: cameraSettings.height,
            aspectRatio: aspectRatio,

        }
        console.log('conf', conf)
        this.setState({ playerConfig: conf })
        //  let cameraSettings=this.state.cameraStream.getSettings()
        // console.log('cameraSettings', cameraSettings)
    }

    verifyAccount = () => {
        this.setState({ verificationFething: true })
        this.props.verifyAccount(this.verificationFetched)
    }
    verificationFetched = (data) => {
        console.log(data)
        this.setState({ verificationFething: false })
        if (data.error) {
            this.setState({ required: data.required })
            //toast.error(data.message)
            toast.error('Please complete required fields')
        }
    }
    renderCapturePlayer = (conf) => {
        return (
            <div className='capturePlayerHolder'>
                <Container>
                    <Row>
                        {this.state.snapshotUploadSuccess &&
                            <Col xs={12} className='text-center mt-3 h-90' style={{ position: 'relative' }} ref={this.captureStreamHolderRef}>
                                <div className='absolute-center'>
                                    <h2>Success <BsCheckLg className='text-success' style={{ margonLeft: '15px' }} /></h2>
                                </div>

                            </Col>
                        }
                        {!this.state.snapshotUploadSuccess &&
                            <Col xs={12} className='text-center mt-3' style={{ position: 'relative' }} ref={this.captureStreamHolderRef}>
                                <div style={{ position: 'relative', width: 'auto', height: 'auto', display: 'block' }}>
                                    <div className='captirePlayerTitle'>
                                        <h3>{this.state.captureConf.title}</h3>
                                    </div>
                                    <div className='capturePlayerBtnHolder'>
                                        {!this.state.showCaptureCanvas &&
                                            <button className='btn btn-primary' onClick={() => this.capturePicture()}>
                                                <BsCameraFill className='btnLgSvg' /> <FormattedMessage id="verifyAccount.capture" />
                                            </button>
                                        }
                                    </div>
                                    <div className='capturePlayerCloseBtn' onClick={() => this.hideCapturePlayer()}>
                                        <AiOutlineClose />
                                    </div>
                                    <video className={this.state.captureConf.rounded ? 'capturePlayerVideo rounded' : 'capturePlayerVideo'}
                                        ref={this.captureStreamPlayerRef}
                                        style={{ border: '2px solid red', width: this.state.playerConfig.width, height: this.state.playerConfig.height, maxHeight: '90vh' }}>
                                    </video>
                                    {this.state.showCaptureCanvas &&
                                        <div className='verCaptureCanvasHolder'>,
                                            <Image className=' mx-auto' fluid
                                                ref={this.snapShotCanvasRef}
                                                src={this.state.snapshotDataUrl}
                                                style={{}} />

                                            <div className='capturePlayerBtnHolder'>
                                                <Row>
                                                    <Col className='text-end pe-2'>
                                                        <button className='btn btn-primary' onClick={() => this.saveCapturedImage()}>
                                                            <BsCameraFill
                                                                disabled={this.state.snapshotUploadFetching}
                                                                className='btnLgSvg' /> <FormattedMessage id="common.save" /> {this.state.snapshotUploadFetching && <Spinner size={'sm'} />}
                                                        </button>
                                                    </Col>
                                                    <Col className='text-start ps-2'>
                                                        <button className='btn btn-primary' onClick={() => this.setState({ showCaptureCanvas: false, snapshotDataUrl: undefined })}>
                                                            <BsCameraFill className='btnLgSvg' /> <FormattedMessage id="common.cancel" />
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                    }
                                </div>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        )

    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }
        if (!this.state.verificationPics || !this.props.myProfile) {
            return (
                <Row>
                    <Col md={12} className='pt-4 pb-0' >
                        <div class="section-title ">
                            <img src={verification} alt="#" height={45} /> <h2 className="title"><FormattedMessage id="verifyAccount.accountVerification" /></h2>
                        </div>
                    </Col>
                    <Col xs={12} className='mt-5 pt-2'>
                        <Spinner size={'lg'} />
                    </Col>
                </Row>
            )
        }
        let verificationBtnDisabled = true
        if (this.state.verificationPics.idFront && this.state.verificationPics.idBack && this.state.verificationPics.idSelfie && this.props.myProfile.iban) {
            verificationBtnDisabled = false
        }


        return (
            <div class="space-pt overflow-hidden h-70 myEarnings accountVerification">
                <Toaster
                >
                    {(t) => (
                        <ToastBar toast={t}
                            style={{
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            }}
                        >
                            {({ icon, message }) => (
                                <>
                                    {icon}
                                    {message}
                                </>
                            )}
                        </ToastBar>
                    )}

                </Toaster>
                <Container  >
                    <Row >
                        <Col md={12} className='pt-4 pb-0' >
                            <div class="section-title ">
                                <img src={verification} alt="#" height={45} /> <h2 className="title"><FormattedMessage id="verifyAccount.accountVerification" /></h2>
                            </div>
                        </Col>
                        <Col xs={12} className=' mb-4 '>
                            <Link to={'/'}><BsArrowLeftShort /><FormattedMessage id="common.backToUserPanel" /></Link>
                        </Col>
                    </Row>
                    {this.props.myProfile.isVerified === 0 &&
                        <>
                            <Row >
                                <Col xs={12} md={7} className='mt-4' >

                                    <Row>
                                        <Col xs={5} >
                                            <h4 className={this.state.required && this.state.required.iban ? 'text-danger' : ''}><FormattedMessage id="payoutSettings.payoutSettings" />:</h4>
                                        </Col>
                                        <Col xs={7} >
                                            {this.props.myProfile.iban &&
                                                <BsCheckLg size={26} className='text-success' />
                                            }
                                            {!this.props.myProfile.iban &&
                                                <Link to={'/my/payoutsettings/'}> (<FormattedMessage id="verifyAccount.required" />)<BsArrowRightShort /></Link>
                                            }
                                        </Col>
                                    </Row>

                                    <div className="section-title2  verificationSection">
                                    </div>
                                </Col>
                            </Row>
                            {this.state.verificationPicsFetching &&
                                <Row>
                                    <Col xs={12} className='mt-5 pt-2'>
                                        <Spinner size={'lg'} />
                                    </Col>
                                </Row>
                            }
                            {this.state.verificationPics &&
                                <Row >
                                    <Col xs={12} md={7} className=''>
                                        <Row>
                                            {(!this.state.verificationPics.idFront || !this.state.verificationPics.idBack) &&
                                                <Col xs={12} className='mt-3' >
                                                    {this.props.locale === 'en' &&
                                                        <span>Take a pictures of your identity document document using your device camera. <br></br>
                                                            Your picture, name and birth date must be visible. All other data can be covered.</span>
                                                    }
                                                    {this.props.locale === 'pl-PL' &&
                                                        <span>Zrób zdjęcie dokumentu tożsamości za pomocą Swojej kamerki. <br></br>
                                                            Twoje zdjęcie, imię i nazwisko oraz data urodzenia muszą być widoczne. Wszystkie pozostałe dane możesz zasłonić</span>
                                                    }

                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col xs={5} >
                                                <h4 className={this.state.required && this.state.required.idFront ? 'text-danger pt-2' : 'pt-2'}><FormattedMessage id="verifyAccount.frontId" />: </h4>
                                            </Col>
                                            <Col xs={7} className='mt-2' >
                                                {!this.state.verificationPics.idFront ? <Button size={'sm'} variant={'primary'} onClick={() => this.takeFrontSideIdPic()}><BsCameraFill className='btnSmallSvg' />Take Picture</Button> : <BsCheckLg size={26} className='text-success' />}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} >
                                                <h4 className={this.state.required && this.state.required.idBack ? 'text-danger mt-4 pt-2' : 'mt-4 pt-2'}><FormattedMessage id="verifyAccount.backId" />: </h4>
                                            </Col>
                                            <Col xs={7} className='mt-4' >
                                                {!this.state.verificationPics.idBack ? <Button size={'sm'} variant={'primary'} onClick={() => this.takeBackSideIdPic()}><BsCameraFill className='btnSmallSvg' />Take Picture</Button> : <BsCheckLg size={26} className='text-success' />}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={12} md={7} className=''>
                                                <Row>
                                                    <Col xs={5} >
                                                        <h4 className={this.state.required && this.state.required.idSelfie ? 'text-danger mt-4 pt-2' : 'mt-4 pt-2'}><FormattedMessage id="verifyAccount.selfieId" />: </h4>
                                                    </Col>
                                                    <Col xs={7} className='mt-4' >
                                                        {!this.state.verificationPics.idSelfie ? <Button size={'sm'} variant={'primary'} onClick={() => this.takeFacePic()}><BsCameraFill className='btnSmallSvg' /><FormattedMessage id="verifyAccount.takePicture" /></Button> : <BsCheckLg size={26} className='text-success' />}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} className='mt-5 pt-2 pb-5'>

                                        <button disabled={this.state.verificationFething} className='btn btn-primary' onClick={() => this.verifyAccount()}> <FormattedMessage id="verifyAccount.requestAccVerification" /> {this.state.verificationFething && <Spinner size={'sm'} />}</button>
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                    {this.props.myProfile.isVerified === 1 &&
                        <Row>
                            <Col xs={12} className='mt-5 pt-2'>
                                <h2><FormattedMessage id="verifyAccount.verificationPendingInfo" /></h2>
                            </Col>
                        </Row>
                    }
                    {this.props.myProfile.isVerified === 2 &&
                        <Row>
                            <Col xs={12} className='mt-5 pt-2'>
                                <h2><FormattedMessage id="verifyAccount.AccountIsVerified" /></h2>
                            </Col>
                        </Row>
                    }
                </Container>
                {
                    this.state.showCapturePlayer &&
                    this.renderCapturePlayer()
                }
            </div >

        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getBalance,
        saveVerificationPic,
        verifyAccount,
        getVerificationPics,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn,
        myProfile: state.profile.myProfile,
        locale: state.locale.locale,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(VerifyAccountView));
