import React, { Component, Suspense } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { getUserProfile, toggleFavorites } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { injectIntl } from 'react-intl'

import { isMobile } from "react-device-detect";
import innerBanner1 from '../../static/images/bg/innerBanner1.jpg'
import default_avatar_boy from '../../static/default_avatar_boy.png'
import default_avatar_girl from '../../static/default_avatar_girl.png'
import default_avatar_unknown from '../../static/default_avatar_unknown.png'
import default_profile_bg from '../../static/default_profile_bg.png'
import ReactGA from "react-ga4";
const AudiencePlayerWrapper =React.lazy(() => import ('../../components/broadcast/audiencePlayerWrapper'));

class ViewProfile extends Component {

  constructor(props) {
    super(props)
    this.playerContRef = React.createRef();
    this.headerRef = React.createRef();
   // console.log('ViewProfile')
  }

  hitGAPageView = (title=false) => {
    ReactGA.initialize("G-ZRFN0MKZ4B");
    let conf={ hitType: "pageview", 
                page: window.location.pathname + window.location.search,
                //title: "/home" 
              }
    if(title){
      if(this.props.loggedIn){
        conf.title=this.props.match.params.urlName
      }
      else{
        conf.title=this.props.match.params.urlName + '-Niezalogowany'
      }
      
    }
    ReactGA.send(conf);
  }
  componentDidMount = () => {
    document.addEventListener("broadcast_status_changed", this.broadcastStatusChangedListener);
    let urlName = this.props.match.params.urlName
    document.title = urlName + ' - kamerki na zywo'
    window.addEventListener('resize', this.handleResize)
    window.addEventListener("scroll", this.handleScroll);
    this.hitGAPageView('/'+urlName)
    this.props.getUserProfile(urlName, this.profileFetched)
    if (this.headerRef.current) {
      setTimeout(() => {
        this.headerRef.current.scrollIntoView()
      }, 700)
    }

  }
  componentWillUnmount = () => {
  //  this.props.dispatch({ type: 'CHANNEL_WS_DISCONNECT', data: this.state.profile.uuid })
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("broadcast_status_changed", this.broadcastStatusChangedListener);
  }

  state = {
    playerHolderHeight: window.innerHeight - 70,
    lastScrollPos: undefined,
    playerState: {},
    connectionState: 'DISCONNECTED',
    audiencePlayerKey:'kjhkh'
  }
  
  broadcastStatusChangedListener=()=>{  
    console.log('broadcastStatusChangedListener')
    this.props.getUserProfile(this.props.match.params.urlName, this.profileFetched)
    //this.setState({audiencePlayerKey:Math.random()})
  }

  handleScroll = (e) => {
    if (!isMobile) {
      // return
    }
    setTimeout(() => {
      try {
        var distance = this.playerContRef.current.getBoundingClientRect().top
        // console.log(distance)
        let lastScrollPos = this.state.lastScrollPos
        if (distance === lastScrollPos) {
          if (distance < 45 && distance > -80) {
            this.playerContRef.current.scrollIntoView()
          }
        }
        else {
          this.setState({ lastScrollPos: distance })
        }
      } catch (e) {

      }

    }, 300)

  }

  handleResize = () => {
    this.setState({
      playerHolderHeight: window.innerHeight - 70
    })
  }

  profileFetched = (data) => {
   // console.log('profileFetched', data)
    if (!data.error) {
      this.setState({ profile: data })
      let userUUID = data.uuid//to jest uuid usera. Prawidlowo dlachannel_ws. Nie zmieniac.
      this.props.dispatch({ type: 'CHANNEL_WS_CONNECT', data: userUUID })
    }
    else {
      if (data.data.code) {
        if (data.data.code == 'notExists') {
          this.setState({ notExists: true })
        }
      }
    }
  }
  toggleFavorites = () => {
    if(this.props.favsFetching){
      return
    }
    this.setState({ favsFetching: true })
   // console.log('toggleFavorites')
    this.props.toggleFavorites(this.state.profile.urlName, this.favoritesFetched)
  }
  favoritesFetched = (data) => {
    this.setState({ favsFetching: false })
    this.setState({ profile: data })
  }

  setParentConnectionState = (connectionState) => {
   // console.log('setParentConnectionState', connectionState)
    this.setState({ connectionState: connectionState })

    if (connectionState === 'CONNECTED') {
      if (!isMobile) {
        this.playerContRef.current.scrollIntoView()
      }

    }
  }

  setParentStreamerOnline = (online) => {
 //   console.log('setParentStreamerOnline', online)
    this.setState({ online: online })
  }



  renderHeader = () => {
    let avatar = default_avatar_unknown
    if (this.state.profile.gender === 1) {
      avatar = default_avatar_girl
    }
    if (this.state.profile.gender === 2) {
      avatar = default_avatar_boy
    }
    if (this.state.profile.avatar) {
      avatar = this.state.profile.avatar
    }
    let bgPic = innerBanner1;
    if (this.state.profile.channelBgPic) {
      bgPic = this.state.profile.channelBgPic
    }
    return (
      <>
        <div className="inner-banner bg-holder bg-overlay-black-3 " ref={this.headerRef} style={{ backgroundImage: "url(" + bgPic + ")", marginBottom: '45px' }}>
          <Container className="position-relative">
            <Row >
              <Col md={12} className='profileHeader'>
                <div className="header-inner-title">

                </div>
                <div class="profile-info">
                  <div class="profile-avatar">
                    <img class="img-fluid" src={avatar} alt="" />
                    <div className='text-center profHeaderOnlineInfo'>
                      {this.state.online === 'online' &&
                        <span className='text-primary'>Online</span>
                      }
                      {this.state.online === 'offline' &&
                        <span className='text-danger'>Offline</span>
                      }
                    </div>
                  </div>
                  <div className='name-info'>
                    <h5 class="title capitalize">{this.state.profile.displayName}</h5>
                    <span className=''>@{this.state.profile.urlName}</span><br></br>

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

      </>
    )
  }
  render() {
    if (!this.props.loggedIn) {
      // return <Redirect to={'/'} /> 
    }
    if (this.state.notExists) {
      return (
        <Container fluid style={{ minHeight: '100vh' }} className="px-0 pt-5 mt-5">
          <Row>
            <Col md={12} className='text-center'>
              <h3>Profile not found</h3>
            </Col>
          </Row>
        </Container>
      )
    }
    if (!this.state.profile) {
      return (
        <Container fluid style={{ minHeight: '100vh' }} className="px-0">
          <div className='absolute-center' >
            <Oval height="80"
              width="80"
              radius="9"
              color='#f6be00'
              secondaryColor='#f6be00'
            />
          </div>
        </Container>
      )
    }

    return (
      <>
        {this.state.connectionState === 'DISCONNECTED' &&
          <>
            {this.renderHeader()}

          </>
        }



        <Container ref={this.playerContRef} fluid
          className="px-0 setreamerPlayerMainHolder"
        //  style={{height:this.state.playerHolderHeight}}
        >
          <Suspense fallback={<div>Loading...</div>}>
          <AudiencePlayerWrapper
            key={this.state.audiencePlayerKey}
            role={'audience'}
            //broadcastUUID={this.state.profile.broadcastUUID}
            userProfile={this.state.profile}
            setParentConnectionState={this.setParentConnectionState}
            setParentStreamerOnline={this.setParentStreamerOnline}
            toggleFavorites={this.toggleFavorites}
            favsFetching={this.state.favsFetching}
            inFavorites={this.state.profile.inFavorites}
          />
          </Suspense>
        </Container>
        {this.state.connectionState !== 'DISCONNECTED' &&
          <>
            <div class="space-ptb">
              <Container>

              </Container>
            </div>
            {this.renderHeader()}
          </>
        }
        <Container className='mt-5'>
          <Row>
            <Col xs={12} className='text-center mt-4 px-2 mb-3'>
              <div className='section-title mb-2'>
                <h4 className='title capitalize'>About Me:</h4>
              </div>
            </Col>
            <Col xs={12} className='profile-info-content '>
              <p>{this.state.profile.aboutMe}</p>
            </Col>
          </Row>

        </Container>
      </>
    )


  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({
    getUserProfile,
    toggleFavorites,
    //  userLoginFetch,
  }, dispatch)
})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    myProfile: state.profile.myProfile,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ViewProfile));
