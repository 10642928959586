import { createReducer } from '@reduxjs/toolkit'
const initialState={
    myProfile:null,
    payoutData:null,
    aboutMeIsSaving:false,
    priceIsSaving:false,
    myProfileFetching:false,
    payoutDataFetching:false,
    verificationPictureFetching:false,
    accountVerificationFetching:false,
    passwordFetching:false,
    deleteAccountFetching:false,
    bloggerProfile:false,
//    unreadedChatsCount:undefined,
    unreadedNotsCount:undefined,
    showOnboardModal:false,
    cookiesReaded:true,
    adultContentWarningReaded:true,
    locale:null,
    showBuyTokensModal:false,
    buyTokensModalOutOfBalance:false,
    showPushNotsModal:false,
}

const profile = createReducer(initialState, (builder) => {
    builder
    .addCase('SET_SHOW_BUY_TOKENS_MODAL', (state, action) => {
      state.showBuyTokensModal=action.data
    })
    .addCase('SET_BUY_TOKENS_MODAL_OUT_OF_BALANCE', (state, action) => {
      state.buyTokensModalOutOfBalance=action.data
    })
    .addCase('SET_LOCALE', (state, action) => {
      state.locale=action.data
    })
      .addCase('SET_COOKIES_READED', (state, action) => {
        state.cookiesReaded=action.data
      })
      .addCase('SET_ADULT_CONTENT_READED', (state, action) => {
        state.adultContentWarningReaded=action.data
      })
      .addCase('SHOW_ONBOARD_MODAL', (state, action) => {
        state.showOnboardModal=action.data
      })
      .addCase('SET_ABOUT_ME_IS_SAVING', (state, action) => {
        state.aboutMeIsSaving=action.data
      })
      .addCase('SET_PRICE_ME_IS_SAVING', (state, action) => {
        state.priceIsSaving=action.data
      })
      .addCase('MY PROFILE_FETCHING', (state, action) => {
        state.myProfileFetching=action.data
      })
      .addCase('SET_PASSWORD_FETCHING', (state, action) => {
        state.passwordFetching=action.data
      })
      .addCase('SET_PAYOUT_DATA_FETCHING', (state, action) => {
        state.payoutDataFetching=action.data
      })
      .addCase('SET_VERIFICATION_PICTURE_FETCHING', (state, action) => {
        state.verificationPictureFetching=action.data
      })
      .addCase('SET_ACCOUNT_VERIFICATION_FETCHING', (state, action) => {
        state.accountVerificationFetching=action.data
      })
      .addCase('SET_DELETE_ACCOUNT_FETCHING', (state, action) => {
        state.deleteAccountFetching=action.data
      })
      
      .addCase('SET_MY PROFILE', (state, action) => {
        state.myProfile=action.data
      })
      .addCase('SHOW_PUSH_NOTS_MODAL', (state, action) => {
        state.showPushNotsModal=action.data
      })
//      .addCase('SET_UNREADED_CHATS_COUNT', (state, action) => {
//        state.unreadedChatsCount=action.data
//      })
      .addCase('SET_UNREADED_NOTS_COUNT', (state, action) => {
        state.unreadedNotsCount=action.data
      })
      .addCase('SET_PAYOUT_DATA', (state, action) => {
        state.payoutData= action.data
      })
      .addCase('RESET_MY PROFILE', (state, action) => {
        state=initialState
      })
      .addCase('SET_BLOGGER_PROFILE', (state, action) => {
        state.bloggerProfile=action.data
      })
      .addCase('RESET_BLOGGER_PROFILE', (state, action) => {
        state.bloggerProfile=false
      })
      .addCase('RESET_ALL', (state, action) => {
      //  console.log('RESET_ALL profile')
        return initialState
      })
  })

export default profile