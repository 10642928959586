import React from 'react';
import { Oval } from 'react-loader-spinner'

function XButton(props) {
    let variant='xBtnPrimary'
    if(props.variant){
        variant=props.variant
    }
    return(

        <span   className={'xBtn '+variant}
                disabled={props.disabled}
                onClick={()=>props.onClick()}
        >
            {props.children}
        </span>
    )
    return (
        
       <button 
            className={'xBtn '+variant}
            disabled={props.disabled}
            onClick={()=>props.onClick()}
       
        >
            {props.children}
       </button>
    );
}

export default XButton;