import React, { Component, Suspense  } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { userLoginFetch } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { injectIntl, FormattedMessage } from 'react-intl'
import { isMobile } from "react-device-detect";
import toast, { Toaster, ToastBar } from 'react-hot-toast';
const StreamerPlayerWrapper =React.lazy(() => import ('../../components/broadcast/streamerPlayerWrapper'));
class StreamerStreaming extends Component {

  constructor(props) {
    super(props)
    this.playerContRef = React.createRef();
  }
  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener("scroll", this.handleScroll);   
  }

  state = {
    playerHolderHeight: window.innerHeight - 70,
    lastScrollPos: undefined,
    connectionState: 'DISCONNECTED'
  }

  handleScroll = (e) => {
    if (!isMobile) {
      // return
    }
    setTimeout(() => {
      var distance = this.playerContRef.current.getBoundingClientRect().top
      // console.log(distance)
      let lastScrollPos = this.state.lastScrollPos
      if (distance === lastScrollPos) {
        if (distance < 45 && distance > -80) {
          this.playerContRef.current.scrollIntoView()
        }
      }
      else {
        this.setState({ lastScrollPos: distance })
      }
    }, 300)

  }

  handleResize = () => {
    this.setState({
      playerHolderHeight: window.innerHeight - 70
    })
  }

  setParentConnectionState = (connectionState) => {
   // console.log('setParentConnectionState', connectionState)
    this.setState({ connectionState: connectionState })
    if (connectionState === 'CONNECTED') {
      this.playerContRef.current.scrollIntoView()
    }
    // this.props.setConnectionState(connectionState)
  }
  render() {
    if (!this.props.loggedIn) {
      return <Redirect to={'/'} />
    }
    return (
      <>
      <Toaster
              toastOptions={{
                duration:1500,
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }}
            />
      {/** 
        <Toaster
        >  
          {(t) => (
            <ToastBar toast={t}
              style={{
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              }}
            >
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                </>
              )}
            </ToastBar>
          )}

        </Toaster>
      */}
        <Container ref={this.playerContRef} fluid
          className="px-0 pb-5 setreamerPlayerMainHolder"
        //  style={{height:this.state.playerHolderHeight}}
        >
          {this.props.myProfile.isVerified === 0 &&
          <Container>
            <Row>
              <Col xs={12} className='mt-5'>
              <h3><FormattedMessage id= "streamer.notVerifiedInfo" /></h3>
              <h3><FormattedMessage id= "streamer.notVerifiedInfo2" /></h3>
              <br></br>
              <Link to={'/my/verification/'}><FormattedMessage id= "streamer.goToVerification" /></Link>
              </Col>
            </Row>
            </Container>
          }
          {this.props.myProfile.isVerified === 1 &&
          <Container>
            <Row>
              <Col xs={12} className='mt-5'>
              <h3><FormattedMessage id= "streamer.pendingVerificationInfo"/></h3>
              </Col>
            </Row>
            </Container>
          }
          {this.props.myProfile.isVerified === 2 &&
          <Suspense fallback={<div>Loading...</div>}>
            <StreamerPlayerWrapper
              role={'host'}
              channelName={this.props.myProfile.uuid}//tu musimy zmienic na BroadcastState.uuid
              setParentStreamerOnline={this.setParentConnectionState}
            />
            </Suspense>
          }
        </Container>
      </>
    )


  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    //  userLoginFetch,
  }, dispatch)
})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    myProfile: state.profile.myProfile,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(StreamerStreaming));
