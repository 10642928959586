import { Host } from '../actions/authActions'

import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
const authtoken = localStorage.getItem("token")
const channelWsMiddleware = createListenerMiddleware()
let socket = null;
let reconnectTimeoutId = null
let connectionAttempts = 1
let leaveChannel = false
let channelName = null
let pingPongTimerId = null
let pong = null;

const checkPong = (listenerApi) => {
    //    console.log('channel checkPong pong', pong)
    let now = Math.round(+new Date() / 1000);
    let pongdifference = now - pong
    // console.log('channel checkPong pongdifference', pongdifference)
    if (!pong || pongdifference > 6) {
        // resetujrmy polaczenie
        //   console.log('no PONG on chatWs, reconnect')
        stopPingPongTimer()
        listenerApi.dispatch({ type: 'CHANNEL_WS_CONNECT', data: channelName });

    }
}
const stopPingPongTimer = () => {
    clearInterval(pingPongTimerId)
    pingPongTimerId = null
    pong = null
}
const startPingPongTimer = (listenerApi) => {
    clearInterval(pingPongTimerId)
    pingPongTimerId = null
    pong = null
    pingPongTimerId = setInterval(() => {
        //  console.log('channel startPingPongTimer setInterval body')
        pong = null
        listenerApi.dispatch({ type: 'CHANNEL_WS_PING' });
        setTimeout(() => {
            checkPong(listenerApi)
        }, 5000)

    }, 10000)
    // listenerApi.dispatch({ type: 'CHAT_WS_PING'});

}

const reconnectTimer = (listenerApi) => {
    if (!navigator.onLine) {
        return
    }
    if (reconnectTimeoutId) {
        clearTimeout(reconnectTimeoutId)
    }
    let timeout = 3000
    if (connectionAttempts >= 5) {
        connectionAttempts = 1
        timeout = 11000
    }
    else {
        connectionAttempts = connectionAttempts + 1
        timeout = 3000
    }
    let state = listenerApi.getState()
    if (state.auth.loggedIn) {
        reconnectTimeoutId = setTimeout(() => {
            listenerApi.dispatch({ type: 'CHANNEL_WS_CONNECT', data: channelName });
        }, timeout)
    }
}

const onOpen = listenerApi => (event) => {
    // console.log('channelWs onOpen')
    connectionAttempts = 1
    if (reconnectTimeoutId) {
        clearTimeout(reconnectTimeoutId)
        reconnectTimeoutId = null
    }
    startPingPongTimer(listenerApi)
    //    console.log('ws channelWsMiddleware websocket open', event.target.url);
};

const onClose = listenerApi => (e) => {
 //  console.log('channelWs onClose', e)
    stopPingPongTimer()
    if (!leaveChannel) {
        reconnectTimer(listenerApi)
    }
    else {
        channelName = null
        leaveChannel = false
    }
};

const onError = listenerApi => (e) => {
    console.log('channelWs onError', e)
    stopPingPongTimer()
    reconnectTimer(listenerApi)
};

const onMessage = listenerApi => (event) => {
    const payload = JSON.parse(event.data);
    //    console.log('channel ws middleware receiving server message', payload);
    switch (payload.command) {
        case 'PONG':
            pong = Math.round(+new Date() / 1000);
            //    console.log('chatWs PONG', pong)
            break;
        case 'CHANNEL_WS_USER_JOINED':
            break;
        case 'CHANNEL_WS_SET_INCOME':
            //payload.data.income
            listenerApi.dispatch({ type: 'SET_CURRENT_INCOME', data: payload.data });
            break;
        case 'BROADCAST_STATUS_UPDATED':
            const event = new CustomEvent("broadcast_status_changed", { detail: payload.data.status });
            document.dispatchEvent(event);
            break;
        case 'CHANNEL_WS_PAUSE_ENABLED':
            document.dispatchEvent(new CustomEvent("broadcast_pause_status", { detail: payload.data }));
            break;

        case 'CHANNEL_WS_PAUSE_DISABLED':
            document.dispatchEvent(new CustomEvent("broadcast_pause_status", { detail: false }));
            break;
        case 'CHANNEL_WS_DESCRIPTION_UPDATED':
            document.dispatchEvent(new CustomEvent("broadcast_description_updated", { detail: payload.data }));
            break;
        case 'CHANNEL_WS_GOAL_UPDATED':
            //console.log('jest CHANNEL_WS_GOAL_UPDATED', payload.data)
            document.dispatchEvent(new CustomEvent("broadcast_goal_updated", { detail: payload.data }));
            break;
        case 'CHANNEL_WS_CAMERA_TRACKS_UPDATED':
            document.dispatchEvent(new CustomEvent("broadcast_camera_tracks_updated", { detail: payload.data }));
            break;
        case 'CHANNEL_WS_CAMERA_TRACKS_DELETED':
            document.dispatchEvent(new CustomEvent("broadcast_camera_tracks_deleted", { detail: payload.data }));
            break;
        case 'CHANNEL_WS_HOST_LEFT_WS_CHANNEL':
            document.dispatchEvent(new CustomEvent("broadcast_host_left_ws_channel"));
            break;

    }
}



channelWsMiddleware.startListening({
    actionCreator: 'channelWsMiddleware',
    effect: async (action, listenerApi) => {
        let state = listenerApi.getState()
        let payload;
        switch (action.type) {
            case 'CHANNEL_WS_PING':
                payload = {
                    'command': 'PING',
                    'data': {}
                }
                socket.send(JSON.stringify(payload));
                break;
            case 'CHANNEL_WS_DISCONNECT':
                leaveChannel = true
                channelName = null
                if (socket) {
                    socket.close();
                    socket = null;
                }
                break;
            case 'CHANNEL_WS_CONNECT':
                if (socket) {
                    socket.close();

                }
                setTimeout(() => {
                    socket = null
                    if (state.profile.myProfile && state.auth.loggedIn) {
                        channelName = action.data
                      //  console.log('CHANNEL_WS_CONNECT', channelName)
                        let wsProtocol = 'wss'
                        if (window.location.protocol === 'http:') {
                            wsProtocol = 'ws'
                        }
                        let host = Host + '/ws/channel/?token=' + state.auth.token + '&channelName=' + channelName
                        socket = new WebSocket(host);
                        socket.onopen = onOpen(listenerApi);
                        socket.onclose = onClose(listenerApi);
                        socket.onmessage = onMessage(listenerApi);
                        socket.onerror = onError(listenerApi);
                    }
                }, 300)
                break;
            case 'CONNECTION_STATE_CHANGE':
                //  if (action.data === 'offline') {
                //        if (socket) {
                //            socket.close();
                //            socket = null
                //       }
                //    }
                //     if (action.data === 'online') {
                //        clearTimeout(reconnectTimeoutId)
                //         listenerApi.dispatch({ type: 'CHANNEL_WS_CONNECT' })
                //     }
                break;
            case 'CHANNEL_WS_GET_INCOME':
                //TYLKO JEZELI HOST!!! jAK ROZWIAZAC?
                //WYSYLAC TYLKO ZE streamerPlayera
                payload = {
                    'command': 'CHANNEL_WS_GET_BROADCAST_INCOME',
                    'data': {
                        //   'broadcastId': action.data //NIE POTRZEBNE JEZELI W REDUCERZE BEDZIEMY POBIERAC DANE NA POSTWAIE STREAM.LAST()
                    }
                }
                if (socket && socket.readyState === 1) {
                    socket.send(JSON.stringify(payload))
                }
                break;
            case 'CHANNEL_WS_CHECK_BROADCAST_ONLINE':
                payload = {
                    'command': 'CHANNEL_WS_CHECK_BROADCAST_ONLINE',
                    'data': {
                        'channelName': action.data
                    }
                }
                socket.send(JSON.stringify(payload))
                break;
            case 'CHANNEL_WS_VIDEO_PROFILE_UPDATED':
                payload = {
                    'command': 'CHANNEL_WS_VIDEO_PROFILE_UPDATED',
                    'data': {}
                }
            case 'CHANNEL_WS_UPDATE_BROADCAST_STATUS':
                //console.log('jest CHANNEL_WS_UPDATE_BROADCAST_STATUS',  action.data )
                payload = {
                    'command': 'CHANNEL_WS_UPDATE_BROADCAST_STATUS',
                    'data': { 'status': action.data }
                }
                socket.send(JSON.stringify(payload))
                break;
            case 'CHANNEL_WS_PAUSE_ENABLED':
                //  console.log('jest CHANNEL_WS_UPDATE_BROADCAST_STATUS')
                payload = {
                    'command': 'CHANNEL_WS_PAUSE_ENABLED',
                    'data': {}
                }
                socket.send(JSON.stringify(payload))
                break;

            case 'CHANNEL_WS_PAUSE_DISABLED':
                //  console.log('jest CHANNEL_WS_UPDATE_BROADCAST_STATUS')
                payload = {
                    'command': 'CHANNEL_WS_PAUSE_DISABLED',
                    'data': {}
                }
                socket.send(JSON.stringify(payload))
                break;
            case 'CHANNEL_WS_DESCRIPTION_UPDATED':
                payload = {
                    'command': 'CHANNEL_WS_DESCRIPTION_UPDATED',
                    'data': action.data
                }
                socket.send(JSON.stringify(payload))
                break;
            case 'CHANNEL_WS_CAMERA_TRACKS_UPDATED':
             //   console.log('wysylamy CHANNEL_WS_CAMERA_TRACKS_UPDATED')
                payload = {
                    'command': 'CHANNEL_WS_CAMERA_TRACKS_UPDATED',
                    'data': {}
                }
                socket.send(JSON.stringify(payload))
                break;
            case 'CHANNEL_WS_CAMERA_TRACKS_DELETED':
                payload = {
                    'command': 'CHANNEL_WS_CAMERA_TRACKS_DELETED',
                    'data': action.data
                }
                socket.send(JSON.stringify(payload))
                break;

        }
    }

})

export default channelWsMiddleware