import { createReducer } from '@reduxjs/toolkit'
const scrollChatEvent = new Event('scrollChat');
const initialState={
    chatData:[],
    presenceCount:0,
    fontSize:14

}

const broadcast = createReducer(initialState, (builder) => {
    builder
    .addCase('SET_CHAT_FONT_SIZE', (state, action) => {
      state.fontSize=action.data
    })
    .addCase('ADD_CHAT_MESSAGE', (state, action) => {
     //   console.log('reducer ADD_CHAT_MESSAGE', action.data)
        let chatData=JSON.parse(JSON.stringify(state.chatData))
        chatData.push(action.data)
      state.chatData=chatData
      setTimeout(()=>{
        window.dispatchEvent(scrollChatEvent);
      }, 300)
    })
    .addCase('SET_PRESENCE_COUNT', (state, action) => {
        state.presenceCount=action.data
    })
    .addCase('RESET_CHAT_DATA', (state, action) => {
      state.chatData=[]
    })
  })

export default broadcast