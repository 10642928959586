import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { getBalance } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsArrowLeftShort, } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { injectIntl, FormattedMessage } from 'react-intl'
import money from '../../static/images/svg/money.svg'

class EarningsView extends Component {


    componentDidMount = () => {
        this.props.getBalance(this.balanceFetched)
    }


    state = {

    }
    balanceFetched = (data) => {
        //  console.log('balanceFetched', data)
        this.setState({ balanceData: data })
    }
    renderStreamsListItem = (item, index) => {
        return (
            <tr key={'lItem' + index}>
                <td>
                    {item.created}
                </td>
                <td className='text-center'>
                    {item.duration} Min
                </td>
                <td >
                    <h4>{item.balance / 10} &euro;</h4>
                </td>
            </tr>
        )
    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }
        return (
            <div class="space-pt overflow-hidden h-70 myEarnings">
                <Container  >
                    <Row >
                        <Col md={12} className='pt-4' >
                            <div class="section-title">
                                <img src={money} alt="#" height={45} /> <h2 className="title"> <FormattedMessage id="earnings.myEarnings" /></h2>
                            </div>
                        </Col>
                        <Col xs={12} className=' mb-4 '>
                            <Link to={'/'}><BsArrowLeftShort /><FormattedMessage id="common.backToUserPanel" /></Link>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12} className='pt-0' >
                            <h2 className="title"><FormattedMessage id="earnings.balance" />: {this.state.balanceData && this.state.balanceData.euro} &euro;</h2>
                        </Col>
                    </Row>


                    <>
                        <Row >
                            <Col md={12} className='pt-5' >
                                <h3 className="title"><FormattedMessage id="earnings.details" />:</h3>
                            </Col>
                        </Row>
                        {this.state.balanceData && this.state.balanceData.streams.length === 0 &&
                            <Row >
                                <Col md={6} className='pt-2 balanceDetails' >
                                    <FormattedMessage id="earnings.noStreams" />
                                </Col>
                            </Row>

                        }
                        {this.state.balanceData && this.state.balanceData.streams.length > 0 &&
                            <Row >
                                <Col md={6} className='pt-2 balanceDetails' >
                                    <FormattedMessage id="earnings.listOfStreamsIncluded" />
                                    <Table striped bordered hover variant="dark" className='mt-3 '>
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id="common.started" /></th>
                                                <th className='text-center'><FormattedMessage id="earnings.duration" /></th>
                                                <th><FormattedMessage id="earnings.listBalance" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.balanceData && this.state.balanceData.streams.length > 0 && this.state.balanceData.streams.map((item, index) => {
                                                return this.renderStreamsListItem(item, index)
                                            })}
                                        </tbody>



                                    </Table>
                                </Col>
                            </Row>
                        }
                    </>

                </Container>
            </div>

        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getBalance,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(EarningsView));
