import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLoginFetch, register, passwordReset } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FormGroup from 'react-bootstrap/FormGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { ThreeDots } from 'react-loader-spinner'
import XButton from '../../components/xButton'
//import { parseParams } from '../../actions/helpers'
import innerBanner1 from '../../static/images/bg/innerBanner1.jpg'
import { FormattedMessage, injectIntl } from 'react-intl'
import ReactGA from "react-ga4";

class LoginRegister extends Component {


  componentDidMount = () => {
    ///  console.log('this.props', this.props)
    document.title = 'Chillistream - login / register'
    if (this.props.location.hash === '#reg') {
      this.setState({ activeTab: 'register' })
    }
    //   console.log('this.props.location.search', this.props.location.search)
    const urlParams = new URLSearchParams(this.props.location.search);
    let nextUrl = urlParams.get('next')
    if (nextUrl) {
   //   console.log('componentDidMount jest nextUrl', nextUrl)
      this.setState({ nextUrl: nextUrl }, () => {
    //    console.log(' componentDidMountjest nextUrl', this.state)
      })
    }


    this.scrollToTop()
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  state = {
    email: "",
    password: "",
    password1: "",
    password2: "",
    termsAccepted: false,
    errors: {},
    registerErors: [],
    registerSuccess: false,
    passReseErrors: [],
    passResetSuccess: false,
    fetching: false,
    showPassword: false,
    redirect: null,
    activeTab: 'login'
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = () => {
    this.setState({ errors: [], fetching: true })
    this.props.userLoginFetch(this.state, this.formErrors)
  }

  formErrors = (data) => {
    console.log('formErrors', data)
    if (!data.error) {
      if (this.state.nextUrl) {
        this.setState({ redirect: '/' + this.state.nextUrl + '/' })
        return
      }
    }
    else{
      this.setState({ errors: data.data, fetching: false })
    }
    
  }

  handleCheckboxChange = event => {
    // console.log('event', event)
    this.setState({ [event.target.name]: event.target.checked })
  }

  handleRegisterSubmit = () => {
    this.setState({ registerErors: [], fetching: true })
    this.props.register(this.state, this.registerSubmitted)
  }

  registerSubmitted = (data) => {
    //  console.log('registerSubmitted', data)
    this.setState({ fetching: false })
    if (!data.error) {
      this.setState({ registerSuccess: true })
      ReactGA.event("sign_up")

    }
    else {
      //   console.log('error', data)
      this.setState({ registerErors: data.data })
    }

  }

  handlePassResetSubmit = event => {
    this.setState({ errors: false, fetching: true })
    this.props.passwordReset(this.state, this.handlePassResetSubmitted)
  }

  handlePassResetSubmitted = (data) => {
    //   console.log('submitted', data)
    if (!data.error) {
      this.setState({ passResetSuccess: true, fetching: false })

    }
    else {
      this.setState({ fetching: false, passReseErrors: data.data })
    }
  }

  render() {
    // console.log('jest nextUrl w render ' + this.state.nextUrl)
    if(this.state.redirect){
      return <Redirect to={this.state.redirect} />
    }
    if (this.props.loggedIn) {
  //    console.log('loggedIn nextUrl:' + this.state.nextUrl)
      if (!this.state.nextUrl) {
        //  console.log('brak nextUrl w redirect - brak')
        return <Redirect to={'/'} />
      }
      else {
        //   console.log('jest nextUrl redirect to' + '/' + this.state.nextUrl + '/')
        return <Redirect to={'/' + this.state.nextUrl + '/'} />
      }


    }
    return (
      <>
        <div className="inner-banner bg-holder bg-overlay-secondary-3" style={{ backgroundImage: "url(" + innerBanner1 + ")" }}>
          <Container className="position-relative">
            <Row >
              <Col md={12}>
                <div className="header-inner-title">
                  <h2 className="title"><FormattedMessage id="loginRegister.login" /></h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="space-ptb">
          <Container className=" position-relative">
            <Row className="justify-content-center">
              <Col lg={12} xxl={7} className="">
                <div className="section-title text-center">
                  <h2 className="title">
                    <FormattedMessage id="loginRegister.WelcomeToChilli" />
                  </h2>
                </div>
                <div className="bg-dark login-tabs tabs">
                  <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li className="nav-item" onClick={() => this.setState({ errors: [], registerErors: [], email: '', activeTab: 'login' })}>
                      <a className={this.state.activeTab === 'login' ? "nav-link active" : "nav-link"} id="login-tab" data-bs-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="false"><FormattedMessage id="loginRegister.login" /></a>
                    </li>
                    <li className="nav-item" onClick={() => this.setState({ errors: [], registerErors: [], email: '', activeTab: 'register' })}>
                      <a className={this.state.activeTab === 'register' ? "nav-link active" : "nav-link"} id="register-tab" data-bs-toggle="tab" href="#register" role="tab" aria-controls="register" aria-selected="true"><FormattedMessage id="loginRegister.register" /></a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className={this.state.activeTab === 'login' ? "tab-pane fade show active" : "tab-pane fade"} id="login" role="tabpanel" aria-labelledby="login-tab">
                      <Row className="row mt-4 mb-4 mb-sm-5 align-items-center form-flat-style">
                        <Col sm={12} className="mb-3 ">
                          <label className="form-label">Email:</label>
                          <Form.Control
                            id="emailInput"
                            type="email"
                            value={this.state.email}
                            name={'email'}
                            placeholder=""
                            isInvalid={this.state.errors.email}
                            onChange={this.handleChange}
                            disabled={this.state.fetching}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.errors.email && this.state.errors.email.map((item, index) => {
                              return <div key={'emailErrFbop' + index}>{item}</div>
                            })}
                          </Form.Control.Feedback>
                        </Col>
                        <Col sm={12} className="mb-3">
                          <label className="form-label"><FormattedMessage id="loginRegister.password" />:</label>
                          <Form.Control
                            id="password1Input"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.password}
                            name={'password'}
                            placeholder={''}
                            isInvalid={this.state.errors.password}
                            onChange={this.handleChange}
                            disabled={this.state.fetching}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.errors.password && this.state.errors.password.map((item, index) => {
                              return <div key={'pass1ErrFbop' + index}>{item}</div>
                            })}
                          </Form.Control.Feedback>
                        </Col>

                        <Col xs={12} className="text-danger mb-2" >
                          <small>
                            {this.state.errors && this.state.errors.non_field_errors &&
                              this.state.errors.non_field_errors.map((item, index) => {
                                return <div key={'erra' + index}>{item}</div>
                              })
                            }
                          </small>
                        </Col>
                        <Col sm={6} >
                          <Button onClick={() => { this.handleSubmit(); }} variant={'primary'}><FormattedMessage id="loginRegister.login" /> {this.state.fetching && <Spinner animation="border" size="sm" />}</Button>
                        </Col>
                        <Col sm={6} >
                          <ul className="list-unstyled d-flex justify-content-sm-end mb-0 mt-md-0 mt-3">
                            <li><FormattedMessage id="loginRegister.dontHaveAccount" /> <a href="javascript:;" onClick={() => { this.setState({ activeTab: 'register' }) }}><FormattedMessage id="loginRegister.clickHereToRegister" /></a></li>
                          </ul>
                          <ul className="list-unstyled d-flex justify-content-sm-end mb-0 mt-md-0 mt-3 pt-2">
                            <li><FormattedMessage id="loginRegister.forgotPassword" /> <a href="javascript:;" onClick={() => { this.setState({ activeTab: 'passRecovery' }) }}><FormattedMessage id="loginRegister.recoverPassword" /> </a></li>
                          </ul>
                        </Col>
                      </Row>
                      {/*  
                      <div className="login-social-media bg-secondary">
                        <div className="mb-4 d-block text-center"><b>Login or Sign in with</b></div>
                        <Row >
                          <Col md={6}>
                            <a className="btn btn-dark facebook-bg social-bg-hover d-block mb-3" href="#"><span><i className="fab fa-facebook-f me-2"></i>Login with Facebook</span></a>
                          </Col>
                          <Col md={6}>
                            <a className="btn btn-dark twitter-bg social-bg-hover d-block mb-3" href="#"><span><i className="fab fa-twitter me-2"></i>Login with Twitter</span></a>
                          </Col>
                          <Col md={6}>
                            <a className="btn btn-dark instagram-bg social-bg-hover d-block mb-3 mb-md-0" href="#"><span><i className="fab fa-instagram me-2"></i>Login with Instagram</span></a>
                          </Col>
                          <Col md={6}>
                            <a className="btn btn-dark linkedin-bg social-bg-hover d-block" href="#"><span><i className="fab fa-linkedin-in me-2"></i>Login with Linkedin</span></a>
                          </Col>
                        </Row>
                      </div> 
                      */}
                    </div>

                    <div className={this.state.activeTab === 'register' ? "tab-pane fade show active" : "tab-pane fade"} id="register" role="tabpanel" aria-labelledby="register-tab">
                      {!this.state.registerSuccess &&
                        <>
                          <Row className="row mt-4 mb-4 mb-sm-5 align-items-center form-flat-style">
                            <Col md={12} className=" mb-3">
                              <label className="form-label">Email:</label>
                              <Form.Control
                                id="emailInput"
                                type="email"
                                value={this.state.email}
                                name={'email'}
                                placeholder=""
                                isInvalid={this.state.registerErors.email}
                                onChange={this.handleChange}
                                disabled={this.state.fetching}
                              />
                              <Form.Control.Feedback type="invalid">
                                <small>
                                  {this.state.registerErors.email && this.state.registerErors.email.map((item, index) => {
                                    return <div key={'passemailErrFbop' + index}>{item}</div>
                                  })}
                                </small>
                              </Form.Control.Feedback>
                            </Col>
                            <Col md={6} className=" mb-3">
                              <label className="form-label"><FormattedMessage id="loginRegister.password" />:</label>
                              <Form.Control
                                id="password1Input"
                                type={this.state.showPassword ? "text" : "password"}
                                value={this.state.password1}
                                name={'password1'}
                                placeholder=""
                                isInvalid={this.state.registerErors.password1}
                                onChange={this.handleChange}
                                disabled={this.state.fetching}
                              />
                              <Form.Control.Feedback type="invalid">
                                <small>
                                  {this.state.registerErors.password1 && this.state.registerErors.password1.map((item, index) => {
                                    return <div key={'pass1ErrFbop' + index}>{item}</div>
                                  })}

                                </small>
                              </Form.Control.Feedback>
                            </Col>
                            <Col md={6} className="mb-3">
                              <label className="form-label"><FormattedMessage id="loginRegister.confirmPassword" />:</label>
                              <Form.Control
                                id="password2Input"
                                type={this.state.showPassword ? "text" : "password"}
                                value={this.state.password2}
                                name={'password2'}
                                placeholder=''
                                isInvalid={(this.state.registerErors.password2 || this.state.registerErors.non_field_errors)}
                                onChange={this.handleChange}
                                disabled={this.state.fetching}
                              />
                              <Form.Control.Feedback type="invalid">
                                <small>
                                  {this.state.registerErors.password2 && this.state.registerErors.password2.map((item, index) => {
                                    return <div key={'pass2ErrFbop' + index}>{item}</div>
                                  })}
                                  {this.state.registerErors.non_field_errors && this.state.registerErors.non_field_errors.map((item, index) => {
                                    return <div key={'pass2NfErrFbop' + index}>{item}</div>
                                  })}
                                </small>
                              </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} className="mb-3">
                              <Form.Check
                                custom
                                required
                                id="termsAcceptedChkbx"
                                inline
                                type={'checkbox'}
                                name='termsAccepted'
                                checked={this.state.termsAccepted}
                                onChange={this.handleCheckboxChange}
                                disabled={this.state.fetching}
                                isInvalid={this.state.registerErors.termsAccepted}
                                label={<span>{this.props.intl.formatMessage({ id: "auth.iAgreeTo" })} <Link to={'/terms'} target="_blank" >{this.props.intl.formatMessage({ id: "auth.termAndConditions" })}</Link> {this.props.intl.formatMessage({ id: "auth.and" })} <Link to={'/privacy'} target="_blank" >{this.props.intl.formatMessage({ id: "auth.privacyPolicy" })}</Link> {this.props.intl.formatMessage({ id: "auth.ofService" })}</span>}

                              />
                            </Col>



                            <Col md={12} className='text-danger mb-3'>
                              <small>
                                {this.state.registerErors.termsAccepted && this.state.registerErors.termsAccepted.map((item, index) => {
                                  return <div key={'regulaminErr' + index}>{item}</div>
                                })}
                              </small>
                            </Col>
                            <Col md={6} >
                              <Button onClick={() => { this.handleRegisterSubmit() }} variant={'primary'}><FormattedMessage id="loginRegister.register" />  {this.state.fetching && <Spinner animation="border" size="sm" />}</Button>
                            </Col>
                            <Col md={6} className="mb-3">
                              <ul className="list-unstyled d-flex justify-content-md-end mb-0 mt-3">
                                <li><FormattedMessage id="loginRegister.alreadyRegisteredUser" />  <a href="javascript:;" onClick={() => { this.setState({ activeTab: 'login' }) }}><FormattedMessage id="loginRegister.clickHereToLogin" /> </a></li>
                              </ul>
                            </Col>
                          </Row>
                          {/*                         
                          <div className="login-social-media bg-secondary"> 
                            <div className="mb-4 d-block text-center"><b>Login or Sign in with</b></div>
                            <form className="row">
                              <div className="col-md-6">
                                <a className="btn btn-dark facebook-bg social-bg-hover d-block mb-3" href="#"><span><i className="fab fa-facebook-f me-2"></i>Login with Facebook</span></a>
                              </div>
                              <div className="col-md-6">
                                <a className="btn btn-dark twitter-bg social-bg-hover d-block mb-3" href="#"><span><i className="fab fa-twitter me-2"></i>Login with Twitter</span></a>
                              </div>
                              <div className="col-md-6">
                                <a className="btn btn-dark instagram-bg social-bg-hover d-block mb-3 mb-md-0" href="#"><span><i className="fab fa-instagram me-2"></i>Login with Instagram</span></a>
                              </div>
                              <div className="col-md-6">
                                <a className="btn btn-dark linkedin-bg social-bg-hover d-block" href="#"><span><i className="fab fa-linkedin-in me-2"></i>Login with Linkedin</span></a>
                              </div>
                            </form>
                          </div>
                          */}
                        </>
                      }
                      {this.state.registerSuccess &&
                        <Col md={12} className=" mt-3 mb-3">
                          <div className="section-title text-center">
                            <h2 className="title">Success</h2>
                          </div>
                          <FormattedMessage id="loginRegister.registerSuccessInfo" />
                        </Col>
                      }
                    </div>
                    <div className={this.state.activeTab === 'passRecovery' ? "tab-pane fade show active" : "tab-pane fade"} id="passRecovery" role="tabpanel" aria-labelledby="passRecovery-tab">
                      <Row className="row mt-4 mb-4 mb-sm-5 align-items-center form-flat-style">
                        <Col md={12} className=" mt-3 mb-3">
                          <div className="section-title text-center text-center">
                            <h2 className="title"><FormattedMessage id="loginRegister.recoverYourPassword" /></h2>
                          </div>
                          {this.state.passResetSuccess &&
                            <span><FormattedMessage id="loginRegister.recoverSuccessInfo" /></span>
                          }

                        </Col>
                        {!this.state.passResetSuccess &&
                          <>
                            <Col md={12} className=" mb-3">
                              <label className="form-label">Email:</label>
                              <Form.Control
                                id="emailInput"
                                type="email"
                                value={this.state.email}
                                name={'email'}
                                placeholder=""
                                isInvalid={(this.state.passReseErrors.email || this.state.passReseErrors.non_field_errors)}
                                onChange={this.handleChange}
                                disabled={this.state.fetching}
                              />
                              <Form.Control.Feedback type="invalid">
                                <small>
                                  {this.state.passReseErrors.email && this.state.passReseErrors.email.map((item, index) => {
                                    return <div key={'passemailErrFbop' + index}>{item}</div>
                                  })}
                                  {this.state.passReseErrors.non_field_errors && this.state.passReseErrors.non_field_errors.map((item, index) => {
                                    return <div key={'pass2NfErrFbop' + index}>{item}</div>
                                  })}
                                </small>
                              </Form.Control.Feedback>
                            </Col>
                            <Col md={6} >
                              <Button onClick={() => { this.handlePassResetSubmit() }} variant={'primary'}><FormattedMessage id="loginRegister.recover" /> {this.state.fetching && <Spinner animation="border" size="sm" />}</Button>
                            </Col>
                          </>
                        }
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )



  }
}

const mapDispatchToProps = dispatch => ({
  // userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn
  };
}
export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  { userLoginFetch, register, passwordReset }
)(injectIntl(LoginRegister));
