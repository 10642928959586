import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteAccountConfirm} from "../../actions/index";
import {Redirect} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import {RiLockPasswordLine} from 'react-icons/ri'
import {BsFillEyeSlashFill, BsFillEyeFill} from 'react-icons/bs'
import {Oval} from 'react-loader-spinner'
import {injectIntl} from 'react-intl'

import {Link} from 'react-router-dom'

class AccountDeleteConfirm extends Component {

  componentDidMount=()=>{
    //let uid=this.props.match.params.uid
    let token=this.props.match.params.token
    this.setState({token:token}, ()=>{
     // this.props.accountDeleteConfirm(this.state, this.submitted)
    })

  }

  state = {
    errors:false,
    fetching:false,
    success:false,
    token:null,
    submited:false,
  }



  submit=()=>{
    //console.log('submit')
    this.setState({fetching:true, submited:true})
    const fd=new FormData()
    fd.append('token', this.state.token)
    this.props.deleteAccountConfirm(fd, this.submitted)
  }
  submitted=(data)=>{
   // console.log('submitted', data)
    this.setState({fetching:false})
    if(!data.error){
      this.setState({success:true})
    }
    else{
   //   console.log('error', data)
      this.setState({errors:data.data.message})
    }
    
  }
  render() {
    if (this.state.redirect) {
      return <Redirect  to={this.state.redirect} />
    }
    return(
      <Container  style={{backgroundColor:'#fff', minHeight:'100vh'}} className="px-0">
              <Row noGutters>
                <Col xs={12} className='p-2 p-md-5 pt-5 text-center fadeIn'>
                    <Row noGutters>
                        <Col xs={12} className='border p-2 p-md-3 text-center pb-5'>
                            <Row noGutters>
                              <Col xs={12} className=' p-3 text-center h3'>
                                  Usunięcie konta
                                <hr></hr>
                              </Col>
                              {!this.state.submited&&
                                <>
                                  <Col xs={12} className=' pt-3 text-center  upperCase'>
                                      Usunięcie konta oznacza nieodwracalne usunięcie wszystkich Twoich danych z systemu.
                                  </Col>
                                  <Col xs={12} className=' pt-2 pb-2 text-center header-2 upperCase'>
                                    Czy napewno chcesz to zrobić?
                                  </Col>
                                  <Col xs={12} className=' pt-2 pb-2 text-center  upperCase'>
                                    <Button onClick={()=>this.setState({redirect:'/'})}   className=" upperCase">
                                      Nie, przenieś mnie na główną stronę serwisu
                                    </Button>
                                  </Col>
                                  <Col xs={12} className=' pt-2 pb-2 text-center  upperCase'>
                                    <Button onClick={()=>this.submit()}  variant={'danger'} className=" upperCase">
                                      Tak, usuń moje konto
                                    </Button>
                                  </Col>
                                </>
                              }
                              {this.state.fetching&&
                                <Col xs={12} className=' py-5 text-center'>
                                  <Oval  color="rgba(244, 67, 54, 0.5)" height={80} width={80} />
                                </Col>
                              }
                              {this.state.success&&
                                  <Col xs={12} className='text-center upperCase fadeIn pb-3'>
                                    Twoje konto zostało usunięte.
                                  </Col>
                              }
                              

                              {this.state.errors&&
                                <Col xs={12} className='pt-3 text-center text-danger'>
                                      <p>{this.state.errors}</p>
                                </Col>
                              }
                            </Row>
                        </Col>  
                    </Row>
                </Col>
              </Row>
      </Container>
    )
  }
} 

//const mapDispatchToProps = dispatch => ({
 // userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
//})
function mapStateToProps(state) {
    return {
     loggedIn: state.auth.token
    };
  }
export default connect(
    mapStateToProps,
    {deleteAccountConfirm, }
  )(injectIntl(AccountDeleteConfirm));
