import {combineReducers} from 'redux'
import profile from './profile'
import auth from './auth'
import locale from './locale'
import broadcast from './broadcast'
import audience from './audience'
import chat from './chat'
//export default notifications
export default combineReducers({
    profile, 
    auth, 
    locale, 
    broadcast,
    audience,
    chat,
})