import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { changeEmailConfirm } from "../../actions/index";
import { Redirect } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { Oval } from 'react-loader-spinner'
import OverlaySpinner from '../../components/overlaySpinner'
import {injectIntl} from 'react-intl'

class EmailChangeConfirm extends Component {


  componentDidMount = () => {
    this.setState({fetching:true})

    let token = this.props.match.params.token
   // console.log('token', token)
    const fd = new FormData()
    fd.append('token', token)
    this.props.changeEmailConfirm(fd, this.tokenFetched)
    //wrzucamy token na serwer i sprawdzamy czy sie zgadza

  }
  state = {
    password: '',
    passFormErrors: {},
    passInputType: 'password'

  }
  tokenFetched = (data) => {
    this.setState({fetching:false})
    if (data.error) {
      this.setState({ error: data.data.message })
    }
    else {
      this.setState({ tokenData: data })
    }
  }

  confirm = () => {
    this.setState({fetching:true})
    this.setState({ passFormErrors: [] })
    const fd = new FormData()
    fd.append('token', this.state.tokenData.token)
    fd.append('password', this.state.password)
    this.props.changeEmailConfirm(fd, this.confirmed)
  }
  confirmed = (data) => {
    this.setState({fetching:false})
    if (data.error) {
      if (data.data.password) {
        this.setState({ passFormErrors: data.data })
      }
    }
    else {
      this.setState({ success: true })
    }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <Container fluid style={{ backgroundColor: '#fff', minHeight: '100vh' }} className="px-0">
        <Container className="px-2 px-md-0 pb-4">
          <Row noGutters>
            <Col xs={12} md={6} className='py-2 mx-auto h3 text-center'>
              Potwierdzenie zmiany hasła:
            </Col>
          </Row>
          {this.state.success &&
            <Row noGutters>
              <Col xs={12} md={6} className='text-center text-success mx-auto py-2'>
                Adres email został zmieniony.
              </Col>
            </Row>
          }
          {this.state.tokenData && !this.state.success &&
            <Row noGutters>
              <Col xs={12} md={6} className='text-center mx-auto py-2'>
                <Row noGutters>
                  <Col xs={12} className='text-center '>
                    <p>Aby potwierdzic zmianę adresu na <br></br>
                      <span className='h6 '>{this.state.tokenData.email}</span>, podaj hasło do swojego konta.

                    </p>
                  </Col>

                  <Col xs={12} className='my-2'>
                    <FloatingLabel
                      //    controlId="oldPassInput"
                      label="Hasło do konta"
                      className="mb-3"
                      isInvalid={this.state.passFormErrors.old_password}
                    >
                      <Form.Control
                        id="pass"
                        type={this.state.passInputType}
                        value={this.state.password}
                        name={'password'}
                        isInvalid={this.state.passFormErrors.password}
                        placeholder="Hasło do konta"
                        onChange={(e) => this.setState({ password: e.target.value })}
                        disabled={this.state.fetching}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.passFormErrors.password && this.state.passFormErrors.password.map((item, index) => {
                          return <div key={'passErrFbop' + index}>{item}</div>
                        })}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <Button onClick={() => { this.confirm() }}  disabled={this.state.fetching}>
                      Potwierdź
                    </Button>
                  </Col>
                </Row>

              </Col>
            </Row>
          }
          {this.state.error &&
            <Row noGutters>
              <Col xs={12} md={6} className='text-center text-danger py-3 mx-auto '>
                {this.state.error}
              </Col>
            </Row>
          }

        </Container>
        <Row noGutters>
          {this.state.fetching &&
            <OverlaySpinner />
          }
        </Row>
      </Container>
    )


  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({
    changeEmailConfirm,
    //  userLoginFetch,
  }, dispatch)
})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EmailChangeConfirm));
