import { axiosClient, ApiUrl, HostUrl, upssssText } from './authActions'

export function toggleFavorites(displayName, callback) {
 //   console.log('toggleFavorites', displayName)
    let path = ApiUrl + "favorites/" + displayName + "/"
    return function (dispatch) {
      axiosClient.patch(path)
        .then((response) => {
         // console.log('toggleFavorites', response.data)
          callback(response.data)
        })
        .catch((error) => {
          // handle error
       //   console.log('toggleFavorites error', error)
      //    console.log('toggleFavorites error.response', error.response)
          if (error.response) {
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              callback(error.response.data)
  
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {
        });
    }
  }
  export function getNotifyMe(displayName, callback) {
      // console.log('getNotifyMe', displayName)
       let path = ApiUrl + "notifyme/" + displayName + "/"
       return function (dispatch) {
         axiosClient.get(path)
           .then((response) => {
          //  console.log('getNotifyMe2', response.data)
             callback(response.data)
           })
           .catch((error) => {
             if (error.response) {
               if (error.response.status === 400) {
                 //   console.log(error.response.data)
                 callback(error.response.data)
               }
               if (error.response.status === 404) {
               }
               if (error.response.status === 403) {
               }
             }
           })
           .then(() => {
           });
       }
     }

  export function toggleNotifyMe(displayName, callback) {
   //    console.log('toggleNotifyMe', displayName, callback)
       let path = ApiUrl + "notifyme/" + displayName + "/"
       return function (dispatch) {
         axiosClient.patch(path)
           .then((response) => {
             callback(response.data)
           })
           .catch((error) => {
             if (error.response) {
               if (error.response.status === 400) {
                 //   console.log(error.response.data)
                 callback(error.response.data)
               }
               if (error.response.status === 404) {
               }
               if (error.response.status === 403) {
               }
             }
           })
           .then(() => {
           });
       }
     }

  export function getFavorites(callback) {
  //  console.log('getFavorites')
    let path = ApiUrl + "favorites/"
    return function (dispatch) {
      axiosClient.get(path)
        .then((response) => {
        //  console.log('getFavorites', response.data)
          callback(response.data)
        })
        .catch((error) => {
          // handle error
       //   console.log('getFavorites error', error)
      //    console.log('getFavorites error.response', error.response)
          if (error.response) {
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              callback(error.response.data)
  
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {
    
        });
    }
  }

  export function getLastVisits(callback) {
 //   console.log('getLastVisits')
    let path = ApiUrl + "lastvisits/"
    return function (dispatch) {
      axiosClient.get(path)
        .then((response) => {
       //   console.log('getLastVisits', response.data)
          callback(response.data)
        })
        .catch((error) => {
          // handle error
        //  console.log('getLastVisits error', error)
       //   console.log('getLastVisits error.response', error.response)
          if (error.response) {
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              callback(error.response.data)
  
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {
    
        });
    }
  }
  