import { axiosClient, ApiUrl, HostUrl, upssssText } from './authActions'


export function getWallet(callback) {
   // console.log('getWallet')
    let path = ApiUrl + "my/wallet/"
    return function (dispatch) {
      axiosClient.get(path)
        .then((response) => {
        //  console.log('getWallet', response.data)
          callback(response.data)
        })
        .catch((error) => {
          // handle error
     //     console.log('getWallet error', error)
      //    console.log('getWallet error.response', error.response)
          if (error.response) {
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              callback(error.response.data)
  
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {
    
        });
    }
  }

  export function getCheckoutData(packetId, callback) {
   // console.log('getCheckoutData')
    let path = ApiUrl + "my/wallet/checkout/"+packetId+"/"
    return function (dispatch) {
      axiosClient.get(path)
        .then((response) => {
      //    console.log('getCheckoutData', response.data)
          callback(response.data)
        })
        .catch((error) => {
          // handle error
       //   console.log('getCheckoutData error', error)
      //   console.log('getCheckoutData error.response', error.response)
          if (error.response) {
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              callback(error.response.data)
  
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {
    
        });
    }
  }

  export function testCheckout(fd, callback) {
 //   console.log('testCheckoutData')
    let path = ApiUrl + "my/wallet/checkout/"
    return function (dispatch) {
      axiosClient.post(path, fd)
        .then((response) => {
       //   console.log('testCheckoutData', response.data)
          dispatch({ type: "SET_MY PROFILE", data: response.data.profile });
          callback(response.data)
        })
        .catch((error) => {
          // handle error
        //  console.log('testCheckoutData error', error)
     //     console.log('testCheckoutData error.response', error.response)
          if (error.response) {
            if (error.response.status === 400) {
              //   console.log(error.response.data)
              callback(error.response.data)
  
            }
            if (error.response.status === 404) {
            }
            if (error.response.status === 403) {
            }
          }
        })
        .then(() => {
    
        });
    }
  }