import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import XButton from '../../components/xButton'
import { connect } from 'react-redux';
import {
  changePassword,
  changeEmailInit,
  checkDisplayName,
  updateProfile,
  updateAvatar,
  deleteAccountInit,
} from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FormGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

import Spinner from 'react-bootstrap/Spinner';

import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { MdOutlineClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import OverlaySpinner from '../../components/overlaySpinner'
import CustomCropper from '../../components/customCropper'
import { ThreeDots } from 'react-loader-spinner'
import { injectIntl } from 'react-intl'

class MyProfileSettings extends Component {
  componentDidMount = () => {
    if (!this.props.loggedIn) {
      this.setState({ redirect: '/' })
    }
    else {
      this.setState({
        displayName: this.props.myProfile.displayName,
        // email: this.props.myProfile.email,


      })
    }
  }


  state = {
    emailFormErrors: [],
    passFormErrors: {},
    showEmailChangeForm: false,
    showPassChangeForm: false,
    showPasswordChangeForm: false,
    email: '',
    passInputType: 'password',
    old_password: '',
    password: '',
    password2: '',
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  resetPasswordForm = () => {
    this.setState({
      old_password: '',
      new_password1: '',
      new_password2: '',
      passFormErrors: {},
      passInputType: 'password',
      showPassCheck: false,
    })
  }

  savePassword = () => {
    this.setState({ passFormErrors: {} })
    // let passFormErrors={}
    let old_passwordErrors = []
    let passwordErrors = []
    let password2Errors = []
    if (this.state.old_password.length === 0) {
      old_passwordErrors.push('pole nie może być puste')
    }
    if (this.state.new_password1.length === 0) {
      passwordErrors.push('pole nie może być puste')
    }
    if (this.state.new_password2.length === 0) {
      password2Errors.push('pole nie może być puste')
    }
    if (this.state.new_password1 != this.state.new_password2) {
      passwordErrors.push('hasła nie są zgodne')
      password2Errors.push('hasła nie są zgodne')
    }
    let passFormErrors = {}
    if (old_passwordErrors.length > 0) {
      passFormErrors.old_password = old_passwordErrors
    }
    if (passwordErrors.length > 0) {
      passFormErrors.new_password1 = passwordErrors
    }
    if (password2Errors.length > 0) {
      passFormErrors.new_password2 = password2Errors
    }
    if (Object.keys(passFormErrors).length !== 0) {
      this.setState({ passFormErrors: passFormErrors })
      return
    }
    const fd = new FormData()
    fd.append('old_password', this.state.old_password)
    fd.append('new_password1', this.state.new_password1)
    fd.append('new_password2', this.state.new_password2)
    this.props.changePassword(fd, this.passwordChanged)
  }
  passwordChanged = (data) => {
    if (data.error) {
      this.setState({ passFormErrors: data.data })
      return
    }
    this.setState({ passwordSuccessMessage: data.detail })
    setTimeout(() => { this.setState({ passwordSuccessMessage: undefined }) }, 3000)
  }

  showPassword = (e) => {
    this.setState({ showPassCheck: e.target.checked })
    if (e.target.checked) {
      this.setState({ passInputType: 'text' })
    }
    else {
      this.setState({ passInputType: 'password' })
    }
  }
  
  resetEmailForm = () => {
    this.setState({ email: '', emailFormErrors: [], emailSuccessMessage: undefined })
  }
  changeEmail = () => {
    this.setState({ emailFormfetching: true, emailFormErrors: [] })
    const fd = new FormData()
    fd.append('email', this.state.email)
    this.props.changeEmailInit(fd, this.emailChanged)
  }

  emailChanged = (data) => {
    this.setState({ emailFormfetching: false })
    if (data.error) {
      this.setState({ emailFormErrors: data.data })
      return
    }
    this.setState({ emailSuccessMessage: data.detail })
    //setTimeout(() => { this.setState({ emailSuccessMessage: undefined }) }, 5000)

  }
  resetDisplayNameForm = () => {

  }
  showForm = (formName) => {
    this.setState({
      showEmailChangeForm: false,
      showPassChangeForm: false,
      showSccountDeleteForm: false
    },
      () => {
        if (formName === 'email') {
          this.resetEmailForm();
          this.setState({ showEmailChangeForm: true });
        }
        else if (formName === 'password') {
          this.resetPasswordForm();
          this.setState({ showPassChangeForm: true })
        }
        else if (formName === 'displayName') {
          this.resetDisplayNameForm();
          this.setState({ showDisplayNameChangeForm: true })
        }
        else if (formName === 'accountDelete') {
          this.resetDisplayNameForm();
          this.setState({ showSccountDeleteForm: true })
        }

      }
    )
  }

  handleDisplayNameChange = (e) => {
    clearTimeout(this.state.displayNameTimoutId)
    this.setState({ displayNameCheckFetching: false, displayNameAvailable: undefined })
    this.setState({ displayName: e.target.value },
      () => {
        if (this.state.displayName.length < 3) {
          return
        }
        if (this.state.displayName === this.props.myProfile.displayName) {
          return
        }
        const timoutId = setTimeout(() => {
          this.setState({ displayNameCheckFetching: true, })
          this.props.checkDisplayName(this.state.displayName, this.displayNameChecked)
        }, 500)
        this.setState({ displayNameTimoutId: timoutId })
      }
    )
  }

  displayNameChecked = (data) => {
    this.setState({ displayNameCheckFetching: false, displayNameAvailable: data.available })
  }
  changeDisplayName = () => {
    this.setState({ displayNameFormFetching: true })
    if (this.state.displayName === this.props.myProfile.displayName) {
      this.setState({ displayNameFormFetching: false })
      return
    }
    const fd = new FormData()
    fd.append('displayName', this.state.displayName)
    this.props.updateProfile(fd, this.displayNameUpdated)
  }
  displayNameUpdated = (data) => {
    this.setState({ displayNameFormFetching: false })
    if (!data.error) {
      this.setState({ displayNameSuccessMessage: true })

    }
  }
  handleAvatarOnChange = (e) => {
    this.setState({ newAvatarEvent: e, showAvatarCropper: true })
  }
  saveAvatar = (data) => {
    this.setState({ avatarSaveFetching: true })
    const fd = new FormData()
    fd.append('avatar', data)
    this.props.updateProfile(fd, this.avatarSaved)
    //upload!
  }
  avatarSaved = (data) => {
    this.setState({ avatarSaveFetching: false, avatarSaveSuccess: true })
  }
  accountDeleteInit = () => {
    this.setState({ accountDeleteFetching: true })
    this.props.deleteAccountInit(this.accountDeleteFetched)
  }
  accountDeleteFetched = (data) => {
  //  console.log('accountDeleteFetched', data)
    this.setState({ accountDeleteFetching: false })
    if (!data.error) {
      this.setState({ accountDeleteSuccessMessage: data.detail })
    }
    //accountDeleteSuccessMessage

  }
  render() {
    if (!this.props.loggedIn) {
      return <Redirect to={'/'} />
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <Container fluid style={{ minHeight: '100vh', }} className="px-2 px-md-0">
        <Container className="px-0 pb-4 bgSurface">
          <Row className='gx-0'>
            <Col xs={12} md={6} className='py-2 mx-auto h3 '>
              {this.props.intl.formatMessage({ id: "profileSettings.myProfile" })}:
            </Col>
          </Row>
          <Row className='gx-0'>
            <Col xs={12} md={6} className='py-2 mx-auto'>
              <Row className='gx-0'>
                <Col xs={12}>
                  <Image src={this.props.myProfile.avatar} roundedCircle height={130} />
                </Col>
                <Col xs={12}>
                  <small className=''>
                    {this.props.intl.formatMessage({ id: "profileSettings.profilePicture" })}:
                  </small><br></br>
                  <label className="text-center" >
                    {this.props.avatarIsSaving ?
                      <Spinner animation="border" size="sm" variant={'light'} className="likeBtnSpinner" /> :
                      <span className='cursor text-danger fakeLink lead' >
                        {this.props.intl.formatMessage({ id: "profileSettings.changePictureBtn" })}
                      </span>
                    }
                    <input id="newPostUploadImageInput"
                      key={this.state.fileInputKey1}
                      disabled={this.props.postEditModalFilesInputFetching ? true : false}
                      //   multiple
                      type="file"
                      accept="image/jpeg, image/png"
                      style={{ display: 'none' }}
                      onChange={(e) => this.handleAvatarOnChange(e)}
                    />
                  </label>

                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='gx-0'>
            <Col xs={12} md={6} className='py-2 mx-auto'>
              <Row className='gx-0'>
                <Col xs={12}>
                  <small className=''>Email:</small><br></br>
                  <span className='bold text-muted'>
                    {this.props.myProfile.email}
                  </span>
                </Col>
                <Col xs={12}>
                  <span className='cursor text-danger fakeLink lead' onClick={() => { this.showForm('email') }}>
                    {this.props.intl.formatMessage({ id: "profileSettings.changeEmailBtn" })}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='gx-0'>
            <Col xs={12} md={6} className='py-2 mx-auto'>
              <Row className='gx-0'>
                <Col xs={12}>
                  <small className=''>
                    {this.props.intl.formatMessage({ id: "profileSettings.displayName" })}:
                  </small><br></br>
                  <span className='bold text-muted'>
                    @{this.props.myProfile.displayName}
                  </span>
                </Col>
                <Col xs={12}>
                  <span className='cursor text-danger fakeLink lead' onClick={() => { this.showForm('displayName') }}>
                    {this.props.intl.formatMessage({ id: "profileSettings.changedisplayNameBtn" })}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='gx-0'>
            <Col xs={12} md={6} className='py-2 mx-auto '>
              <span className='cursor text-danger fakeLink lead' onClick={() => { this.setState({ showPasswordChangeForm: true }) }}>
                {this.props.intl.formatMessage({ id: "profileSettings.changePasswordBtn" })}
              </span>
            </Col>
          </Row>

          <Row className='gx-0'>
            <Col xs={12} md={6} className='py-2 mx-auto border-bottom'>
            </Col>

          </Row>


          {!this.state.accountDeleteSuccessMessage &&
            <Row className='gx-0'>
              <Col xs={12} md={6} className='py-2 mx-auto '>
                <span className='cursor text-danger fakeLink lead' onClick={() => { this.setState({ showSccountDeleteForm: !this.state.showSccountDeleteForm }) }}>
                  {this.props.intl.formatMessage({ id: "profileSettings.deleteAccountBtn" })}
                </span>
              </Col>
            </Row>
          }
          {this.state.accountDeleteSuccessMessage &&
            <Row className='gx-0'>
              <Col xs={12} md={6} className='pb-2 mx-auto '>
                {this.state.accountDeleteSuccessMessage}
              </Col>
            </Row>
          }
          {this.state.showSccountDeleteForm && !this.state.accountDeleteSuccessMessage &&
            <Row className='gx-0'>
              <Col xs={12} md={6} className='pb-2 mx-auto '>
                <Row className='gx-0'>
                  <Col xs={12} className='py-2'>
                    Rozpocznij proces usuwania konta:
                  </Col>
                  <Col xs={12}>
                    {!this.state.accountDeleteFetching &&
                      <Button variant={'danger'} onClick={() => this.accountDeleteInit()}>
                        Usuń konto
                      </Button>

                    }
                    {this.state.accountDeleteFetching &&
                      <ThreeDots
                        height="25"
                        width="50"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        </Container>
        <Modal
          centered
          show={this.state.showEmailChangeForm}
          //onHide={this.handleDiplayNameModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Container className=''>
              <Col xs={12} className=''>
                <Row>
                  <Col xs={'auto'} className='text-muted h4'>
                    ZMIANA EMAIL:
                  </Col>
                  <Col className='text-end'>
                    <MdOutlineClose
                      onClick={() => { !this.state.emailFormfetching && this.setState({ showEmailChangeForm: false }) }}
                      className='closeButton'
                    />
                  </Col>
                </Row>
              </Col>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row className='gx-0'>
                <Col xs={12} className='pt-2 pb-4 mb-4 mx-auto mb-3 '>
                  <Row>

                    {this.state.emailSuccessMessage &&
                      <Col xs={12} className='py-4 px-2 mx-auto  text-center'>
                        {this.state.emailSuccessMessage}
                      </Col>
                    }
                    {!this.state.emailSuccessMessage &&
                      <>
                        <Col xs={12} className='pt-2'>
                          {this.state.emailFormfetching &&
                            <OverlaySpinner />
                          }
                          <FormGroup
                          //validationState={this.getValidationState()}
                          >
                            <FloatingLabel
                              // controlId="emailInput"
                              label="Nowy adres email"
                              className="mb-3"
                              isInvalid={this.state.emailFormErrors.email}
                            >
                              <Form.Control
                                //  id="emailInput"
                                type="email"
                                value={this.state.email}
                                placeholder="email"
                                name={'email'}
                                onChange={this.handleChange}
                                isInvalid={this.state.emailFormErrors.email}
                                disabled={this.state.emailFormfetching}
                              />
                              <Form.Control.Feedback type="invalid">
                                {this.state.emailFormErrors.email && this.state.emailFormErrors.email.map((item, index) => {
                                  return <div key={'emailErr' + index}>{item}</div>
                                })}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                        <Col xs={12} className='pb-2 '>
                          <XButton
                            onClick={() => { this.changeEmail(); }}
                            disabled={this.state.emailFormfetching}
                          >
                            Zapisz
                          </XButton>
                        </Col>
                      </>
                    }
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={this.state.showDisplayNameChangeForm}
          // onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Container className=''>
              <Col xs={12} className=''>
                <Row>
                  <Col xs={'auto'} className='text-muted h4'>
                    ZMIANA PSEUDONIMU:
                  </Col>
                  <Col className='text-end'>
                    <MdOutlineClose
                      onClick={() => {
                        !this.state.displayNameFormFetching && this.setState({
                          displayNameSuccessMessage: undefined,
                          displayName: this.props.myProfile.displayName,
                          showDisplayNameChangeForm: false
                        })
                      }}
                      className='closeButton'
                    />
                  </Col>
                </Row>
              </Col>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {this.state.displayNameFormFetching &&
                <OverlaySpinner />
              }
              {this.state.displayNameSuccessMessage &&
                <Row className='gx-0'>
                  <Col xs={12} className='text-center'>
                    Pseudonim został zmieniony.
                  </Col>
                </Row>
              }
              {!this.state.displayNameSuccessMessage &&
                <Row className='gx-0'>
                  <Col xs={12} className='pt-2 mx-auto  '>
                    <FormGroup
                    //validationState={this.getValidationState()}
                    >
                      <FloatingLabel
                        // controlId="pseudonimInput"
                        label="Pseudonim"
                        className="mb-3"
                      >
                        <Form.Control
                          id="pseudonimInput"
                          type="text"
                          value={this.state.displayName}
                          name={'displayName'}
                          placeholder="Pseudonim"
                          onChange={this.handleDisplayNameChange}
                        />
                      </FloatingLabel>
                    </FormGroup>
                  </Col>
                  <Col xs={12} className='pb-2 '>
                    {this.state.displayNameCheckFetching &&
                      <ThreeDots
                        height="25"
                        width="50"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    }
                    {this.state.displayNameAvailable !== undefined && this.state.displayNameAvailable === true &&
                      <small className='text-success'>Pseudonim dostępny</small>
                    }
                    {this.state.displayNameAvailable !== undefined && this.state.displayNameAvailable === false &&
                      <small className='text-danger'>Pseudonim niedostępny</small>
                    }
                  </Col>

                  <Col xs={12} className='pb-2 '>
                    <XButton
                      onClick={() => { this.changeDisplayName(); }}
                      disabled={!this.state.displayNameAvailable}
                    >
                      Zapisz
                    </XButton>
                  </Col>
                </Row>
              }
            </Container>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={this.state.showAvatarCropper}
          // onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Container className=''>
              <Col xs={12} className=''>
                <Row>
                  <Col xs={'auto'} className='text-muted h4'>
                    ZDJĘCIE PROFILOWE:
                  </Col>
                  <Col className='text-end'>
                    <MdOutlineClose
                      onClick={() => {
                        !this.state.avatarSaveFetching && this.setState({
                          showAvatarCropper: false,
                          newAvatarEvent: undefined,
                          avatarSaveSuccess: undefined
                        })
                      }}
                      className='closeButton'
                    />
                  </Col>
                </Row>
              </Col>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <CustomCropper e={this.state.newAvatarEvent}
              setCroppedData={(data) => this.saveAvatar(data)}
              fetching={this.state.avatarSaveFetching}
              success={this.state.avatarSaveSuccess}
              aspectRatio={1}
            />
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.showPasswordChangeForm}
          onHide={() => { this.resetPasswordForm(); this.setState({ showPasswordChangeForm: false }) }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Container className=''>
              <Col xs={12} className=''>
                <Row>
                  <Col xs={'auto'} className='text-muted h4'>
                    ZMIEŃ HASŁO:
                  </Col>
                  <Col className='text-end'>
                    <MdOutlineClose
                      onClick={() => { !this.state.emailFormfetching && this.setState({ showPasswordChangeForm: false }) }}
                      className='closeButton'
                    />
                  </Col>
                </Row>
              </Col>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Row className='gx-0'>
              <Col xs={12} className='my-2 '>
                <Form.Check
                  type={'checkbox'}
                  label={this.props.intl.formatMessage({ id: "auth.showPasswords" })}
                  onChange={this.showPassword}
                  checked={this.state.showPassCheck}

                />
              </Col>
              <Col xs={12} className='my-2'>
                <FloatingLabel
                  //    controlId="oldPassInput"
                  label={this.props.intl.formatMessage({ id: "profileSettings.oldPassword" })}
                  className="mb-3"
                  isInvalid={this.state.passFormErrors.old_password}
                >
                  <Form.Control
                    id="oldPassInput"
                    type={this.state.passInputType}
                    value={this.state.old_password}
                    name={'old_password'}
                    isInvalid={this.state.passFormErrors.old_password}
                    placeholder={this.props.intl.formatMessage({ id: "profileSettings.oldPassword" })}
                    onChange={this.handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {this.state.passFormErrors.old_password && this.state.passFormErrors.old_password.map((item, index) => {
                      return <div key={'passErrFbop' + index}>{item}</div>
                    })}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12} className='my-2'>
                <FloatingLabel
                  //   controlId="PassInput1"
                  label={this.props.intl.formatMessage({ id: "profileSettings.newPassword" })}
                  className="mb-3"
                  isInvalid={this.state.passFormErrors.password}
                >
                  <Form.Control
                    id="PassInput1"
                    type={this.state.passInputType}
                    value={this.state.new_password1}
                    name={'new_password1'}
                    placeholder={this.props.intl.formatMessage({ id: "profileSettings.newPassword" })}
                    onChange={this.handleChange}
                    isInvalid={this.state.passFormErrors.new_password1}
                  />
                  <Form.Control.Feedback type="invalid">
                    {this.state.passFormErrors.new_password1 && this.state.passFormErrors.new_password1.map((item, index) => {
                      return <div key={'passErrFbp' + index}>{item}</div>
                    })}
                  </Form.Control.Feedback>
                </FloatingLabel>

              </Col>
              <Col xs={12} className='my-2'>
                <FloatingLabel
                  //   controlId="PassInput2"
                  label={this.props.intl.formatMessage({ id: "profileSettings.confirmNewPassword" })}
                  className="mb-3"
                  isInvalid={this.state.passFormErrors.new_password2}
                >
                  <Form.Control
                    id="PassInput2"
                    type={this.state.passInputType}
                    value={this.state.new_password2}
                    name={'new_password2'}
                    placeholder={this.props.intl.formatMessage({ id: "profileSettings.confirmNewPassword" })}
                    onChange={this.handleChange}
                    isInvalid={this.state.passFormErrors.new_password2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {this.state.passFormErrors.new_password2 && this.state.passFormErrors.new_password2.map((item, index) => {
                      return <div key={'passErrFbp2' + index}>{item}</div>
                    })}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              {this.state.passwordSuccessMessage &&
                <Col xs={12}className='pb-2 text-left text-success'>
                  {this.state.passwordSuccessMessage}
                </Col>
              }
              {!this.state.passwordSuccessMessage &&
                <Col xs={12} className='my-2'>
                  <XButton
                    onClick={() => { this.savePassword(); }}
                  //  disabled={this.state.streamDescFetching}
                  >
                    {this.props.intl.formatMessage({ id: "profileSettings.saveNewPassword" })}
                  </XButton>
                </Col>
              }
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    )


  }
}


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      changePassword,
      changeEmailInit,
      checkDisplayName,
      updateProfile,
      updateAvatar,
      deleteAccountInit,
    }, dispatch)
  }
}
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    myProfile: state.profile.myProfile
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MyProfileSettings));
