import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { getWallet, getCheckoutData, testCheckout } from "../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';
import { ThreeDots } from 'react-loader-spinner'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AiOutlineClose } from 'react-icons/ai'
import { Spinner } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl'
import blikBtn from '../static/blikBtn.png'
import hotpayWhite from '../static/hotpayWhite.png'

class BuyTokensModal extends Component {


  componentDidMount = () => {
    this.setState({
      walletFetching: true,
      //  transactionCurrency: this.props.myProfile.transactionCurrency 
    })
    this.props.getWallet(this.walletFetched)
  }


  state = {
    //transactionCurrency: undefined
    transactionCurrency: 'pln',
  }

  walletFetched = (data) => {
    this.setState({ walletFetching: false })

    //  console.log('walletFetched', data)
    this.setState({ balanceData: data })
  }
  renderTokenPacketItem = (item, index) => {
    return (
      <Col xs={6} md={6} lg={4} xl={4} xxl={4} className='px-2 pb-2' key={'tokPack' + index}>
        <div className='walletItem feature-info py-4 px-3' onClick={() => this.showCheckout(item)}>
          <Row>
            <Col xs={12} className='text-center '>
              <h4 className='text-primary'>{item.tokensAmount}<br></br>Tokens</h4>
            </Col>
            <Col xs={12} className='text-center'>
              {this.state.transactionCurrency === 'eur' &&
                <h3>{item.price.eur} &euro;</h3>
              }
              {this.state.transactionCurrency === 'pln' &&
                <h3>{item.price.pln} <br></br>PLN</h3>
              }
            </Col>
          </Row>
        </div>
      </Col>
    )
  }
  showCheckout = (item) => {
    this.setState({ showCheckout: true, chekoutFetching: true, checkoutItem: item })
    this.props.getCheckoutData(item.id, this.checkoutFetched)
  }
  checkoutFetched = (data) => {
    this.setState({
      chekoutFetching: false,
      HPLink: data.HPLink,
      packetData: data.packetData,
    })
  }
  testCheckout = () => {
    this.setState({ testCheckoutFethcing: true })
    const fd = new FormData()
    fd.append('packetId', this.state.checkoutItem.id)
    this.props.testCheckout(fd, this.testCheckoutFetched)
  }
  testCheckoutFetched = (data) => {
    this.setState({ testCheckoutFethcing: false })
    this.setState({ balanceData: data, checkoutSuccess: true }, () => {
      setTimeout(() => {
        this.onHide()
      }, 2000)

    })

  }
  onHide = () => {
    this.props.dispatch({ type: 'SET_BUY_TOKENS_MODAL_OUT_OF_BALANCE', data: false })
    this.props.dispatch({ type: 'SET_SHOW_BUY_TOKENS_MODAL', data: false })
    this.setState({
      showCheckoutModal: false,
      checkoutItem: undefined,
      testCheckoutFethcing: false,
      HPLink: undefined,
      packetData: undefined,
      showCheckout: false,
    })
    // this.setState({ showCheckout: false, chekoutFetching: false, checkoutItem: undefined, checkoutSuccess: false })

  }
  render() {
    // console.log('this.props.showBuyTokensModal', this.props.showBuyTokensModal)
    if (!this.props.myProfile) {
      return null
    }
    return (
      <Modal
        centered
        show={this.props.showBuyTokensModal}
        onHide={() => this.onHide()}

      >

        <Modal.Body>

          {!this.state.showCheckout && !this.state.checkoutSuccess &&
            <>
              <Row>
                <Col md={12} className='pt-2' >
                  <div className="section-title">
                    <h2 className="title"><FormattedMessage id="wallet.buyTokens"/>:</h2>
                    <div className='modalClose' onClick={() => { this.onHide() }}>
                      <AiOutlineClose />
                    </div>

                  </div>
                </Col>

                {this.props.buyTokensModalOutOfBalance &&
                  <Col md={12} className='pb-3 text-primary' >
                   <h4> <FormattedMessage id="wallet.outOfBallance" /></h4>
                  </Col>
                }
              </Row>
              <Row>
                {/** 
                <Col xs={'auto'} className='mb-4' >
                  <button className={this.state.transactionCurrency === 'eur' ? 'btn btn-primary statc' : 'btn btn-secondary '} onClick={() => this.setState({ transactionCurrency: 'eur' })}>EUR</button>
                </Col>
                */}
                <Col xs={'auto'} className='mb-4' >
                  <button className={this.state.transactionCurrency === 'pln' ? 'btn btn-primary statc' : 'btn btn-secondary'} onClick={() => this.setState({ transactionCurrency: 'pln' })}>PLN</button>
                </Col>

              </Row>
              <Row>
                <Col xs={12} className='px-3'>
                  <Row className='g-0'>
                    {this.state.walletFetching &&
                      <Spinner size={'lg'} />
                    }
                    {this.state.balanceData && this.state.balanceData.packets.map((item, index) => {
                      return this.renderTokenPacketItem(item, index)
                    })}

                  </Row>
                </Col>
              </Row>
            </>
          }
          {this.state.showCheckout &&
            <>
              <Row>
                <Col md={12} className='pt-2' >
                  <div className="section-title">
                    <h2 className="title"><FormattedMessage id="wallet.checkout" />:</h2>
                    <div className='modalClose' onClick={() => { this.onHide() }}>
                      <AiOutlineClose />
                    </div>
                  </div>
                </Col>
              </Row>
              {!this.state.checkoutSuccess &&
                <Row>
                  <Col xs={12} className='text-center'>
                    {this.state.transactionCurrency === 'eur' &&
                      <h4><FormattedMessage id="wallet.buy" /> {this.state.checkoutItem.tokensAmount} <FormattedMessage id="wallet.tokensFor" /> {this.state.checkoutItem.price.eur} &euro;</h4>
                    }
                    {this.state.transactionCurrency === 'pln' &&
                      <h4><FormattedMessage id="wallet.buy" /> {this.state.checkoutItem.tokensAmount} <FormattedMessage id="wallet.tokensFor" /> {this.state.checkoutItem.price.pln} PLN</h4>
                    }
                  </Col>
                  <Col xs={12} className='mt-3'>
                    <Col xs={12} className='mt-1 text-center mx-auto p-1 pt-3' style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}>
                      <a href={this.state.HPLink} target='_blank' >
                        ZAPŁAĆ BLIKIEM <br></br>
                        <Image src={blikBtn} height={80} />
                      </a>
                    </Col>
                    <Col xs={12} className='mt-4 text-center mx-auto p-3 mb-5' style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}>
                      <a href={this.state.HPLink} target='_blank' >
                        MIKROPŁATNOŚCI <br></br>
                        <Image src={hotpayWhite} height={50} />
                      </a>
                    </Col>
                  </Col>
                </Row>
              }
              {this.state.checkoutSuccess &&
                <Row>
                  <Col xs={12} className='text-center'>
                    <h3>Success!</h3>
                  </Col>
                </Row>
              }
            </>
          }


        </Modal.Body>
      </Modal>
    )


  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({
    getWallet, getCheckoutData, testCheckout
  }, dispatch)
})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    myProfile: state.profile.myProfile,
    showBuyTokensModal: state.profile.showBuyTokensModal,
    buyTokensModalOutOfBalance: state.profile.buyTokensModalOutOfBalance,

  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(BuyTokensModal));
