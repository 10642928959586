import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoLarge from '../../static/logoLarge.png'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from "react-router-dom";
function CookiesPolicyPl(props) {
    window.scrollTo(0, 0);
    return (
        <Container style={{ paddingTop: '100px' }}>
            <Row className="">
                <Col xs={12} className=' '>
                    <h3 className="">POLITYKA PLIKÓW COOKIES</h3>

                    Niniejsza Polityka dotycząca plików cookies odnosi się do strony internetowej Chillistream.com (dalej: Strony) Poprzez używanie Strony wyrażasz zgodę na używanie
                    plików cookies zgodnie z niniejszą Polityką plików cookies (dalej: Polityka). Jeżeli nie zgadzasz się na używanie przez Stronę plików cookies w
                    opisany w Polityce sposób, powinieneś zmienić ustawienia swojej przeglądarki lub zrezygnować z użytkowania Strony.
                </Col>
                <Col xs={12} className=' '>
                    <h4 className="terms-title">CZYM SĄ PLIKI COOKIES?</h4>
                    Pliki cookies to pliki lub części informacji, które mogą być przechowywane na twoim komputerze (lub innym odblokowanym urządzeniu, takim jak tablet lub smartphone),
                    podczas gdy odwiedzasz Stronę. Owo tzw. ciasteczko zazwyczaj zawiera nazwę strony internetowej z której pochodzi, „długość życia” ciasteczka
                    (to znaczy czas jego istnienia), oraz wartość, którą jest przeważnie przypadkowo wygenerowany unikalny numer.

                    <h4 className="terms-title">DO CZEGO UŻYWAMY PLIKÓW COOKIES?</h4>
                    Korzystanie z plików cookies jest niezbędne dla prawidłowego funkcjonowania Strony. Pliki wykorzystujemy do celów dostosowania treści serwisu do potrzeb użytkowników
                    m.in. do regionu, z którego łączy się dany internauta, do celów statystycznych i monitorowania aktywności odwiedzin naszych użytkowników,
                    jak również do badania prawidłowego funkcjonowania wszystkich funkcji użytych na Stronie w celu ich ciągłego ulepszania.
                    Pliki cookies mogą również być używane do ułatwienia interakcji użytkownika ze Stroną, np. do utrzymania sesji użytkownika po zalogowaniu,
                    dzięki której użytkownik nie musi na każdej podstronie serwisu ponownie wpisywać loginu i hasła. Pliki te możemy wreszcie wykorzystywać do określania
                    profilu użytkownika w celu wyświetlania dopasowanych materiałów w sieciach reklamowych, w szczególności sieci Google. Nie możemy / Możemy
                    Cię personalnie zidentyfikować na podstawie informacji przechowywanych w plikach cookies.

                    <h4 className="terms-title">JAKIEGO RODZAJU PLIKÓW COOKIES UŻYWAMY?</h4>
                    Na Stronie mogą być używane dwa rodzaje plików cookies – „sesyjne” oraz „stałe”. Te pierwsze są tymczasowymi plikami cookies,
                    które pozostają na twoim urządzeniu aż do opuszczenia Strony. Pliki cookies stałe pozostają na twoim urządzeniu znacznie dłużej lub do momentu kiedy je manualnie
                    usuniesz (to jak długo dane ciasteczko pozostanie na twoim urządzeniu zależy od „czasu życia” tego pliku cookies oraz od ustawień twojej przeglądarki internetowej).
                    Wśród plików cookies wyróżnić możne te, które są niezbędne do działania strony www. Należące do tej kategorii pliki cookies wykorzystywane są, aby zapewnić: -
                    utrzymanie sesji użytkownika; - zapisanie stanu sesji użytkownika; - monitoring dostępności usług. Kolejna kategoria plików cookies,
                    to pliki, które wprawdzie nie są niezbędne do korzystania ze strony, ale ułatwiają korzystanie z niej. Wykorzystuje się je, aby umożliwić:
                    przywrócenie ostatnio odwiedzanego widoku przy następnym zalogowaniu do produktu, zapamiętanie wyboru użytkownika w kwestii zaprzestania wyświetlania
                    wybranego komunikatu lub wyświetlenia go określoną liczbę razy; przywrócenie sesji użytkownika sprawdzenie czy zapis do plików cookies funkcjonuje prawidłowo;
                    dopasowanie zawartości produktów do preferencji użytkowników; ustawienie preferowanego języka, waluty, rozmiaru czcionki i innych tego typu właściwości ułatwiających
                    pracę z aplikacją; wyświetlenie ostatnio wybranego parametru sortowania.

                    <h4 className="terms-title">CZY UŻYWAMY PLIKÓW COOKIES PODMIOTÓW TRZECICH?</h4>
                    Możemy korzystać z usług dostawców, którzy także mogą zapisywać pliki cookies w naszym imieniu, gdy odwiedzasz Stronę.
                    Pozwalają one im na dostarczanie usług które zapewniają ci dostawcy. Gdy odwiedzasz Stronę możesz otrzymywać pliki cookies pochodzące od podmiotów trzecich –
                    ich stron internetowych lub domen (np. od Google). Podmioty trzecie wykorzystują pliki cookies w następujących celach: - monitorowanie ruchu na Naszej stronie; -
                    zbieranie anonimowych, zbiorczych statystyk, które pozwalają nam zrozumieć, w jaki sposób użytkownicy korzystają z naszej strony internetowej i umożliwiają nam
                    ciągłe doskonalenie naszych produktów; - ustalanie liczby anonimowych użytkowników Naszej strony; - kontrolowanie jak często pokazywana jest użytkownikom wybrana treść; -
                    kontrolowanie jak często użytkownicy wybierają daną usługę; - badanie zapisów na newslettery; - wykorzystanie systemu personalizowanych rekomendacji dla e-commerce;
                    Wśród podmiotów tych w dacie sporządzenia niniejszego dokumentu zaliczamy: Google Analytics (więcej informacji oraz dodatek do przeglądarki blokujący Google Analytics:
                    tools.google.com);

                    <h4 className="terms-title">CZY MOGĘ STEROWAĆ PLIKAMI COOKIES ALBO JE USUNĄĆ?</h4>
                    W większości przeglądarek internetowych akceptacja plików cookies jest ustawiona domyślnie. Możesz zmienić ustawienia tak,
                    aby ciasteczka były blokowane albo aby alarmowano Cię, że są wysyłane na Twoje urządzenia. Jest wiele sposobów na zarządzanie plikami cookies. Prosimy,
                    abyś odniósł się do instrukcji Twojej przeglądarki internetowej lub wyświetlił pomoc, aby dowiedzieć się jak dostosowywać lub zmieniać ustawienia Twojej przeglądarki.
                    Oto informację jak to zrobić na najpopularniejszych przeglądarkach:
                    <br></br><br></br>
                    <p><a target='_blank' href='https://support.google.com/chrome/answer/95647'>Chrome</a></p>
                    <p><a target='_blank' href='https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectslug=Włączanie+i+wyłączanie+obsługi+ciasteczek&redirectlocale=pl'>Firefox</a></p>
                    <p><a target='_blank' href='https://help.opera.com/pl/latest/web-preferences/#cookies'>Opera</a></p>
                    <p><a target='_blank' href='https://support.apple.com/kb/ph21411?locale=pl_PL'>Safari</a></p>
                </Col>
            </Row>
        </Container>
    )
}
export default injectIntl(CookiesPolicyPl);