import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { userLoginFetch } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'
import { injectIntl } from 'react-intl'
import remoteControl from '../../static/images/svg/remoteControl.svg'
import profile from '../../static/images/svg/profile.svg'
import money from '../../static/images/svg/money.svg'
import verification from '../../static/images/svg/verification.svg'
import bank from '../../static/images/svg/bank.svg'
import broadcast from '../../static/images/svg/broadcast.svg'
import wallet from '../../static/images/svg/wallet.svg'


class MyPanel extends Component {


    componentDidMount = () => {

    }


    state = {

    }


    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }
        return (
            <>
                <div class="space-pt overflow-hidden h-70">
                    <Container  >
                        <Row >
                            <Col md={12} className='pt-4' >
                                <div class="section-title">
                                    <h2 className="title">Quick Menu</h2>
                                </div>

                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className=' streamerMenu streamerPanelBtnsCont mx-auto py-2'>
                                <Row>
                                    
                                    <Col xs={12} xl={6} className='p-3'>
                                        <Link to={'/my/profile/'}>
                                            <div className="feature-info ">
                                                <div className="constant order-md-1 order-2">
                                                    <img className="img-fluid" src={profile} alt="#" />
                                                    <h2 className="title">Profile Settings</h2>
                                                    <p>Click here to edit your profile</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col xs={12} xl={6} className='p-3'>
                                        <Link to={'/my/wallet/'}>
                                            <div className="feature-info ">
                                                <div className="constant order-md-1 order-2">
                                                    <img className="img-fluid" src={wallet} alt="#" />
                                                    <h2 className="title">Wallet</h2>
                                                    <p>Click here to buy more tokens</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col xs={12} xl={6} className='p-3'>
                                        <Link to={'/my/earnings/'}>
                                            <div className="feature-info ">
                                                <div className="constant order-md-1 order-2">
                                                    <img className="img-fluid" src={money} alt="#" />
                                                    <h2 className="title">Earnings</h2>
                                                    <p>Click here to check your income details</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col xs={12} xl={6} className='p-3'>
                                        <Link to={'/my/payoutsettings/'}>
                                            <div className="feature-info ">
                                                <div className="constant order-md-1 order-2">
                                                    <img className="img-fluid" src={bank} alt="#" />
                                                    <h2 className="title">Payout Settings</h2>
                                                    <p>Click here to edit your payout settings</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col xs={12} xl={6} className='p-3'>
                                        <Link to={'/my/verification/'}>
                                            <div className="feature-info ">
                                                <div className="constant order-md-1 order-2">
                                                    <img className="img-fluid" src={verification} alt="#" />
                                                    <h2 className="title">Verify Account</h2>
                                                    <p>Verify your streamer account</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )


    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        //  userLoginFetch,
    }, dispatch)
})
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(MyPanel));
