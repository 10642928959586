import React, { Component } from 'react';
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux';
import { getWallet, getCheckoutData, testCheckout } from "../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AiOutlineClose } from 'react-icons/ai'
import { injectIntl, FormattedMessage } from 'react-intl'
import { BsBell } from 'react-icons/bs'
import {runPushServiceWorker} from '../pushNotsActions'
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
class PushNotsModal extends Component {


  componentDidMount = () => {

  }


  state = {
  }

  runTask = () => {
    runPushServiceWorker()
    this.onHide()
  }

  onHide = () => {
    this.props.dispatch({ type: 'SHOW_PUSH_NOTS_MODAL', data: false })
    let myCurrentDate = new Date()
    let expiryDate =  new Date(new Date().getTime()+(7*24*60*60*1000));
    cookies.set('psmd', true, { path: '/', expires: expiryDate })
  }
  render() {
    if (!this.props.loggedIn) {
      return null
    }
    return (
      <Modal
        centered
        show={this.props.showPushNotsModal}
        onHide={() => this.onHide()}

      >

        <Modal.Body>

          {!this.state.showCheckout && !this.state.checkoutSuccess &&
            <>
              <Row>
                <Col md={12} className='pt-2' >
                  <div className="section-title">
                    <h3 className="title"> <BsBell /> Powiadomienia </h3>
                    <div className='modalClose' onClick={() => { this.onHide() }}>
                      <AiOutlineClose />
                    </div>
                  </div>
                </Col>
                <Col md={12} className='pt-2 mb-3 text-center' >
                  <h4>
                     Czy powiadamiać Cię o transmisjach Twoich ulubionych streamerów?
                  </h4>
                  

                </Col>
                <Col md={12} className='text-center mb-3 mt-3' >
                  <Button variant="primary" onClick={() => { this.runTask() }}>
                    <BsBell /> ZWZWÓL NA POWIADOMIENIA PUSH
                  </Button>
                </Col>
                <Col md={12} className='text-center mb-3 mt-3 upperCase' >
                  <Button variant="primary" onClick={() => { this.onHide() }}>
                  <AiOutlineClose /> Może Póżniej
                  </Button>
                </Col>
              </Row>

            </>
          }


        </Modal.Body>
      </Modal>
    )


  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({
    getWallet, getCheckoutData, testCheckout
  }, dispatch)
})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    showPushNotsModal: state.profile.showPushNotsModal,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PushNotsModal));
