import React, { Component } from 'react';
import { connect } from 'react-redux';
import { passwordResetConfirm } from "../../actions/index";
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import { RiLockPasswordLine } from 'react-icons/ri'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import XButton from '../../components/xButton'

class PassResetConfirm extends Component {

  componentDidMount = () => {
    let uid = this.props.match.params.uid
    let token = this.props.match.params.token
    this.setState({ uid: uid, token: token })
  }

  state = {
    email: "",
    new_password1: "",
    new_password2: "",
    errors: false,
    btnDisabled: false,
    fetching: false,
    success: false,
    uid: null,
    token: null,
    showPassword: false,
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = () => {
    this.setState({ errors: false, fetching: true })
    this.props.passwordResetConfirm(this.state, this.submitted)
  }

  submitted = (data) => {
    if (!data.error) {
      this.setState({ success: true })
    }
    else {
      //   console.log('error', data)
      this.setState({ errors: data.data })
    }
    this.setState({ fetching: false })
  }
  render() {
    if (this.props.loggedIn) {
      //return <Redirect  to={'/'} />
    }
    return (
      <>
      <Container className="px-0 px-3 py-3">
        <Col xs={12} md={6} className='mx-auto py-4'>
          <Row noGutters>
            <Col xs={12} className='h3 mt-4'>
              Reset hasła:
            </Col>
            {this.state.success &&
              <Col xs={12} className='text-center upperCase fadeIn pb-3'>
                hasło zostało zmienione. <br></br>
                Możesz sie <Link to={'/login/'}>zalogować</Link> do swojego konta.
              </Col>
            }
            {!this.state.success &&
              <Col xs={12} >
                <Row noGutters>
                  <Col xs={12} className='pb-2'>
                    Wpisz nowe hasło do swojego konta:
                  </Col>
                  <Col xs={12} className='pt-2 '>
                    <FormGroup>
                      <FloatingLabel
                        // controlId="pseudonimInput"
                        label="Nowe hasło"
                        className="mb-3"
                      >
                        <Form.Control
                          id="password1Input"
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.new_password1}
                          name={'new_password1'}
                          placeholder="Nowe hasło"
                          isInvalid={this.state.errors.new_password1}
                          onChange={this.handleChange}
                          disabled={this.state.fetching}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.errors.new_password1 && this.state.errors.new_password1.map((item, index) => {
                            return <div key={'pass1ErrFbop' + index}>{item}</div>
                          })}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </FormGroup>
                    <FormGroup>
                      <FloatingLabel
                        // controlId="pseudonimInput"
                        label="Nowe hasło"
                        className="mb-3"
                      >
                        <Form.Control
                          id="password2Input"
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.new_password2}
                          name={'new_password2'}
                          placeholder="potwierdź nowe hasło"
                          isInvalid={this.state.errors.new_password2}
                          onChange={this.handleChange}
                          disabled={this.state.fetching}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.errors.new_password2 && this.state.errors.new_password2.map((item, index) => {
                            return <div key={'pass1ErrFbop' + index}>{item}</div>
                          })}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </FormGroup>
                  </Col>
                  <Col xs={12} className="text-center text-danger" style={{ height: '15px' }}>
                    {this.state.errors && this.state.errors.non_field_errors &&
                      this.state.errors.non_field_errors.map((item, index) => {
                        return <div key={'erra' + index}>{item}</div>
                      })
                    }
                  </Col>

                  <Col xs={12} className=' text-center'>
                    {!this.state.fetching &&
                      <Button
                        variant={'primary'}
                        onClick={() => { this.handleSubmit(); }}
                        //disabled={!this.state.displayNameAvailable}
                      >
                        {this.props.intl.formatMessage({ id: "common.save" })}
                      </Button>
                    }
                    {this.state.fetching &&
                      <ThreeDots
                        height="25"
                        width="50"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    }
                  </Col>
                  <Col xs={12} className='pt-3 text-center'>
                    <Link to={'/login/'}> Masz konto? Zaloguj się.</Link><br></br>
                    <Link to={'/login/#reg'}> Nie masz konta? Zarejestruj się.</Link><br></br>
                  </Col>
                </Row>
              </Col>
            }
          </Row>
        </Col>
      </Container>
      </>
    )



  }
}

//const mapDispatchToProps = dispatch => ({
// userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
//})
function mapStateToProps(state) {
  return {
    loggedIn: state.auth.token
  };
}
export default connect(
  mapStateToProps,
  { passwordResetConfirm, }
)(injectIntl(PassResetConfirm));
