import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from 'react-redux'
import store from "./store/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import './template/css/fontawesome/all.css'
import './template/css/style.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);